export default {
list: {
    //the keys are underscored
        'admin':[ 	
            'dashboard',
            'business',
            'identity',
            'employees',
            'locations',
            'offers',
            'services',
            'products',
            'promotions',
            'subscriptions',
            'sales',
            'sales_and_payments',
            'credits_situation',
            'payments',
            'calendar',
            'plannings',
            'schedules',
            'appointments',
            'streaming',
            'user-management',
            'users',
            'customers',
            'groups',
            'roles_and_rights',
            'roles',
            'role_features',
            'role_for_user',
            'app_settings',
            'time_limits',
            'data_libraries',
            'general_data',
            'training_classifications',
            'exercises_and_trainings',
            'personal_files',
        ],
        'worker':[ 	
            'dashboard',
            'business',
            'identity',
            'employees',
            'locations',
            'offers',
            'services',
            'products',
            'promotions',
            'welcome_subscriptions',
            'subscriptions',
            'sales',
            'sales_and_payments',
            'payments_nav',
            'credits_situation',
            'calendar',
            'plannings',
            'schedules',
            'appointments',
            'streaming',
            'payments',
            'user-management',
            'users',
            'customers',
            'groups',
            'roles_and_rights',
            'roles',
            'role_features',
            'role_for_user',
            'app_settings',
            'time_limits',
            'data_libraries',
            'general_data',
            'training_classifications',
            'exercises_and_trainings',
            'personal_files',
        ],
        'customer':[
            'dashboard',
            'subscriptions',
            'about_us',
            'shop_payments',
            'credits_situation',
            'my_groups',
            'offers',
            'services',
            'products',
            'promotions',
            'calendar',
            'schedules',
            'streaming',
            'personal_file',
        ],
    }
    }