import Vue from 'vue'

var filter = function(text, length, clamp) {
  clamp = clamp || '...'
  var node = document.createElement('div')
  node.innerHTML = text
  var content = node.textContent
  return content.length > length ? content.slice(0, length) + clamp : content
}
Vue.filter('truncate', filter)

Vue.prototype.$log = console.log

// datepart: 'y', 'm', 'w', 'd', 'h', 'n', 's'
Date.dateDiff = function(datepart, fromdate, todate) {	
  datepart = datepart.toLowerCase()
  var diff = todate - fromdate
  var divideBy = { w:604800000, 
                   d:86400000, 
                   h:3600000, 
                   n:60000, 
                   s:1000 }
  
  return Math.floor( diff/divideBy[datepart])*-1
}

if (!disableEscClick) {
  var disableEscClick = false
}

// Remove from array.
Array.prototype.remove = function() {
  var what, a = arguments, L = a.length, ax
  while (L && this.length) {
      what = a[--L]
      while ((ax = this.indexOf(what)) !== -1) {
          this.splice(ax, 1)
      }
  }
  return this
}