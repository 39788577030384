// Define baseUrl.
window.baseUrl = process.env.NODE_ENV === 'production' 
    ? require('../config_live.json').base_url 
    : require('../config_dev.json').base_url
// Define publicUrl.
window.publicUrl = process.env.NODE_ENV === 'production' 
    ? require('../config_live.json').public_url 
    : require('../config_dev.json').public_url
// Define currency.
window.currency = process.env.NODE_ENV === 'production'
    ? require('../config_live.json').currency
    : require('../config_dev.json').currency
// Define APP Title.
window.appTitle = process.env.NODE_ENV === 'production'
? require('../config_live.json').appTitle
: require('../config_dev.json').appTitle
      