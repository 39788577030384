const Tools = {
    data() {
        return {
            business: {}
        }
    },
    methods: {
        accessToken() {
        	return localStorage.getItem('access_token')
        },
        refreshToken() {
        	return localStorage.getItem('refresh_token')
        },
        appLogo() {
        	return window.baseUrl + 'business/logo/' + Date.now()
        },
        getPublicUrl() {
            let url = new URL(publicUrl);
            url.port = '';

            return url.toString()
        },
        getBusiness() {
            axios.get(baseUrl + 'business')
                .then(response => {
                    if (response.data.status) {
                        this.business = response.data.item
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        }
    }
}

export default Tools
