/*

    Contains application menu as required to be rendered by CoreUi component
    Important addition to this structure: key. It is required for translating the menu AND for filtering the menu by the user

*/
import * as jwt_decode from 'jwt-decode'
let access_token = localStorage.getItem('access_token')
let decoded = null
if (access_token !== null) {
    decoded = jwt_decode(access_token)
}
var customer_id = 0
if (decoded && decoded.identity && decoded.identity.customer_id) {
    customer_id = decoded.identity.customer_id
}

export default {
    items: [
        // {
        //     name: 'Dashboard',
        //     url: '/dashboard',
        //     icon: 'icon-speedometer',
        //     key: 'dashboard'
        // },
        {
            name: 'Business',
            key: 'business',
            icon: 'fa fa-handshake-o',
            children: [
                {
                    name: 'Identity',
                    url: '/business/identity',
                    key: 'identity'
                },
                {
                    name: 'Workers',
                    url: '/business/workers',
                    key: 'employees'
                },
                {
                    name: 'Locations & rooms',
                    url: '/business/locations',
                    key: 'locations'
                },
                {
                    name: 'TimeLimits',
                    url: '/business/time-limits',
                    key: 'time_limits'
                },
            ]
        },
        {
            name: 'Roles and Rights',
            key: 'roles_and_rights',
            icon: 'fa fa-user-secret',
            children: [
                {
                    name: 'Roles',
                    url: '/roles_and_rights/roles',
                    key: 'roles'
                },
                {
                    name: 'Role Features',
                    url: '/roles_and_rights/role_features',
                    key: 'role_features'
                },
                {
                    name: 'Role for User',
                    url: '/roles_and_rights/role_for_user',
                    key: 'role_for_user'
                },
            ]
        },
        {
            name: 'Data Libraries',
            key: 'data_libraries',
            icon: 'fa fa-book',
            children: [
                {
                    name: 'GeneralData',
                    url: '/data-libraries/general-data',
                    key: 'general_data',
                },
                {
                    name: 'TrainingClassifications',
                    url: '/data-libraries/training-classifications',
                    key: 'training_classifications'
                },
                {
                    name: 'ExercisesAndTrainings',
                    url: '/data-libraries/exercises-and-trainings',
                    key: 'exercises_and_trainings'
                },
            ]
        },
        {
            //title: true,
            name: 'UserManagement',
            key: 'user-management',
            icon: 'fa fa-users',
            children: [
                {
                    name: 'Users',
                    url: '/user-management/users',
                    key: 'users'
                },
                {
                    name: 'Customers',
                    url: '/user-management/customers',
                    key: 'customers'
                },
                {
                    name: 'Groups',
                    url:'/user-management/groups',
                    key: 'groups'
                },
            ]
        },
        {
            name: 'Offers',
            icon: 'fa fa-tags',
            key: 'offers',
            children: [
                {
                    name: 'Services',
                    url:'/offers/services',
                    key: 'services'
                },
                {
                    name: 'Products',
                    url:'/offers/products',
                    key: 'products'
                },
                {
                    name: 'Subscriptions',
                    url:'/offers/subscriptions',
                    key: 'subscriptions'
                },
                {
                    name: 'Promotions',
                    url:'/offers/promotions',
                    key: 'promotions'
                },
                {
                    name: 'Welcome Subscriptions',
                    url:'/offers/welcome-subscriptions',
                    key: 'welcome_subscriptions'
                },
            ],
        },
        {
            name: 'Sales and Payments',
            icon: 'fa fa-credit-card',
            key:'sales',
            children: [
                {
                    name: 'Sales and Payments',
                    key: 'sales_and_payments',
                    url: '/sales_and_payments/sales',
                },
                {
                    name: 'Payments',
                    key: 'payments_nav',
                    url: '/sales_and_payments/payments',
                },
                {
                    name: 'Credits situation',
                    key: 'credits_situation',
                    url: '/sales_and_payments/credits-situation',
                }

            ],
        },
        {
            name: 'Calendar',
            icon: 'fa fa-calendar',
            key: 'calendar',
            children: [
                {
                    name: 'Plannings',
                    key: 'plannings',
                    url: '/calendar/plannings',
                },
                {
                    name: 'Schedules',
                    key: 'schedules',
                    url: '/calendar/schedules',
                },
                {
                    name: 'Appointments',
                    key: 'appointments',
                    url: '/calendar/appointments',
                },
                {
                    name: 'Streaming',
                    key: 'streaming',
                    url: '/calendar/streaming',
                },
            ],
        },
        {
            name: 'PersonalFiles',
            key: 'personal_files',
            icon: 'fas fa-swatchbook',
            url: '/personal-files',
        },
    ],
    items_customer: [
        // {
        //     name: 'Dashboard',
        //     url: '/dashboard',
        //     icon: 'icon-speedometer',
        //     key: 'dashboard'
        // },
        // {
        //     name: 'Sales and Payments',
        //     url:'/sales_and_payments/sales',
        //     icon: 'fa fa-shopping-basket',
        //     key: 'shop_payments',
        // },
        {
            name: 'Sales and Payments',
            icon: 'fa fa-shopping-basket',
            key:'shop_payments',
            children: [
                {
                    name: 'Sales and Payments',
                    key: 'shop_payments',
                    url: '/sales_and_payments/sales',
                },
                {
                    name: 'Credits situation',
                    key: 'credits_situation',
                    url: '/sales_and_payments/credits-situation',
                },
            ],
        },
        {
            name: 'MyGroups',
            url:'/user-management/groups',
            key: 'my_groups',
            icon: 'fa fa-users',
        },
        {
            name: 'Our Offer',
            icon: 'fa fa-shopping-basket',
            key: 'offers',
            children: [
                {
                    name: 'Services',
                    url:'/offers/services',
                    key: 'services'
                },
//                {
//                    name: 'Products',
//                    url:'/offers/products',
//                    key: 'products'
//                },
                {
                    name: 'Subscriptions',
                    url:'/offers/subscriptions',
                    key: 'subscriptions'
                },
//                {
//                    name: 'Promotions',
//                    url:'/offers/promotions',
//                    key: 'promotions'
//                }
            ],
        },
        {
            name: 'Schedules',
            key: 'schedules',
            icon: 'fas fa-calendar',
            url: '/calendar/schedules',
        },
        {
            name: 'Streaming',
            key: 'streaming',
            icon: 'fas fa-video',
            url: '/calendar/streaming',
        },
        {
            name: 'PersonalFile',
            key: 'personal_file',
            icon: 'fas fa-swatchbook',
            url: '/personal-files/' + customer_id,
        },
    ]
}
