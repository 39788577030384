/**
 * Load here all of other JavaScript dependencies
 * that are not included into Vue object on init (such as router)
 * 
 * Usually do bootstrap setups here
 */
import * as jwt_decode from 'jwt-decode';
import userMenu from '@/helpers/UserMenu'
Vue.prototype.$appSettings = new Vue({
	data: {
		lang: 'ro',
		userType: '',
		userNav: [],
		auth: '',
		token: null,
	},
	created: function() {
		this.userNav = this.currentUserMenu(this.userType)
	},
	
	
	methods: {
		currentUserMenu(x) {
			var list = userMenu.list
			this.token = localStorage.getItem('access_token')
			if (this.token) {
				var decoded = jwt_decode(this.token)
				this.userType = decoded.identity.type
				x = this.userType
				return list[x]
			}
			return list
		},
		currentUserType() {
			this.token = localStorage.getItem('access_token')
			if (this.token) {
				var decoded = jwt_decode(this.token)
				return this.userType = decoded.identity.type
			}
			return this.userType = 'guest'
		}
	}
})

/* eslint-disable */

// Import app configuration statements.
import './config'

// window.entries = require('@/app/_entries.js')

//ajax lib
window.axios = require('axios')
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['Content-Type'] = 'application/json';
window.axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

axios.interceptors.request.use((config) => {
	//reject request if we have authorization header, but we dont have access token
    if (config.headers.Authorization != undefined && localStorage.getItem('access_token') == undefined) {
        var error = {};
        error.response = {};
        error.response.data = {};
        error.response.data.msg = 'Access token is not available';
        return Promise.reject(error);
	}
    return config;
	}, (error) => {
		return Promise.reject(error);
});
axios.interceptors.response.use(undefined, err => {
	var error = err.response;
	//save initial request
	var initial_request = err.config;
	//intercept 401 - unauthorized
	if (error.status == 401 && error.config.url.split('/').slice(-1)[0] == 'refresh-token') {
		localStorage.removeItem('access_token');
		localStorage.removeItem('refresh_token');
		return;
	}
	
	
	if (error.status == 401 && error.config.url.split('/').slice(-1)[0] != 'refresh-token') {
		//prepare request for refresh-token
		var headers = {'Authorization': 'Bearer ' + localStorage.getItem('refresh_token')};
		return new Promise(function (resolve, reject) {
			//send refresh token request
			axios.post(baseUrl + 'auth/refresh-token', undefined, {headers: headers})
				.then(response => {
					// initial_request.__isRetryRequest = true;
					//set new access token
					var access_token = response.data.access_token;
					if (access_token == undefined) {
						return Promise.reject(error);
					}
					localStorage.setItem('access_token', access_token);
					//include the new access token in the original failed request
					initial_request.headers.Authorization = 'Bearer ' + access_token;
					//resend original request (with the new token)
					resolve(axios(initial_request));
				})
				.catch(error => {
					reject(error);
				});
		});
	}
	return Promise.reject(error);
});

//date manipulation lib
window.moment = require('moment')

import { resolve } from 'path';
import Vue from 'vue';

