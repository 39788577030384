/**
 * Translator by language and keyboard
 * requires the commented line bellow to be set in main js
 * Vue.prototype.$appSettings = new Vue({data: {lang: 'ro'}}) 
 */
import nav from '@/_nav'
import dictionary from '@/helpers/Dictionary'

const Translator = {
    data: function() {
        return {
            vocabulary: dictionary.vocabulary,
            isSeen: true
        }
    },
    created: function () {
        if (localStorage.getItem("_lang")) {
            this.$appSettings.lang = localStorage.getItem("_lang")
        }
    },
    methods: {
        translate(text){
            if (!this.vocabulary[text]) {
                return text
            }
            return this.vocabulary[text][this.$appSettings.lang]
        },
        switchLang(lang) {
            localStorage.setItem('_lang', lang)
            this.$appSettings.lang = lang
        },
        filterNavItems() { 
        //Filtering the menu by user authorized entries 
        //and translating the menu by the 'key' property of each menu
            return nav.items
                .filter(parent => {
                    return this.$appSettings.userNav.includes(parent.key.toLowerCase())     
                })
                .map(item => {
                    item.name = this.translate(item.key.toLowerCase())
                    return item
                })
                .map(parent2 => {
                    if (parent2.children != undefined) {
                        parent2.children.filter(item2 => {
                            return this.$appSettings.userNav.includes(item2.key.toLowerCase())
                        })
                        .map(item3 => {
                            item3.name = this.translate(item3.key.toLowerCase())
                            return item3
                        })
                    }
                    return(parent2)
                })
        },
        filterNavItemsCustomer() {
            //Filtering the menu by user authorized entries 
            //and translating the menu by the 'key' property of each menu
            return nav.items_customer
                .filter(parent => {
                    return this.$appSettings.userNav.includes(parent.key.toLowerCase())     
                })
                .map(item => {
                    item.name = this.translate(item.key.toLowerCase())
                    return item
                })
                .map(parent2 => {
                    if (parent2.children != undefined) {
                        parent2.children.filter(item2 => {
                            return this.$appSettings.userNav.includes(item2.key.toLowerCase())
                        })
                        .map(item3 => {
                            item3.name = this.translate(item3.key.toLowerCase())
                            return item3
                        })
                    }
                    return(parent2)
                })
        },
        getAuthorizedMenu() {
            if (this.$appSettings.currentUserType() == 'admin') {
                return this.filterNavItems()
            } else if (this.$appSettings.currentUserType() == 'worker') {
                return this.filterNavItems()
            } else if(this.$appSettings.currentUserType() == 'customer'){
                return this.filterNavItemsCustomer()
            }  
        
        },
    }
}    

export default (Translator)

