// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.

require('./bootstrap');
import 'core-js/es6/promise'
import 'core-js/es6/string'
import 'core-js/es7/array'
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import App from './App'
import router from './router'
import Tools from './helpers/Tools.js'
import Translator from './helpers/Translator.js'
import DateFormatter from './helpers/DateFormatter.js'
import Toasted from 'vue-toasted';
import VueSlideoutPanel from 'vue2-slideout-panel'
import OptionalRouterLink from './containers/RouterLink'
import Vuelidate from 'vuelidate'
import InfiniteLoading from 'vue-infinite-loading'

// import VueTour from 'vue-tour'
// Vue.use(VueTour)
// require('vue-tour/dist/vue-tour.css')

// You need a specific loader for CSS files like https://github.com/webpack/css-loader
Vue.use(InfiniteLoading, {
  slots: {
    // keep default messages
    noResults: {
      render: h => h('div'),
    },
    noMore: {
      render: h => h('div'),
    },
  },
})

Vue.mixin(Tools)
Vue.mixin(Translator)
Vue.mixin(DateFormatter)

Vue.component('optional-router-link', OptionalRouterLink)
Vue.use(Vuelidate)
Vue.use(VueSlideoutPanel)
Vue.use(Toasted)

Vue.use(BootstrapVue)

// Use Quasar
import Quasar from 'quasar'

Vue.use(Quasar)

export const eventBus = new Vue()

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  mixins: [Tools, Translator, DateFormatter], 
  template: '<App/>',
  components: {
    App
  }
})

// Import custom scripts.
import './scripts'
import './global_variables'
