import Vue from 'vue'
import Router from 'vue-router'
import * as jwt_decode from 'jwt-decode'

Vue.use(Router)

// Not Found.
const NotFound = () => import("@/views/NotFound.vue")

// Containers
const DefaultContainer = () => import('@/containers/DefaultContainer')

const Login = () => import('@/views/auth/Login')
const Register = () => import('@/views/auth/Register')
const ActivateAccount = () => import('@/views/auth/ActivateAccount')
const ResetPassword = () => import('@/views/auth/ResetPassword')
const ChangePassword = () => import('@/views/auth/ChangePassword')
const ForgotPassword = () => import('@/views/auth/ForgotPassword')
const ResendActivationEmail = () => import('@/views/auth/ResendActivationEmail')
const ActivationAccount = () => import('@/views/auth/ActivationAccount')
const ActivateWorkerAccount = () => import('@/views/auth/ActivationWorkerAccount')
const ActivateUser = () => import('@/views/auth/ActivationUser')
const CreatedAccount = () => import('@/views/auth/BeforeLogin')
const TermsAndConditions = () => import('@/views/auth/TermsAndConditions')

const UserSettings = () => import('@/views/account-management/user-settings/UserSettings')
const MyProfile = () => import('@/views/account-management/my-profile/MyProfile')
const Dashboard = () => import('@/views/Dashboard')
const SuperadminDashboard = () => import('@/views/SuperadminDashboard')

const Roles = () => import('@/views/roles/Roles')
const RoleForUser = () => import('@/views/roles/RoleForUser')
const RoleFeatures = () => import('@/views/roles/RoleFeatures')
const Identity = () => import('@/views/business/identity/Identity')
const Employees = () => import('@/views/business/employees/Employees')
const Locations = () => import('@//views/business/locations/Locations')
const Sales = () => import('@/views/customer/Sales')
const CreditsSituation = () => import('@/views/customer/CreditsSituation')
const Payments = () => import('@/views/customer/Payments')
const PaymentCompletedRedirect = () => import('@/views/customer/PaymentCompletedRedirect')
const Appointments = () => import('@/views/calendar/appointments/Appointments')
const Plannings = () => import('@/views/calendar/plannings/Plannings')
const Schedules = () => import('@/views/calendar/schedules/Schedules')
const Streaming = () => import('@/views/calendar/streaming/Streaming')

// [+] Personal Files.
const PersonalFiles = () => import('@/views/personal-files/PersonalFiles')
const PersonalFile = () => import('@/views/personal-files/PersonalFile')
// [-] Personal Files.

// [+] Time Limits.
const TimeLimits = () => import('@/views/business/time-limits/TimeLimits')
// [-] Time Limits.

// [+] Data Libraries.
const GeneralData  = () => import('@/views/data-libraries/general-data/GeneralData')
const TrainingClassifications  = () => import('@/views/data-libraries/training-classifications/TrainingClassifications')
const ExercisesAndTrainings  = () => import('@/views/data-libraries/exercises-and-trainings/ExercisesAndTrainings')
// [-] Data Libraries.

// [+] Offers.
const Services = () => import('@/views/offers/services/Services')
const Products = () => import('@/views/offers/products/Products')
const Subscriptions = () => import('@/views/offers/subscriptions/Subscriptions')
const Promotions = () => import('@/views/offers/promotions/Promotions')
const WelcomeSubscriptions = () => import('@/views/offers/welcome-subscriptions/WelcomeSubscriptions')
// [-] Offers.

// [+] User Management.
const Users = () => import('@/views/user-management/users/Users')
const Customers = () => import('@/views/user-management/customers/Customers')
const Groups = () => import('@/views/user-management/groups/Groups')
const RespondToGroupInvitation = () => import('@/views/user-management/groups/RespondToGroupInvitation')
// [-] User Management.


const Comp = {
    'worker': SuperadminDashboard,
    'customer': Dashboard,
}

let router = new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            title: 'Login'
        }
    },
    {
        path: '/activate-v1-account',
        name: 'ActivateAccount',
        component: ActivateAccount
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        component: ResetPassword
    },
    {
        path: '/change-password',
        name: 'ChangePassword',
        meta: {
            requiresAuth: true
        },
        component: ChangePassword
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword
    },
    {
        path: '/resend-activation-email',
        name: 'ResendActivationEmail',
        component: ResendActivationEmail
    },
    {
        path: '/activate-account',
        name: 'ActivationAccount',
        component: ActivationAccount
    },
    {
        path: '/activate-worker-account',
        name: 'ActivateWorkerAccount',
        component: ActivateWorkerAccount
    },
    {
        path: '/activate-user',
        name: 'ActicateUser',
        component: ActivateUser
    },
    {
        path: '/created-account',
        name: 'CreatedAccount',
        component: CreatedAccount
    },
    {
        path: '/terms-and-conditions',
        name: 'TermsAndConditions',
        component: TermsAndConditions
    },
    {
        path: '/register',
        name: 'Register',
        component: Register
    },
    {
        path: '/respond-to-group-invitation',
        name: 'RespondToGroupInvitation',
        component: RespondToGroupInvitation
    },
    {
        path: '/user-settings',
        name: 'UserSettings',
        meta: {
            requiresAuth: true
        },
        component: UserSettings
    },
    {
        path: '/my-profile',
        name: 'MyProfile',
        meta: {
            requiresAuth: true
        },
        component: MyProfile
    },
    {
        path: '/',
        redirect: '/calendar/schedules',
        name: 'Home',
        meta: {
            requiresAuth: true
        },
        component: DefaultContainer,
        children: [
            {
                path: 'dashboard',
                name: 'Dashboard',
                meta: {
                    requiresAuth: true
                },
                component: {
                        render (dash) { 
                            return dash(Comp[this.$appSettings.userType])
                        }
                    }
            },
            {
                path: 'business',
                redirect: '/business/identity',
                name: 'Business',
                meta: {
                    requiresAuth: true
                },
                component: {
                    render (c) { return c('router-view') }
                },
                children: [
                    {
                        path: 'identity',
                        name: 'Identity',
                        meta: {
                            requiresAuth: true
                        },
                        component: Identity,
                    },
                    {
                        path: 'workers',
                        name: 'Workers',
                        meta: {
                            requiresAuth: true
                        },
                        component: Employees
                    },
                    {
                        path: 'locations',
                        name: 'Locations',
                        meta: {
                            requiresAuth: true
                        },
                        component: Locations
                    },
                    {
                        path: 'time-limits',
                        name: 'TimeLimits',
                        meta: {
                            requiresAuth: true
                        },
                        component: TimeLimits,
                    },
            ]
            },
            {
                path: 'offers',
                redirect: '/offers/services',
                name: 'Offers',
                meta: {
                    requiresAuth: true
                },
                component: {
                    render (c) { return c('router-view') }
                },
                children: [
                    {
                        path: 'services',
                        name: 'Services',
                        meta: {
                            requiresAuth: true
                        },
                        component: Services
                    },
                    {
                        path: 'products',
                        name: 'Products',
                        meta: {
                            requiresAuth: true
                        },
                        component: Products
                    },
                    {
                        path: 'subscriptions',
                        name: 'Subscriptions',
                        meta: {
                            requiresAuth: true
                        },
                        component: Subscriptions
                    },
                    {
                        path: 'promotions',
                        name: 'Promotions',
                        meta: {
                            requiresAuth: true
                        },
                        component: Promotions
                    },
                    {
                        path: 'welcome-subscriptions',
                        name: 'WelcomeSubscriptions',
                        meta: {
                            requiresAuth: true
                        },
                        component: WelcomeSubscriptions
                    },
                ],
            },
            {
                path: 'sales_and_payments',
                name: 'SalesAndPayments',
                meta: {
                    requiresAuth: true
                },
                component: {
                    render (c) { return c('router-view') }
                },
                children: [
                    {
                        path: 'sales',
                        name: 'Sales',
                        meta: {
                            requiresAuth: true
                        },
                        component: Sales
                    },
                    {
                        path: 'credits-situation',
                        name: 'CreditsSituation',
                        meta: {
                            requiresAuth: true
                        },
                        component: CreditsSituation
                    },
                    {
                        path: 'payments',
                        name: 'Payments',
                        meta: {
                            requiresAuth: true
                        },
                        component: Payments
                    },
                    {
                        path: 'payment_completed',
                        name: 'PaymentCompletedRedirect',
                        meta: {
                            requiresAuth: true
                        },
                        component: PaymentCompletedRedirect
                    },
                ],  
            },
            {
                path: 'user-management',
                redirect: '/user-management/users',
                name: 'UserManagement',
                meta: {
                    requiresAuth: true
                },
                component: {
                    render (c) { return c('router-view') }
                },
                children: [
                    {
                        path: 'users',
                        name: 'Users',
                        meta: {
                            requiresAuth: true
                        },
                        component: Users,
                    },
                    {
                        path: 'customers',
                        name: 'Customers',
                        meta: {
                            requiresAuth: true
                        },
                        component: Customers,
                    },
                    {
                        path: 'groups',
                        name: 'Groups',
                        meta: {
                            requiresAuth: true
                        },
                        component: Groups
                    },
                ]
            },
            {
                path: 'roles_and_rights',
                redirect: '/roles_and_rights/roles',
                name: 'RolesAndRights',
                meta: {
                    requiresAuth: true
                },
                component: {
                    render (c) { return c('router-view') }
                },
                children: [
                    {
                        path: 'roles',
                        name: 'Roles',
                        meta: {
                            requiresAuth: true
                        },
                        component: Roles,
                    },
                    {
                        path: 'role_features',
                        name: 'RoleFeatures',
                        meta: {
                            requiresAuth: true
                        },
                        component: RoleFeatures,
                    },
                    {
                        path: 'role_for_user',
                        name: 'RoleForUser',
                        meta: {
                            requiresAuth: true
                        },
                        component: RoleForUser,
                    },
                ]
            },
            {
                path: 'data-libraries',
                redirect: '/data-libraries/training-types',
                name: 'DataLibraries',
                meta: {
                    requiresAuth: true
                },
                component: {
                    render (c) { return c('router-view') }
                },
                children: [
                    {
                        path: 'general-data',
                        name: 'GeneralData',
                        meta: {
                            requiresAuth: true
                        },
                        component: GeneralData,
                    },
                    {
                        path: 'training-classifications',
                        name: 'TrainingClassifications',
                        meta: {
                            requiresAuth: true
                        },
                        component: TrainingClassifications,
                    },
                    {
                        path: 'exercises-and-trainings',
                        name: 'ExercisesAndTrainings',
                        meta: {
                            requiresAuth: true
                        },
                        component: ExercisesAndTrainings,
                    },
                ]
            },
            {
                path: 'calendar',
                redirect: '/schedules',
                name: 'Calendar',
                meta: {
                    requiresAuth: true
                },
                component: {
                    render (c) { return c('router-view') }
                },
                children: [
                    {
                        path: 'schedules',
                        name: 'Schedules',
                        meta: {
                            requiresAuth: true
                        },
                        component: Schedules,
                    },
                    {
                        path: 'appointments',
                        name: 'Appointments',
                        meta: {
                            requiresAuth: true
                        },
                        component: Appointments,
                    },
                    {
                        path: 'plannings',
                        name: 'Plannings',
                        meta: {
                            requiresAuth: true
                        },
                        component: Plannings,
                    },
                    {
                        path: 'streaming',
                        name: 'Streaming',
                        meta: {
                            requiresAuth: true
                        },
                        component: Streaming,
                    },
                ]
            },
            {
                path: 'personal-files',
                name: 'PersonalFiles',
                meta: {
                    requiresAuth: true
                },
                component: PersonalFiles,
            },
            {
                path: 'personal-files/:id',
                name: 'PersonalFile',
                meta: {
                    requiresAuth: true
                },
                component: PersonalFile,
                props: { default: 1, }
            },
        ]
    },
    {
        // catch all 404 - define at the very end
        path: "*",
        meta: {
            requiresAuth: false
        },
        component: NotFound
    }
  ]
})


router.beforeEach((to, from, next) => {
    window.document.title = appTitle

    let access_token = localStorage.getItem('access_token')
    let decoded = null
    if (access_token !== null) {
        decoded = jwt_decode(access_token)    
    }
    if (decoded && decoded.identity) {
        if (to.name === 'Login') {
            next({
                name: 'Home',
                params: { nextUrl: to.fullPath }
            })
        }
        next()
    } else {
        if (to.meta.requiresAuth === true) {
            next({
                name: 'Login',
                params: { nextUrl: to.fullPath }
            })
        }
        next()
    }
})

export default router