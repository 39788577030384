export default {

    vocabulary: {
        //A
        'about_us': {
            'ro': 'Despre noi',
            'en': 'About us'
        },
        'accept_invitation': {
            'ro': 'Acceptă invitația',
            'en': 'Accept invitation'
        },
        'add': {
            'ro': 'Adaugă',
            'en': 'Add'
        },
        'activate_account': {
            'ro': 'Activează-ți contul',
            'en': 'Activate your account'
        },
        'translate_v1_user_account_reactivation_info_text': {
            'ro': 'Pentru a vă reactiva contul și a beneficia de noile integrări de funcționalități, vă rugăm să introduceți o parolă nouă și să citiți și să acceptați %the_terms_and_conditions%',
            'en': 'In order to reactivate your account and benefit from the new feature integrations please enter new password and read and accept %the_terms_and_conditions%'
        },
        'account_activated_not_v1_user': {
            'ro': 'Contul nu este utilizator V1',
            'en': 'The account is not V1 user'
        },
        'account_activated_bad_activation_token': {
            'ro': 'Tokenul pentru activare nu este corect. Verificați dacă ați activat din linkul primit în cel mai recent e-mail.',
            'en': 'Bad activation token. Check if you have activated from the link received in the most recent email.'
        },
        'activation_here': {
            'ro': 'Finalizează înregistrarea contului, activându-l aici.',
            'en': 'In order de finalize the registration, please activate your account here.'
        },
        'already_activated': {
            'ro': 'Contul respectiv a fost deja activat.',
            'en': 'This account has already activated.'
        },
        'account_activation': {
            'ro': 'Activare cont',
            'en': 'Acctoun activation'
        },
        'json_key_is_required': {
            'ro': 'Date incomplete',
            'en': 'Incomplete data'
        },
        'active': {
            'ro': 'Activ',
            'en': 'Active'
        },
        'activate': {
            'ro': 'Activează',
            'en': 'Activate'
        },
        'deactivate': {
            'ro': 'Dezactivează',
            'en': 'Deactivate'
        },
        'activity': {
            'ro': 'Activiăți',
            'en': 'Activity'
        },
        'access_price': {
            'ro': 'Preț de acces',
            'en': 'Access price'
        },
        'subscription_access_price_info': {
            'ro': 'Prețul de access se adaugă la prețul creditelor și produselor incluse în abonament, iar pe totalul rezultat se aplică discountul definit mai jos.',
            'en': 'The access price is added to the price of the credits and products included in the subscription, and the discount defined below is applied to the total result.'
        },
        'custom_subscription_price': {
            'ro': 'Preț de abonament',
            'en': 'Subscription price'
        },
        'add_customer': {
            'ro': 'Adaugă client',
            'en': 'Add customer'
        },
        'new_customer': {
            'ro': 'Client nou',
            'en': 'New customer'
        },
        'add_customer_resource_added': {
            'ro': 'Client adăugat',
            'en': 'Customer added'
        },
        'add_customer_json_key_is_required_first_name': {
            'ro': 'Te rugăm să completezi prenumele clientului',
            'en': 'Please fill in the first name of the customer'
        },
        'edit_customer_json_key_is_required_first_name': {
            'ro': 'Te rugăm să completezi prenumele clientului',
            'en': 'Please fill in the first name of the customer'
        },
        'add_customer_json_key_is_required_last_name': {
            'ro': 'Te rugăm să completezi numele de familie al clientului',
            'en': 'Please fill in the last name of the customer'
        },
        'edit_customer_json_key_is_required_last_name': {
            'ro': 'Te rugăm să completezi numele de familie al clientului',
            'en': 'Please fill in the last name of the customer'
        },
        'add_customer_json_key_is_required_email': {
            'ro': 'Te rugăm să completezi adresa de email a clientului',
            'en': 'Please fill in the email address of the customer'
        },
        'edit_customer_json_key_is_required_email': {
            'ro': 'Te rugăm să completezi adresa de email a clientului',
            'en': 'Please fill in the email address of the customer'
        },
        'add_customer_json_key_is_required_phone_number': {
            'ro': 'Te rugăm să completezi numărul de telefon al clientului',
            'en': 'Please fill in the phone number of the customer'
        },
        'edit_customer_json_key_is_required_phone_number': {
            'ro': 'Te rugăm să completezi numărul de telefon al clientului',
            'en': 'Please fill in the phone number of the customer'
        },
        'add_customer_json_key_is_required_birth_date': {
            'ro': 'Te rugăm să completezi data de naștere a clientului',
            'en': 'Please fill in the birth date of the customer'
        },
        'edit_customer_json_key_is_required_birth_date': {
            'ro': 'Te rugăm să completezi data de naștere a clientului',
            'en': 'Please fill in the birth date of the customer'
        },
        'add_customer_json_key_is_required_gender': {
            'ro': 'Te rugăm să completezi genul clientului',
            'en': 'Please fill in the gender of the customer'
        },
        'edit_customer_json_key_is_required_gender': {
            'ro': 'Te rugăm să completezi genul clientului',
            'en': 'Please fill in the gender of the customer'
        },
        'add_group': {
            'ro': 'Adaugă grup',
            'en': 'Add group'
        },
        'new_group': {
            'ro': 'Grup nou',
            'en': 'New group'
        },
        'add_image': {
            'ro': 'Adaugă imagine',
            'en': 'Add image'
        },
        'add_member': {
            'ro': 'Adaugă membru',
            'en': 'Add member'
        },
        'member_activated': {
            'ro': 'Membrul a fost activat',
            'en': 'Member has been activated'
        },
        'member_deactivated': {
            'ro': 'Membrul a fost dezactivat',
            'en': 'Member has been deactivated'
        },
        'add_payment': {
            'ro': 'Adaugă plată',
            'en': 'Add payment'
        },
        'add_product': {
            'ro': 'Adaugă produs',
            'en': 'Add product'
        },
        'new_product': {
            'ro': 'Produs nou',
            'en': 'New product'
        },
        'add_products': {
            'ro': 'Adaugă produse',
            'en': 'Add products'
        },
        'include_products': {
            'ro': 'Include produse',
            'en': 'Include products'
        },
        'include_product': {
            'ro': 'Include produs',
            'en': 'Include product'
        },
        'add_promotion': {
            'ro': 'Adaugă promoție',
            'en': 'Add promotion'
        },
        'add_promotion_promotion_overlap': {
            'ro': 'Promoție suprapusă',
            'en': 'Promotion overlap'
        },
        'add_welcomeSubscription_welcome_subscription_overlap': {
            'ro': 'Abonament bonus de înregistrare suprapus',
            'en': 'Welcome subscription overlap'
        },
        'add_welcomeSubscription_resource_added': {
            'ro': 'Abonament bonus de înregistrare adăugat',
            'en': 'Welcome subscription added'
        },
        'add_welcome_subscription': {
            'ro': 'Adaugă abonament bonus de înregistrare',
            'en': 'Add welcome subscription'
        },
        'new_promo': {
            'ro': 'Promoție nouă',
            'en': 'New promotion'
        },
        'add_role': {
            'ro': 'Adaugă rol',
            'en': 'Add role'
        },
        'add_role_json_key_is_required_role_name': {
            'ro': 'Te rugăm să completezi numele rolului',
            'en': 'Please fill in the name of the role'
        },
        'all_sales': {
            'ro': 'Toate',
            'en': 'All'
        },
        'reset': {
            'ro': 'Resetare',
            'en': 'Reset'
        },
        'deleted_sales': {
            'ro': 'Șterse',
            'en': 'Deleted'
        },
        'add_sale': {
            'ro': 'Adaugă vânzare',
            'en': 'Add sale'
        },
        'add_staff': {
            'ro': 'Adaugă personal',
            'en': 'Add staff'
        },
        'add_service': {
            'ro': 'Adaugă serviciu',
            'en': 'Add service'
        },
        'new_service': {
            'ro': 'Serviciu nou',
            'en': 'New service'
        },
        'add_services': {
            'ro': 'Adaugă servicii',
            'en': 'Add services'
        },
        'include_services': {
            'ro': 'Include servicii',
            'en': 'Include services'
        },
        'include_service': {
            'ro': 'Include serviciu',
            'en': 'Include service'
        },
        'add_subscription': {
            'ro': 'Adaugă abonament',
            'en': 'Add subscription'
        },
        'new_subscription': {
            'ro': 'Abonament nou',
            'en': 'New subscription'
        },
        'add_subscription_custom': {
            'ro': 'Adaugă abonament: Personalizat',
            'en': 'Add subscription: Custom'
        },
        'add_subscription_predefined': {
            'ro': 'Adaugă abonament: Predefinit',
            'en': 'Add subscription: Predefined'
        },
        'add_user': {
            'ro': 'Adaugă utilizator',
            'en': 'Add user'
        },
        'address': {
            'ro': 'Adresă',
            'en': 'Address'
        },
        'amount': {
            'ro': 'Cantitate',
            'en': 'Amount'
        },
        'sale_amount': {
            'ro': 'Sumă',
            'en': 'Amount'
        },
        'total_amount': {
            'ro': 'Suma totală',
            'en': 'Total amount'
        },
        'paid_amount': {
            'ro': 'Suma plătită',
            'en': 'Paid amount'
        },
        'payment_remaining_amount': {
            'ro': 'Rest de plată',
            'en': 'Remaining amount'
        },
        'archive': {
            'ro': 'Istoric',
            'en': 'Archive'
        },

        //B
        'back': {
            'ro': 'Înapoi',
            'en': 'Back'
        },
        'basic_price': {
            'ro': 'Prețul de bază',
            'en': 'Basic price'
        },
        'full_price': {
            'ro': 'Prețul întreg',
            'en': 'Full price'
        },
        'buy': {
            'ro': 'Cumpără',
            'en': 'Buy'
        },
        'buyer': {
            'ro': 'Cumpărător',
            'en': 'Buyer'
        },
        'buyer_type': {
            'ro': 'Tip Cumpărător',
            'en': 'Buyer type'
        },
        'business': {
            'ro': 'Afacerea mea',
            'en': 'My Business'
        },
        'birthday': {
            'ro': 'Data nașterii',
            'en': 'Birthday'
        },

        //C
        'calculate': {
            'ro': 'Calculează',
            'en': 'Calculate'
        },
        'apply_code': {
            'ro':'Aplică cod promoțional',
            'en':'Apply promotional cod'
        },
        'cancel': {
            'ro': 'Anulează',
            'en': 'Cancel'
        },
        'cashier': {
            'ro': 'Casier',
            'en': 'Cashier'
        },
        'clients': {
            'ro': 'Client',
            'en': 'Customers'
        },
        'client_profile': {
            'ro': 'Profil client',
            'en': 'Customer Profile'
        },
        'create': {
            'ro': 'Creează',
            'en': 'Create'
        },
        'create_account': {
            'ro': 'Crează-ți un cont',
            'en': 'Create your account'
        },
        'credits': {
            'ro': 'Credite',
            'en': 'Credits'
        },
        'credit': {
            'ro': 'Credit',
            'en': 'Credit'
        },

        'client_subscription': {
            'ro': 'Subscriere client',
            'en': 'Customer Subscription'
        },
        'complete': {
            'ro': 'Completă',
            'en': 'Complete'
        },
        'please_enter_password_with_limitations': {
            'ro': '8-16 caractere, cel puțin o litera mare, o litera mică și o cifră',
            'en': '8-16 characters, at least 1 big letter, 1 small letter and 1 number'
        },
        'characters': {
            'ro': 'caractere',
            'en': 'characters'
        },
        'confirm_password': {
            'ro': 'Confirmă parola',
            'en': 'Confirm password'
        },
        'password_confirmation': {
            'ro': 'Confirmare parolă',
            'en': 'Password confirmation'
        },
        'password_confirm': {
            'ro': 'Confirmați parola',
            'en': 'Confirm password'
        },
        'custom': {
            'ro': 'Personalizat',
            'en': 'Custom'
        },
        'customer': {
            'ro': 'Client',
            'en': 'Customer'
        },
        'customer_email': {
            'ro': 'Email client',
            'en': 'Customer Email'
        },
        'customers': {
            'ro': 'Clienți',
            'en': 'Customers'
        },
        'customer_journals': {
            'ro': 'Jurnal Client',
            'en': 'Customer Journal'
        },
        'customer_services': {
            'ro': 'Servicii Client',
            'en': 'Customer Services'
        },
        'customer_performance': {
            'ro': 'Performanțe Client',
            'en': 'Customer Performance'
        },

        //D
        'dashboard': {
            'ro': 'Panou control',
            'en': 'Dashboard'
        },
        'date': {
            'ro': 'Dată',
            'en': 'Date'
        },
        'delete': {
            'ro': 'Șterge',
            'en': 'Delete'
        },
        'revert': {
            'ro': 'Anulează',
            'en': 'Revert'
        },
        'delete_sale': {
            'ro': 'Șterge vânzare',
            'en': 'Delete sale'
        },
        'revert_sale': {
            'ro': 'Anulează vânzare',
            'en': 'Revert sale'
        },
        'deleted': {
            'ro': 'Șters',
            'en': 'Deleted'
        },
        'delete_customer': {
            'ro': 'Șterge client',
            'en': 'Delete customer'
        },
        'delete_customer_resource_deleted': {
            'ro': 'Clientul a fost șters',
            'en': 'Client deleted'
        },
        'delete_customer_is_user': {
            'ro': 'Clientul este utilizator și nu poate șters',
            'en': 'Client is user and can not be deleted'
        },
        'msg_customer_is_user_deletion_recommendation': {
            'ro': 'Clientul poate șters numai din pagina de utilizatori',
            'en': 'Client can be deleted only from the users page'
        },
        'edit_customer_resource_updated': {
            'ro': 'Clientul a fost actualizat',
            'en': 'Client updated'
        },
        'delete_input': {
            'ro': 'Șterge input',
            'en': 'Detele input'
        },
        'delete_group': {
            'ro': 'Șterge grup',
            'en': 'Delete group'
        },
        'delete_location': {
            'ro': 'Șterge locația',
            'en': 'Delete location'
        },
        'delete_member': {
            'ro': 'Șterge membru',
            'en': 'Delete member'
        },
        'delete_product': {
            'ro': 'Șterge produs',
            'en': 'Delete product'
        },
        'delete_promotion': {
            'ro': 'Șterge promoție',
            'en': 'Delete promotion'
        },
        'delete_welcome_subscription': {
            'ro': 'Șterge abonament bonus de înregistrare',
            'en': 'Delete welcome subscription'
        },
        'delete_role': {
            'ro': 'Șterge rol',
            'en': 'Delete role'
        },
        'delete_service': {
            'ro': 'Șterge serviciu',
            'en': 'Delete service'
        },
        'delete_subscription': {
            'ro': 'Șterge abonament',
            'en': 'Delete subscription'
        },
        'delete_subscription_included_service': {
            'ro': 'Șterge serviciu inclus',
            'en': 'Delete included service'
        },
        'delete_subscription_included_product': {
            'ro': 'Șterge produs inclus',
            'en': 'Delete included product'
        },
        'delete_service_included_provider': {
            'ro': 'Șterge prestatorul inclus',
            'en': 'Delete included provider'
        },
        'standard_subscription_product_resource_deleted': {
            'ro': 'Produsul inclus a fost scos din abonament',
            'en': 'Included product has been deleted from the subscription'
        },
        'standard_subscription_service_resource_deleted': {
            'ro': 'Serviciul inclus a fost scos din abonament',
            'en': 'Included service has been deleted from the subscription'
        },
        'custom_subscription_service_resource_deleted': {
            'ro': 'Serviciul inclus a fost scos din abonament',
            'en': 'Included service has been deleted from the subscription'
        },
        'service_provider_resource_updated': {
            'ro': 'Angajatul a fost inclus în lista de prestatori',
            'en': 'The worker has been added to providers'
        },
        'selected_provider': {
            'ro': 'Prestatorul selectat',
            'en': 'The selected provider'
        },
        'selected_consultant': {
            'ro': 'Consultantul selectat',
            'en': 'The selected consultant'
        },
        'service_provider_resource_deleted': {
            'ro': 'Angajatul a fost scos din lista de prestatori',
            'en': 'The worker has been deleted from providers'
        },
        'planning_service_provider_resource_added': {
            'ro': 'Prestatorul a fost adăugat',
            'en': 'The provider has been added'
        },
        'planning_service_provider_resource_deleted': {
            'ro': 'Prestatorul a fost șters',
            'en': 'The provider has been deleted'
        },
        'standard_subscription_product_resource_added': {
            'ro': 'Produsul a fost inclus în abonament',
            'en': 'The product has been added to the subscription'
        },
        'standard_subscription_service_resource_added': {
            'ro': 'Serviciul a fost inclus în abonament',
            'en': 'The service has been added to the subscription'
        },
        'custom_subscription_service_resource_added': {
            'ro': 'Serviciul a fost inclus în abonament',
            'en': 'The service has been added to the subscription'
        },
        'standard_subscription_product_json_key_is_required_quantity': {
            'ro': 'Te rugăm să completezi cantitatea produsului',
            'en': 'Please enter the required quantity'
        },
        'standard_subscription_product_invalid_key_value_quantity': {
            'ro': 'Te rugăm să completezi cantitatea produsului, în mod corect',
            'en': 'Please enter correctly the required quantity'
        },
        'standard_subscription_service_json_key_is_required_credits': {
            'ro': 'Te rugăm să completezi creditele serviciului',
            'en': 'Please enter the required credits'
        },
        'standard_subscription_service_invalid_key_value_credits': {
            'ro': 'Te rugăm să completezi creditele serviciului, în mod corect',
            'en': 'Please enter correctly the required credits'
        },
        'delete_user': {
            'ro': 'Șterge contul de utilizator',
            'en': 'Delete user account'
        },
        'delete_worker': {
            'ro': 'Șterge angajat',
            'en': 'Delete worker'
        },
        'delete_worker_is_user': {
            'ro': 'Angajatul este utilizator și nu poate șters',
            'en': 'Worker is user and can not be deleted'
        },
        'msg_worker_is_user_deletion_recommendation': {
            'ro': 'Angajatul poate șters numai din pagina de utilizatori',
            'en': 'Worker can be deleted only from the users page'
        },
        'deleting_customer': {
            'ro': 'Ești sigur că vrei să ștergi clientul?',
            'en': 'Are you sure you want to delete this customer?'
        },
        'deleting_group': {
            'ro': 'Ești sigur că vrei să ștergi grupul?',
            'en': 'Are you sure you want to delete this group?'
        },
        'deleting_location': {
            'ro': 'Ești sigur că vrei să ștergi locația?',
            'en': 'Are you sure you want to delete this location?'
        },
        'deleting_member': {
            'ro': 'Ești sigur că vrei să ștergi membrul grupului? Toate informațiile legate de acest membru vor fi pierdute.',
            'en': 'Are you sure you want to delete this member? All data related to this member will be lost.'
        },
        'deleting_product': {
            'ro': 'Ești sigur că vrei să ștergi produsul?',
            'en': 'Are you sure you want to delete this product?'
        },
        'deleting_sale': {
            'ro': 'Ești sigur că vrei să ștergi vânzarea?',
            'en': 'Are you sure you want to delete this sale?'
        },
        'reverting_sale': {
            'ro': 'Sunteți sigur că vreți să anulați vânzarea?',
            'en': 'Are you sure you want to revert this sale?'
        },
        'delete_sale_json_key_is_required_deletion_reason': {
            'ro': 'Te rugăm să completezi motivul ștergerii',
            'en': 'Please fill in the deletion reason'
        },
        'deletion_reason': {
            'ro': 'Motivul ștergerii',
            'en': 'Deletion reason'
        },
        'revert_reason': {
            'ro': 'Motivul anulării',
            'en': 'Revert reason'
        },
        'sale_deleted_by': {
            'ro': 'Ștearsă de',
            'en': 'Deleted by'
        },
        'revert_sale_confirmation': {
            'ro': 'Această acțiune va șterge toate plățile asociate vânzării și va anula toate creditele care au fost date clientului la vânzare.',
            'en': 'This action will delete all payments associate with the sale and will cancel all the credits which was received by client on sale'
        },
        'revert_sale_confirmation_no_undo': {
            'ro': 'Sunteți sigur că vreți să continuați?',
            'en': 'Are you sure you want to continue?'
        },
        'sale_get_date': {
            'ro': 'Obține data',
            'en': 'Get date'
        },
        'sale_deleted_at': {
            'ro': 'Ștearsă la',
            'en': 'Deleted at'
        },
        'delete_sale_resource_deleted': {
            'ro': 'Vânzare ștearsă',
            'en': 'Sale deleted'
        },
        'deleting_promotion': {
            'ro': 'Ești sigur că vrei să ștergi promoția?',
            'en': 'Are you sure you want to delete this promotion?'
        },
        'deleting_welcome_subscription': {
            'ro': 'Ești sigur că vrei să ștergi acest abonament bonus de înregistrare?',
            'en': 'Are you sure you want to delete this welcome subscription?'
        },
        'deleting_role': {
            'ro': 'Ești sigur că vrei să ștergi rolul?',
            'en': 'Are you sure you want to delete this role?'
        },
        'deleting_service': {
            'ro': 'Ești sigur că vrei să ștergi serviciul?',
            'en': 'Are you sure you want to delete this service?'
        },
        'deleting_subscription': {
            'ro': 'Ești sigur că vrei să ștergi abonamentul?',
            'en': 'Are you sure you want to delete this subscription?'
        },
        'deleting_subscription_included_product': {
            'ro': 'Ești sigur că vrei să ștergi produsul inclus?',
            'en': 'Are you sure you want to delete this included product?'
        },
        'deleting_service_included_provider': {
            'ro': 'Ești sigur că vrei să ștergi prestatorul inclus?',
            'en': 'Are you sure you want to delete this included provider?'
        },
        'deleting_subscription_included_service': {
            'ro': 'Ești sigur că vrei să ștergi serviciul inclus?',
            'en': 'Are you sure you want to delete this included service?'
        },
        'deleting_user': {
            'ro': 'Ești sigur că vrei să ștergi utilizatorul?',
            'en': 'Are you sure you want to delete this user?'
        },
        'deleting_worker': {
            'ro': 'Ești sigur că vrei să ștergi angajatul?',
            'en': 'Are you sure you want to delete this worker?'
        },
        'description': {
            'ro': 'Descriere',
            'en': 'Description'
        },
        'detail': {
            'ro': 'Detaliu',
            'en': 'Detail'
        },
        'details': {
            'ro': 'Detalii',
            'en': 'Details'
        },
        'detail_group': {
            'ro': 'Detalii grup',
            'en': 'Group details'
        },
        'details_product': {
            'ro': 'Detalii produs',
            'en': 'Details product'
        },
        'details_promotion': {
            'ro': 'Detalii promoție',
            'en': 'Details promotion'
        },
        'details_service': {
            'ro': 'Detalii serviciu',
            'en': 'Details service'
        },
        'details_subscription': {
            'ro': 'Detalii abonament',
            'en': 'Details subscription'
        },
        'discount': {
            'ro': 'Discount',
            'en': 'Discount'
        },
        'discount_price': {
            'ro': 'Discount',
            'en': 'discount'
        },
        'discount_value': {
            'ro': 'Valoare discount',
            'en': 'Discount value'
        },
        'discount_type': {
            'ro': 'Tip discount',
            'en': 'Type discount'
        },
        'duration': {
            'ro': 'Perioadă',
            'en': 'Duration'
        },
        'dezactivate': {
            'ro': 'Dezactivează',
            'en': 'Dezactivate'
        },

        //E
        'edit': {
            'ro': 'Editează',
            'en': 'Edit'
        },
        'edit_promotion': {
            'ro': 'Editează promoție',
            'en': 'Edit promotion'
        },
        'edit_customer': {
            'ro': 'Editează client',
            'en': 'Edit customer'
        },
        'edit_group': {
            'ro': 'Editează grup',
            'en': 'Edit group'
        },
        'edit_location': {
            'ro': 'Editează locația',
            'en': 'Edit location'
        },
        'edit_price': {
            'ro': 'Editează preț',
            'en': 'Edit price'
        },
        'edit_product': {
            'ro': 'Editează produs',
            'en': 'Edit product'
        },
        'edit_role': {
            'ro': 'Editează rol',
            'en': 'Edit role'
        },
        'edit_role_cannot_rename_admin_role': {
            'ro': 'Rolul de admin nu poate fi redenumit',
            'en': 'Admin role cannot be renamed'
        },
        'edit_role_json_key_is_required_role_name': {
            'ro': 'Te rugăm să completezi numele rolului',
            'en': 'Please fill in the name of the role'
        },
        'edit_service': {
            'ro': 'Editează serviciu',
            'en': 'Edit service'
        },
        'edit_subscription': {
            'ro': 'Editează abonament',
            'en': 'Edit subscription'
        },
        'edit_user': {
            'ro': 'Editează utilizator',
            'en': 'Edit user'
        },
        'edit_worker': {
            'ro': 'Editează angajat',
            'en': 'Edit worker'
        },
        'email': {
            'ro': 'Adresă email',
            'en': 'Email'
        },
        'employee': {
            'ro': 'Angajat',
            'en': 'Worker'
        },
        'employees': {
            'ro': 'Angajați',
            'en': 'Workers'
        },

        //F
        'features': {
            'ro': 'Operații',
            'en': 'Features'
        },
        'final': {
            'ro': 'Finalizează',
            'en': 'Finish'
        },
        'finish_sale': {
            'ro': 'Finalizează vânzarea',
            'en': 'Finish sale'
        },
        'first_name': {
            'ro': 'Prenume',
            'en': 'First Name'
        },
        'fix': {
            'ro': 'Fix',
            'en': 'Fix'
        },
        'feedback': {
            'ro': 'Recenzie',
            'en': 'Feedback'
        },
        'password_recovery': {
            'ro': 'Recuperare parolă',
            'en': 'Password recovery'
        },
        'password_resetted_login_account': {
            'ro': 'Parola a fost resetată. Autentifică-te în contul tău',
            'en': 'Password resetted. Login to your account'
        },
        'account_activated_login_account': {
            'ro': 'Contul a fost activat. Autentifică-te în contul tău',
            'en': 'Account activated. Login to your account'
        },
        'account_activated_success': {
            'ro': 'Contul a fost activat.',
            'en': 'Account activated.'
        },
        'forgot_password': {
            'ro': 'Ai uitat parola?',
            'en': 'Forgot Password?'
        },
        'form': {
            'ro': 'Formular',
            'en': 'Form'
        },

        //G
        'gender': {
            'ro': 'Gen',
            'en': 'Gender'
        },
        'go_to_login': {
            'ro': 'Autentifică-te aici',
            'en': 'Login here:'
        },
        'group': {
            'ro': 'Grup',
            'en': 'Group'
        },
        'group_name': {
            'ro': 'Nume grup',
            'en': 'Group name'
        },
        'group_owner': {
            'ro': 'Deţinătorul grupului',
            'en': 'Group owner'
        },
        'group_member': {
            'ro': 'Membrii grupului',
            'en': 'Group member'
        },
        'groups': {
            'ro': 'Grupuri',
            'en': 'Groups'
        },

        //H
        'home': {
            'ro': 'Acasă',
            'en': 'Home'
        },
        'hour': {
            'ro': 'Ora',
            'en': 'Hour'
        },

        //I
        'image': {
            'ro': 'Imagine',
            'en': 'Image'
        },
        'identity': {
            'ro': 'Identitate',
            'en': 'Identity'
        },
        'include_service_credits': {
            'ro': 'Servicii și credite incluse',
            'en': 'Included Services and credits'
        },
        'included_products': {
            'ro': 'Produse incluse',
            'en': 'Included products'
        },
        'invite_member': {
            'ro': 'Invită membru',
            'en': 'Invite member'
        },
        'images': {
            'ro': 'Imagini',
            'en': 'Images'
        },
        'inactive': {
            'ro': 'Inactiv',
            'en': 'Inactive'
        },

        //J

        //K

        //L
        'last_name': {
            'ro': 'Nume de familie',
            'en': 'Last name'
        },
        'location': {
            'ro': 'Locație',
            'en': 'Location'
        },
        'locations': {
            'ro': 'Locații',
            'en': 'Locations'
        },
        'logged_schedules': {
            'ro': 'Marcari antrenamente',
            'en': 'Trainings markings'
        },
        'login': {
            'ro': 'Autentificare',
            'en': 'Login'
        },
        'login_account': {
            'ro': 'Autentifică-te în contul tău',
            'en': 'Login to your account'
        },
        'logout': {
            'ro': 'Deconectare',
            'en': 'Logout'
        },

        //M
        'make_it_user': {
            'ro': 'Fă-l utilizator',
            'en': 'Make it user'
        },
        'max_usage': {
            'ro': 'Numărul maxim de utilizări',
            'en': 'Max usage'
        },
        'total_usage': {
            'ro': 'Numărul de clienți care au primit acest abonament',
            'en': 'Number of customers who received this subscription'
        },
        'members': {
            'ro': 'Membrii',
            'en': 'Members'
        },
        'members_in_group': {
            'ro': 'Membri în grup',
            'en': 'Members in group'
        },
        'my_groups': {
            'ro': 'Grupurile mele',
            'en': 'My groups'
        },
        //N
        'name': {
            'ro': 'Nume',
            'en': 'Name'
        },
        'next': {
            'ro': 'Următorul',
            'en': 'Next'
        },
        'no': {
            'ro': 'Nu',
            'en': 'No'
        },
        'notes': {
            'ro': 'Notițe',
            'en': 'Notes'
        },
        'no_results': {
            'ro': 'Fără rezultate',
            'en': 'No results'
        },

        //O
        'owner': {
            'ro': 'Proprietar',
            'en': 'Owner'
        },
        'offers': {
            'ro': 'Cumpără',
            'en': 'Buy'
        },
        'optional': {
            'ro': '(opțional)',
            'en': '(optional)'
        },
        //P
        'paid': {
            'ro': 'Plătit',
            'en': 'Paid'
        },
        'password': {
            'ro': 'Parolă',
            'en': 'Password'
        },
        'payments': {
            'ro': 'Plăți',
            'en': 'Payments'
        },
        'payments_nav': {
            'ro': 'Încasări',
            'en': 'Payments'
        },
        'percentage': {
            'ro': 'Procentaj',
            'en': 'Percentage'
        },
        'payment_date': {
            'ro': 'Data plății',
            'en': 'Payment date'
        },
        'payment_method': {
            'ro': 'Metodă plată',
            'en': 'Payment method'
        },
        'payment_type': {
            'ro': 'Tip plată',
            'en': 'Payment type'
        },
        'payment_status': {
            'ro': 'Status plată',
            'en': 'Payment status'
        },
        'phone': {
            'ro': 'Telefon mobil',
            'en': 'Phone'
        },
        'planning_only': {
            'ro': 'Doar programare',
            'en': 'Planning only'
        },
        'plannings': {
            'ro': 'Planificări',
            'en': 'Plannings'
        },
        'predefined': {
            'ro': 'Predefinit',
            'en': 'Predefined'
        },
        'previous': {
            'ro': 'Precedent',
            'en': 'Previous'
        },
        'price': {
            'ro': 'Preț',
            'en': 'Price'
        },
        'prices': {
            'ro': 'Prețuri',
            'en': 'Prices'
        },
        'price_history': {
            'ro': 'Istoric prețuri',
            'en': 'Price history'
        },
        'product': {
            'ro': 'Produs',
            'en': 'Product'
        },
        'products': {
            'ro': 'Produse',
            'en': 'Products'
        },
        'profile': {
            'ro': 'Profilul meu',
            'en': 'My Profile'
        },
        'profile_identity': {
            'ro': 'Profil deținător',
            'en': 'Profile Identity'
        },
        'promo_code': {
            'ro': 'Cod promoțional',
            'en': 'Promo code'
        },
        'promo_type': {
            'ro': 'Tip promoțional',
            'en': 'Promo type'
        },
        'promotions': {
            'ro': 'Promoții',
            'en': 'Promotions'
        },
        'only_standard_promotions': {
            'ro': 'Standard',
            'en': 'Standard'
        },
        'only_promo_code_promotions': {
            'ro': 'Promo code',
            'en': 'Promo code'
        },
        'target_type_promotions': {
            'ro': 'Tipul promoției',
            'en': 'Promotion type'
        },
        'target_type_selection': {
            'ro': 'Alege tipul promoției',
            'en': 'Choose promotion type'
        },
        'choose_promotions_by_custom_subscription': {
            'ro': 'Alege abonamentul personalizat',
            'en': 'Choose custom subscription'
        },
        'choose_promotions_by_subscription': {
            'ro': 'Alege abonamentul',
            'en': 'Choose subscription'
        },
        'choose_promotions_by_product': {
            'ro': 'Alege produsul',
            'en': 'Choose product'
        },
        'choose_promotions_by_service': {
            'ro': 'Alege serviciul',
            'en': 'Choose service'
        },
        'choose_all_promotions_by_custom_subscription': {
            'ro': 'Toate abonamentele',
            'en': 'Choose all subscriptions'
        },
        'choose_all_promotions_by_subscription': {
            'ro': 'Toate abonamentele',
            'en': 'Choose all subscriptions'
        },
        'choose_all_promotions_by_product': {
            'ro': 'Toate produsele',
            'en': 'Choose all products'
        },
        'choose_all_promotions_by_service': {
            'ro': 'Toate serviciile',
            'en': 'Choose all services'
        },
        'welcome_subscriptions': {
            'ro': 'Abonamente bonus de înregistrare',
            'en': 'Welcome Subscriptions'
        },
        'welcome_subscription': {
            'ro': 'Abonament bonus de înregistrare',
            'en': 'Welcome Subsciption'
        },
        'provided_services': {
            'ro': 'Servicii oferite',
            'en': 'Provided Services'
        },
        'providers_list': {
            'ro': 'Listă provideri',
            'en': 'Providers list'
        },
        //Q
        'quantity': {
            'ro': 'Cantitate',
            'en': 'Quantity'
        },
        //R
        'register': {
            'ro': 'Înregistrare',
            'en': 'Register'
        },
        'register_free': {
            'ro': 'Înregistrarea este gratuită.',
            'en': 'Registration is free.'
        },
        'register_free_paragraph_info': {
            'ro': 'Completeaza formularul cu datele tale personale, pentru a crea un cont.',
            'en': 'Fill in the form with your data, in order to create an account.'
        },
        'register_here': {
            'ro': 'Dacă nu ai un cont creat. te rugăm să te înregistrezi aici.',
            'en': 'If you don\'t have already an account, please register here.'
        },
        'already_have_account_login': {
            'ro': 'Ai deja un cont creat? Te rugăm să te autentifici.',
            'en': 'Already have an account created? Please log in.'
        },
        'first_name_is_required': {
            'ro': 'Prenumele este obligatoriu',
            'en': 'First name is mandatory'
        },
        'last_name_is_required': {
            'ro': 'Numele de familie este obligatoriu',
            'en': 'Name is mandatory'
        },
        'email_is_required': {
            'ro': 'Adresa de email este obligatorie',
            'en': 'Email is mandatory'
        },
        'email_is_invalid': {
            'ro': 'Te rugăm să introduci o adresă de email validă',
            'en': 'Please enter a valid email address'
        },
        'phone_is_required': {
            'ro': 'Telefonul este obligatoriu',
            'en': 'Phone is mandatory'
        },
        'gender_is_required': {
            'ro': 'Genul este obligatoriu',
            'en': 'Gender is mandatory'
        },
        'birth_date_is_required': {
            'ro': 'Data nașterii este obligatorie',
            'en': 'Birth date is mandatory'
        },
        'password_is_required': {
            'ro': 'Parola este obligatorie',
            'en': 'Password is mandatory'
        },
        'confirm_password_is_required': {
            'ro': 'Confirmarea parolei este obligatorie',
            'en': 'Confirm password is mandatory'
        },
        'confirm_password_must_match': {
            'ro': 'Parolele trebuie să fie identice',
            'en': 'Passwords must match'
        },
        'check_your_email': {
            'ro': 'Contul a fost creat, verifică-ți email-ul',
            'en': 'Account successfully created, check your email'
        },
        'forgot_password_check_your_email': {
            'ro': 'Te rugăm să îți verifici email-ul',
            'en': 'Please check your email'
        },
        'forgot_password_user_not_found': {
            'ro': 'Acest email nu corespunde unui utilizator',
            'en': 'Email not corresponding to any user'
        },
        'unsafe_password': {
            'ro': 'Parola nu satisface condițiile de securitate',
            'en': 'The password does not satisfy our security conditions'
        },
        'customer_cannot_be_created': {
            'ro': 'Contul nu a putut fi creat',
            'en': 'The account could not be created'
        },
        'selected_language': {
            'ro': 'Limba',
            'en': 'Language'
        },
        'registered_account': {
            'ro': 'Cont înregistrat',
            'en': 'Registered account'
        },
//        'receive_emails_is_mandatory': {
//            'ro': 'Pentru a primi mail-uri de la noi avem nevoie de aprobare',
//            'en': 'To receive emails from us we need approval'
//        },
        'receive_emails_is_mandatory': {
            'ro': 'Sunt de acord să primesc e-mail-uri în legătură cu: abonamente, rezervări etc.',
            'en': 'I agree to receive e-mails related to: subscriptions, schedules, etc.'
        },
        'reject_invitation': {
            'ro': 'Respinge invitația',
            'en': 'Reject invitation'
        },
        'repeat_password': {
            'ro': 'Confirmă Parolă',
            'en': 'Repeat Password'
        },
        'reset_password': {
            'ro': 'Resetare parolă',
            'en': 'Reset password'
        },
        'change_password': {
            'ro': 'Schimbă parolă',
            'en': 'Change password'
        },
        'change_of_password': {
            'ro': 'Schimbare parolă',
            'en': 'Change the password'
        },
        'change_password_password_changed': {
            'ro': 'Parola a fost schimbată',
            'en': 'Password changed'
        },
        'change_password_invalid_credentials': {
            'ro': 'Parola curentă este incorectă',
            'en': 'Incorect current password'
        },
        'respond_to_group_invitation': {
            'ro': 'Răspunde la invitația de grup',
            'en': 'Respond to group invitation'
        },
        'selected_group': {
            'ro': 'Grupul selectat',
            'en': 'Selected group'
        },
        'choose_group': {
            'ro': 'Alege grupul',
            'en': 'Choose group'
        },
        'all_groups': {
            'ro': 'Toate grupurile',
            'en': 'All groups'
        },
        'results_found': {
            'ro': 'Rezultate găsite',
            'en': 'Results found'
        },
        'request_new_group': {
            'ro': 'Solicită grup nou',
            'en': 'Request new group'
        },
        'request_new_group_json_key_is_required_name': {
            'ro': 'Te rugăm să completezi numele grupului',
            'en': 'Please fill in the name of the group'
        },
        'request_new_group_resource_added': {
            'ro': 'Solicitarea a fost trimisă',
            'en': 'Request sent'
        },
        'your_group_request_is_waiting_for_approval': {
            'ro': 'Solicitarea ta de grup aşteaptă aprobare',
            'en': 'Your group request is waiting for approval'
        },
        'you_already_own_group': {
            'ro': 'Aveți deja un grup',
            'en': 'You already have one group'
        },
        'select_language': {
            'ro': 'Limba',
            'en': 'Language'
        },
        'default_language': {
            'ro': 'Limba implicită',
            'en': 'Default language'
        },
        'enter_customer_email': {
            'ro': 'Adresa de email a clientului',
            'en': 'Customer\'s email'
        },
        'group_invite': {
            'ro': 'Invitație de grup',
            'en': 'Group invitation'
        },
        'invite_group_member_invitation_sent': {
            'ro': 'Invitația a fost trimisă',
            'en': 'Invitation sent'
        },
        'invite_group_member_invalid_key_value': {
            'ro': 'Limba trebuie selectată',
            'en': 'Language must be selected'
        },
        'invite_group_member_json_key_is_required_lang': {
            'ro': 'Limba trebuie selectată',
            'en': 'Language must be selected'
        },
        'invite_group_member_invalid_request': {
            'ro': 'Nu se pot face invitații pentru acest grup',
            'en': 'Invites for this group are not possible'
        },
        'require_subscription': {
            'ro': 'Necesită abonament',
            'en': 'Require subscription'
        },
        'role_features': {
            'ro': 'Drepturi',
            'en': 'Rights'
        },
        'role_for_user': {
            'ro': 'Rolurile utilizatorilor',
            'en': 'User roles'
        },
        'roles': {
            'ro': 'Roluri',
            'en': 'Roles'
        },
        'roles_and_rights': {
            'ro': 'Roluri și Drepturi',
            'en': 'Roles and rights'
        },
        'select_worker': {
            'ro': 'Selectează angajatul',
            'en': 'Select worker'
        },
        'selected_worker': {
            'ro': 'Angajatul selectat',
            'en': 'Selected worker'
        },

        //S
        'save': {
            'ro': 'Salvează',
            'en': 'Save'
        },
        'sales': {
            'ro': 'Vânzări',
            'en': 'Sales'
        },
        'sales_and_payments': {
            'ro': 'Vânzări și plăți',
            'en': 'Sale and Payments'
        },
        'select': {
            'ro': 'Selectează',
            'en': 'Select'
        },
        'select_sale': {
            'ro': 'Selectează vânzare',
            'en': 'Select sale'
        },
        'search': {
            'ro': 'Caută',
            'en': 'Search'
        },
        'see_payment': {
            'ro': 'Vizualizare plată',
            'en': 'See payment'
        },
        'see_sale': {
            'ro': 'Vizualizare vânzare',
            'en': 'See sale'
        },
        'see_payment_panel': {
            'ro': 'Vizualizare încasare',
            'en': 'See payment'
        },
        'send': {
            'ro': 'Trimite',
            'en': 'Send'
        },
        'send_request_new_group': {
            'ro': 'Trimite solicitarea',
            'en': 'Send request'
        },
        'send_invite': {
            'ro': 'Trimite invitația',
            'en': 'Send invite'
        },
        'set_password': {
            'ro': 'Setare parolă',
            'en': 'Set password'
        },
        'settings': {
            'ro': 'Setări',
            'en': 'Settings'
        },
        'service': {
            'ro': 'Serviciu',
            'en': 'Service'
        },
        'services': {
            'ro': 'Servicii',
            'en': 'Services'
        },
        'selected_services': {
            'ro': 'Servicii selectate',
            'en': 'Selected services'
        },
        'included_services': {
            'ro': 'Servicii incluse',
            'en': 'Included services'
        },
        'schedule_events': {
            'ro': 'Evenimente planificate',
            'en': 'Schedule events'
        },
        'schedule': {
            'ro': 'Rezervare',
            'en': 'Schedule'
        },
        'select_provider': {
            'ro': 'Prestator',
            'en': 'Provider'
        },
        'select_consultant': {
            'ro': 'Consultant',
            'en': 'Consultant'
        },
        'consultant': {
            'ro': 'Consultant',
            'en': 'Consultant'
        },
        'view_providers': {
            'ro': 'Vezi prestatorii',
            'en': 'View providers'
        },
        'no_schedules_yet': {
            'ro': 'Nu sunt rezervări',
            'en': 'No schedules yet'
        },
        'shop_payments': {
            'ro': 'Comenzile mele',
            'en': 'Shop and payments'
        },
        'shop_product': {
            'ro': 'Cumpără produs',
            'en': 'Shop product'
        },
        'shop_service': {
            'ro': 'Cumpără serviciu',
            'en': 'Shop service'
        },
        'shop_subscription': {
            'ro': 'Cumpără abonament',
            'en': 'Shop subscription'
        },
        'standard_promo': {
            'ro': 'Promoție standard',
            'en': 'Standard promo'
        },
        'standard': {
            'ro': 'Standard',
            'en': 'Standard'
        },
        'stock': {
            'ro': 'Stoc',
            'en': 'Stock'
        },
        'fixed': {
            'ro': 'Fix',
            'en': 'Fix'
        },
        'subcontractors': {
            'ro': 'Subcontractori',
            'en': 'Subcontractors'
        },
        'subscription': {
            'ro': 'Abonament',
            'en': 'Subscription'
        },
        'subscriptions': {
            'ro': 'Abonamente',
            'en': 'Subscriptions'
        },
        'subscription_included_services': {
            'ro': 'Servicii incluse în abonament',
            'en': 'Subscription included services'
        },
        'subscription_price': {
            'ro': 'Prețul în abonament',
            'en': 'Subscription price'
        },
        'success_activate': {
            'ro': 'Contul înregistrat a fost activat cu succes',
            'en': 'Your account as activated succesfully'
        },
        'sum': {
            'ro': 'Sumă',
            'en': 'Sum'
        },

        //T
        'target': {
            'ro': 'Țintă',
            'en': 'Target'
        },
        'title': {
            'ro': 'Titlu',
            'en': 'Title'
        },
        'this_subscription_includes_following_services': {
            'ro': 'Acest abonament include serviciile',
            'en': 'This subscription includes these services'
        },
        'this_service_is_available_only_with_subscription': {
            'ro': 'Acest serviciu este disponibil doar cu abonament',
            'en': 'This service is available only with subscription'
        },
        'this_service_is_available_only_with_the_following_subscriptions': {
            'ro': 'Acest serviciu este disponibil doar în următoarele abonamente',
            'en': 'This service is available only with the following subscriptions'
        },
        'this_service_is_also_available_with_the_following_subscriptions': {
            'ro': 'Acest serviciu este disponibil și în următoarele abonamente',
            'en': 'This service is available also with the following subscriptions'
        },
        'type': {
            'ro': 'Tip',
            'en': 'Type'
        },

        //U
        'user': {
            'ro': 'Utilizator',
            'en': 'User'
        },
        'users_and_groups': {
            'ro': 'Utilizatori și grupuri',
            'en': 'Users and groups'
        },
        'user-management': {
            'ro': 'Utilizatori și grupuri',
            'en': 'Users and groups'
        },
        'users': {
            'ro': 'Utilizatori',
            'en': 'Users'
        },
        'user_type': {
            'ro': 'Tipul de utilizator',
            'en': 'User type'
        },
        'delete_user_resource_deleted': {
            'ro': 'Utilizatorul a fost șters',
            'en': 'User deleted'
        },
        'users': {
            'ro': 'Utilizatori',
            'en': 'Users'
        },
        'users_for_role': {
            'ro': 'Utilizatori după roluri',
            'en': 'Users for role'
        },

        //V
        'valid_from': {
            'ro': 'Valid din',
            'en': 'Valid from'
        },
        'valid_from_date': {
            'ro': 'Din data de',
            'en': 'Valid from'
        },
        'valid_until_date': {
            'ro': 'Până în data de',
            'en': 'Valid until'
        },
        'valid_until': {
            'ro': 'Valid până la',
            'en': 'Valid until'
        },
        //W
        'welcome': {
            'ro': 'Bine ai venit',
            'en': 'Welcome'
        },
        'worker': {
            'ro': 'Angajat',
            'en': 'Worker'
        },
        'workers': {
            'ro': 'Angajați',
            'en': 'Workers'
        },
        'worker_names': {
            'ro': 'Numele angajaților',
            'en': 'Worker names'
        },
        'make_user': {
            'ro': 'Creează utilizator',
            'en': 'Make user'
        },
        'making_user': {
            'ro': 'Ești sigur că vrei să creezi utilizator pentru acest angajat?',
            'en': 'Are you sure you want to make user for this employy?'
        },
        'making_user_additional_info': {
            'ro': 'După crearea utilizatorului, angajatul va primi un email cu instrucțiuni pentru setarea parolei.',
            'en': 'After the user creation, the employee will receive by email instructions for setting up the password.'
        },
        'user_email_already_registered': {
            'ro': 'Utilizator existent cu această adresă de email',
            'en': 'User exists with this email address'
        },
        'worker_is_user': {
            'ro': 'Angajatul este utilizator',
            'en': 'Employee is user'
        },
        'worker_is_not_user': {
            'ro': 'Angajatul nu este utilizator',
            'en': 'Employee is not user'
        },

        //X
        //Y
        'yes': {
            'ro': 'Da',
            'en': 'Yes'
        },
        //Z

        //PLACEHOLDER
        'enter_title': {
            'ro': 'Introduceți titlul',
            'en': 'Enter title'
        },
        'enter_address': {
            'ro': 'Introduceți adresa',
            'en': 'Enter address'
        },
        'enter_name': {
            'ro': 'Introduceți numele',
            'en': 'Enter name'
        },
        'enter_sum': {
            'ro': 'Introduceți suma',
            'en': 'Enter sum'
        },
        'enter_lastname': {
            'ro': 'Introduceți numele de familie',
            'en': 'Enter last name'
        },
        'enter_firstname': {
            'ro': 'Introduceți prenumele',
            'en': 'Enter first name'
        },
        'enter_email': {
            'ro': 'Introduceți adresa de email',
            'en': 'Enter email'
        },
        'enter_phone': {
            'ro': 'Introduceți numărul de telefon',
            'en': 'Enter phone'
        },
        'enter_duration': {
            'ro': 'Introduceți perioada',
            'en': 'Enter duration'
        },
        'enter_accessPrice': {
            'ro': 'Introduceți prețul de acces',
            'en': 'Enter access price'
        },
        'enter_subscription_price': {
            'ro': 'Introduceți prețul de abonament',
            'en': 'Enter subscription price'
        },
        'enter_subscription_credits': {
            'ro': 'Introduceți creditele abonamentului',
            'en': 'Enter subscription credits'
        },
        'enter_birthday': {
            'ro': 'Introduceți data nașterii',
            'en': 'Enter birthday'
        },
        'enter_basicPrice': {
            'ro': 'Introduceți prețul de bază',
            'en': 'Enter basic price'
        },
        'enter_amount': {
            'ro': 'Introduceți cantitate',
            'en': 'Enter amount'
        },
        'enter_paid_amount': {
            'ro': 'Introduceți suma plătită',
            'en': 'Enter paid amount'
        },
        'enter_description': {
            'ro': 'Introduceți descrierea',
            'en': 'Enter description'
        },
        'enter_discount': {
            'ro': 'Introduceți discount',
            'en': 'Enter discount'
        },
        'enter_discountValue': {
            'ro': 'Introduceți discount',
            'en': 'Enter discount'
        },
        'enter_promotion': {
            'ro': 'Introduceți promoție',
            'en': 'Enter promotion'
        },
        'enter_password': {
            'ro': 'Introduceți parolă',
            'en': 'Enter password'
        },
        'enter_promocode': {
            'ro': 'Introduceți codul promoției',
            'en': 'Enter promocode'
        },
        'enter_subscriptionPrice': {
            'ro': 'Introduceți prețul în abonament',
            'en': 'Enter subscription price'
        },
        'enter_type': {
            'ro': 'Introduceți tip',
            'en': 'Enter type'
        },
        'enter_locationName': {
            'ro': 'Introduceți numele locației',
            'en': 'Enter location name'
        },
        'enter_serviceName': {
            'ro': 'Introduceți numele serviciului',
            'en': 'Enter service name'
        },
        'enter_product': {
            'ro': 'Introduceți numele produsului',
            'en': 'Enter product name'
        },
        'enter_owner': {
            'ro': 'Introduceți proprietar',
            'en': 'Enter owner'
        },
        'enter_quantity': {
            'ro': 'Introduceți cantitatea produsului',
            'en': 'Enter product quantity'
        },
        'enter_credits': {
            'ro': 'Introduceți creditele serviciului',
            'en': 'Enter service credits'
        },
        'enter_maxUsage': {
            'ro': 'Introduceți numărul maxim de utilizări',
            'en': 'Enter max usage'
        },
        'find_group': {
            'ro': 'Caută grup',
            'en': 'Search group'
        },
        'owners': {
            'ro': 'Proprietari',
            'en': 'Owners'
        },
        'find_owners': {
            'ro': 'Caută proprietari',
            'en': 'Search owners'
        },
        'find_worker': {
            'ro': 'Caută angajat',
            'en': 'Search worker'
        },
        'find_provider': {
            'ro': 'Caută prestator',
            'en': 'Search provider'
        },
        'find_location': {
            'ro': 'Caută locații',
            'en': 'Search locations'
        },
        'find_user': {
            'ro': 'Caută utilizatori',
            'en': 'Search users'
        },
        'find_client': {
            'ro': 'Caută client',
            'en': 'Search client'
        },
        'find_roles': {
            'ro': 'Caută roluri',
            'en': 'Search roles'
        },
        'find_subscription': {
            'ro': 'Caută abonament',
            'en': 'Search subscription'
        },
        'find_service': {
            'ro': 'Caută serviciu',
            'en': 'Search service'
        },
        'find_product': {
            'ro': 'Caută produs',
            'en': 'Search product'
        },
        'find_promotion': {
            'ro': 'Caută promoție',
            'en': 'Search promotion'
        },
        'find_welcome_subscription': {
            'ro': 'Caută abonament bonus de înregistrare',
            'en': 'Search welcome subscription'
        },

        // Add Sale Form.
        'add_sale_promo_code': {
            'ro': 'Cod promoțional',
            'en': 'Promo code'
        },
        'add_sale_options_calculated': {
            'ro': 'Opțiunile tale au fost calculate',
            'en': 'Your options were calculated'
        },
        'add_sale_resource_added': {
            'ro': 'Vânzarea a fost adâugatâ',
            'en': 'Your sale has been added'
        },
        'add_sale_enter_promo_code': {
            'ro': 'Codul promoțional',
            'en': 'Promo code'
        },
        'add_sale_invalid_or_expired_promo_code': {
            'ro': 'Codul promoțional este invalid sau a expirat',
            'en': 'The promo code is invalid or expired'
        },
        'add_sale_group_not_found': {
            'ro': 'Nu uita să selectezi grupul',
            'en': 'Do not forget to select the group'
        },
        'add_sale_product_not_found': {
            'ro': 'Nu uita să selectezi produsul',
            'en': 'Do not forget to select the product'
        },
        'add_sale_service_not_found': {
            'ro': 'Nu uita să selectezi serviciul',
            'en': 'Do not forget to select the service'
        },
        'add_sale_subscription_not_found': {
            'ro': 'Nu uita să selectezi abonamentul',
            'en': 'Do not forget to select the subscription'
        },
        'add_sale_select_buyer_type': {
            'ro': 'Selectează tipul de cumpărător',
            'en': 'Select buyer type'
        },
        'add_sale_select_offer_type': {
            'ro': 'Selectează tipul de ofertă',
            'en': 'Select offer type'
        },
        'add_sale_invalid_buyer_type': {
            'ro': 'Te rugăm să selectezi tipul de cumpărător',
            'en': 'Please select the buyer type'
        },
        'add_sale_invalid_key_value_credits': {
            'ro': 'Te rugăm să completezi creditele',
            'en': 'Please enter the required credits'
        },
        'add_sale_invalid_key_value_quantity': {
            'ro': 'Te rugăm să completezi cantitatea',
            'en': 'Please enter the required quantity'
        },
        'add_sale_invalid_key_value_valid_from': {
            'ro': 'Te rugăm să completezi data de validitate',
            'en': 'Please enter the required valid from date'
        },
        'add_sale_json_key_is_required_customer_id': {
            'ro': 'Nu uita să selectezi clientul',
            'en': 'Do not forget to select the client'
        },
        'add_sale_json_key_is_required_group_id': {
            'ro': 'Nu uita să selectezi grupul',
            'en': 'Do not forget to select the group'
        },
        'add_sale_json_key_is_required_service_id': {
            'ro': 'Nu uita să selectezi serviciul',
            'en': 'Do not forget to select the service'
        },
        'add_sale_json_key_is_required_product_id': {
            'ro': 'Nu uita să selectezi produsul',
            'en': 'Do not forget to select the product'
        },
        'add_sale_json_key_is_required_subscription_id': {
            'ro': 'Nu uita să selectezi abonamentul',
            'en': 'Do not forget to select the subscription'
        },
        'add_sale_json_key_is_required_credits': {
            'ro': 'Te rugăm să completezi creditele',
            'en': 'Please enter the required credits'
        },
        'add_sale_json_key_is_required_quantity': {
            'ro': 'Te rugăm să completezi cantitatea',
            'en': 'Please enter the required quantity'
        },
        'add_sale_json_key_is_required_valid_from': {
            'ro': 'Te rugăm să completezi data de validitate',
            'en': 'Please enter the required valid from date'
        },
        'add_sale_selected_credits_does_not_match_included_credits': {
            'ro': 'Te rugăm să completezi creditele serviciilor incluse în abonament, în mod corect',
            'en': 'Please enter correctly the required credits included in the subscription'
        },
        // Add Sale Payment Form.
        'add_sale_payment_json_key_is_required_amount': {
            'ro': 'Te rugăm să completezi suma plătită',
            'en': 'Please enter the amount'
        },
        'add_sale_payment_sale_date': {
            'ro': 'Data vânzării',
            'en': 'Sale date'
        },
        // Sales status: unpaid, pending, partial, complete
        'sales_payment_status_online_payment_started': {
            'ro': 'Începută (online)',
            'en': 'Started (online)'
        },
        'sales_payment_status_online_pending_approval': {
            'ro': 'În aşteptare (online)',
            'en': 'Pending (online)'
        },
        'sales_payment_status_online_approved': {
            'ro': 'Aprobată (online)',
            'en': 'Approved (online)'
        },
        'sales_payment_status_online_reversed': {
            'ro': 'Returnată (online)',
            'en': 'Reversed (online)'
        },
        'sales_payment_status_unpaid': {
            'ro': 'Neplătită',
            'en': 'Unpaid'
        },
        'sales_payment_status_pending': {
            'ro': 'În aşteptare',
            'en': 'Pending'
        },
        'sales_payment_status_partial': {
            'ro': 'Parțială',
            'en': 'Partial'
        },
        'sales_payment_status_complete': {
            'ro': 'Completă',
            'en': 'Complete'
        },
        'sales_type_product': {
            'ro': 'Produs',
            'en': 'Product'
        },
        'sales_type_service': {
            'ro': 'Serviciu',
            'en': 'Service'
        },
        'sales_type_subscription': {
            'ro': 'Abonament',
            'en': 'Subscription'
        },
        'sales_type_custom_subscription': {
            'ro': 'Abonament personalizat',
            'en': 'Custom subscription'
        },

        'payment_type_frontdesk_card':{
            'ro':'',
            "en":''
        },
        '':{
            'ro':'',
            "en":''
        },

        'custom_subscription': {
            'ro': 'Personalizat',
            'en': 'Custom'
        },
        'payment_history': {
            'ro': 'istoricul plăților',
            'en': 'Payment history'
        },


        // Add Promotion Form.
        'add_promotion_enter_promotion_name': {
            'ro': 'Introduceți numele promoției',
            'en': 'Enter promotion name'
        },
        'add_promotion_select_target': {
            'ro': 'Selectează tipul de ofertă',
            'en': 'Select offer type'
        },
        'add_promotion_choose_target': {
            'ro': 'Alege tipul de ofertă',
            'en': 'Choose offer type'
        },
        'add_promotion_select_discount_type': {
            'ro': 'Selectează tipul de discount',
            'en': 'Select discount type'
        },
        'add_promotion_choose_discount_type': {
            'ro': 'Alege tipul de discount',
            'en': 'Choose discount type'
        },
        'add_promotion_select_promotion_type': {
            'ro': 'Selectează tipul de promoție',
            'en': 'Select promotion type'
        },
        'add_promotion_choose_promotion_type': {
            'ro': 'Alege tipul de promoție',
            'en': 'Choose promotion type'
        },
        'add_promotion_general_information': {
            'ro': 'Informații generale',
            'en': 'General information'
        },
        'add_promotion_invalid_key_value_valid_from': {
            'ro': 'Nu uita să selectezi Valid din',
            'en': 'Do not forget to select Valid from'
        },
        'add_promotion_invalid_key_value_valid_until': {
            'ro': 'Nu uita să selectezi Valid până la',
            'en': 'Do not forget to select Valid until'
        },
        'add_promotion_json_key_is_required_target_type': {
            'ro': 'Nu uita să selectezi tipul de ofertă',
            'en': 'Do not forget to select the offer type'
        },
        'add_promotion_json_key_is_required_name': {
            'ro': 'Te rugăm să completezi numele promoției',
            'en': 'Please enter the promotion name'
        },
        'add_promotion_json_key_is_required_promo_type': {
            'ro': 'Te rugăm să alegi tipul de promoție',
            'en': 'Please choose the promotion type'
        },
        'add_promotion_json_key_is_required_discount_type': {
            'ro': 'Te rugăm să alegi tipul de discount',
            'en': 'Please enter the discount type'
        },
        'add_promotion_json_key_is_required_discount_value': {
            'ro': 'Te rugăm să completezi valoarea de discount',
            'en': 'Please enter the discount value'
        },
        'add_promotion_json_key_is_required_valid_from': {
            'ro': 'Te rugăm să completezi data de început',
            'en': 'Please enter the start date'
        },
        'add_promotion_json_key_is_required_valid_until': {
            'ro': 'Te rugăm să completezi data de încheiere',
            'en': 'Please enter the end date'
        },
        'add_promotion_json_key_is_required_max_usage': {
            'ro': 'Te rugăm să completezi numărul maxim de utilizări',
            'en': 'Please enter the max usage'
        },
        'add_promotion_invalid_key_value_target_ids': {
            'ro': 'Nu uita să selectezi oferta',
            'en': 'Do not forget to select the offer'
        },

        // Calendar.
        'calendar': {
            'ro': 'Calendar',
            'en': 'Calendar'
        },

        // Streaming.
        'streaming': {
            'ro': 'Antrenamente online',
            'en': 'Online workouts'
        },

        'streaming_sessions': {
            'ro': 'Streaming',
            'en': 'Streaming'
        },

        // Plannings.
        'no_services_found_on_search': {
            'ro': 'Nu au fost găsite servicii care să corespundă criteriilor de căutare',
            'en': 'No services matched your search criteria'
        },
        'no_service_locations_found_on_search': {
            'ro': 'Nu au fost găsite locații care să corespundă criteriilor de căutare',
            'en': 'No locations matched your search criteria'
        },
        'no_services_found': {
            'ro': 'Nu au fost găsite servicii',
            'en': 'No services found'
        },
        'no_service_locations_found': {
            'ro': 'Nu au fost găsite locații',
            'en': 'No locations found'
        },
        'plannings_no_services_advice_message': {
            'ro': 'Te rugăm să adaugi cel puțin un serviciu care nu este de tip programare',
            'en': 'Please add at least 1 service which is not appointment type service'
        },
        'plannings_no_locations_advice_message': {
            'ro': 'Te rugăm să adaugi cel puțin o locație',
            'en': 'Please add at least 1 location'
        },
        'add_planning': {
            'ro': 'Adaugă planificare',
            'en': 'Add planning'
        },
        'infinite': {
            'ro': 'Infinit',
            'en': 'Infinite'
        },
        'predefined_date': {
            'ro': 'Dată predefinită',
            'en': 'Predefined date'
        },
        'edit_planning': {
            'ro': 'Editează planificarea',
            'en': 'Edit planning'
        },
        'edit_planning_json_key_is_required_name': {
            'ro': 'Te rugăm să completezi numele',
            'en': 'Please enter the name'
        },
        'edit_planning_json_key_is_required_max_clients': {
            'ro': 'Te rugăm să completezi numărul maxim de clienți',
            'en': 'Please enter the required max clients'
        },
        'edit_planning_resource_updated': {
            'ro': 'Planificarea a fost actualizată',
            'en': 'Planning updated'
        },
        'associate_service_providers_to_this_planning': {
            'ro': 'Asociază prestatori',
            'en': 'Assign providers'
        },
        'associate_service_providers': {
            'ro': 'Asociază prestatori',
            'en': 'Assign providers'
        },
        'service_provider_resource_added': {
            'ro': 'Angajatul a fost inclus în lista de prestatori',
            'en': 'The worker has been added to providers'
        },
        'delete_planning': {
            'ro': 'Șterge',
            'en': 'Delete'
        },
        'deleting_planning': {
            'ro': 'Ești sigur că vrei să ștergi această planificare?',
            'en': 'Are you sure you want to delete this planning?'
        },
        'delete_planning_planning_has_schedules': {
            'ro': 'Planificările care au rezervări nu pot fi șterse',
            'en': 'Plannings with schedules cannot be deleted'
        },
        'activate_planning': {
            'ro': 'Activează planificarea',
            'en': 'Activatea the planning'
        },
        'activate_planning_title': {
            'ro': 'Activează planificarea',
            'en': 'Activatea the planning'
        },
        'inactivate_planning': {
            'ro': 'Dezactivează',
            'en': 'Inactivate'
        },
        'inactive_planning_title': {
            'ro': 'Planificare inactivă',
            'en': 'Inactive planning'
        },
        'activate_planning_resource_deleted': {
            'ro': 'Planificare activată',
            'en': 'Planning activated'
        },
        'activate_planning_resource_is_in_the_past': {
            'ro': 'Nu se poate activa o planificare din trecut',
            'en': 'Plannings in the past can no longer be activated'
        },
        'inactivate_planning_resource_updated': {
            'ro': 'Planificarea a fost inactivată',
            'en': 'Planning inactivated'
        },
        'show_inactive_plannings': {
            'ro': 'Afișează și inactive',
            'en': 'Show also inactive'
        },
        'delete_planning_resource_deleted': {
            'ro': 'Planificarea a fost ștearsă',
            'en': 'Planning deleted'
        },
        'recurring_planning': {
            'ro': 'Planificare recurentă',
            'en': 'Recurring planning'
        },
        'start_date': {
            'ro': 'Data de început',
            'en': 'Start date'
        },
        'start_time': {
            'ro': 'Ora începerii',
            'en': 'Start time'
        },
        'start_time_open': {
            'ro': 'Ora deschiderii',
            'en': 'Start time'
        },
        'end_date': {
            'ro': 'Data de încheiere',
            'en': 'End date'
        },
        'end_time': {
            'ro': 'Ora de sfârșit',
            'en': 'End time'
        },
        'end_time_close': {
            'ro': 'Ora închiderii',
            'en': 'End time'
        },
        'max_clients': {
            'ro': 'Numărul maxim de clienți',
            'en': 'Max clients'
        },
        // Add Planning Form.
        'add_planning_json_key_is_required_max_clients': {
            'ro': 'Te rugăm să completezi numărul maxim de clienți',
            'en': 'Please enter the required max clients'
        },
        'add_planning_resource_added': {
            'ro': 'Planificarea ta a fost adăugată',
            'en': 'Your planning has been added'
        },
        'add_planning_resource_updated': {
            'ro': 'Planificarea a fost actualizată',
            'en': 'Planning updated'
        },
        'add_planning_planning_overlap': {
            'ro': 'Planificare suprapusă',
            'en': 'Planning overlap'
        },
        // Schedules.
        'scheduled_by': {
            'ro': 'Programat de',
            'en': 'Scheduled by'
        },
        'scheduled_at': {
            'ro': 'Programat la',
            'en': 'Scheduled at'
        },
        'add_schedule': {
            'ro': 'Rezervă',
            'en': 'Add schedule'
        },
        'add_schedule_title': {
            'ro': 'Adaugă rezervare',
            'en': 'Add schedule'
        },
        'add_schedule_customer_overlap': {
            'ro': 'Rezervare suprapusă!',
            'en': 'Schedule overlap!'
        },
        'add_schedule_too_late': {
            'ro': 'Programare prea târzie pentru a fi adăugată',
            'en': 'Too late schedule to add',
        },
        'schedule_title': {
            'ro': 'Detalii',
            'en': 'Details'
        },
        'scheduled_list_title': {
            'ro': 'Lista rezervărilor',
            'en': 'Schedules list'
        },
        'schedules': {
            'ro': 'Rezervări',
            'en': 'Schedules'
        },
        'canceled_schedules': {
            'ro': 'Rezervări anulate',
            'en': 'Canceled schedules'
        },
        'cancel_schedules': {
            'ro': 'Anulare rezervări',
            'en': 'Cancel schedules'
        },
        'canceled_schedules_no_canceled_schedules': {
            'ro': 'Nu există rezervări anulate',
            'en': 'There are no canceled reservations'
        },
        'selected_customer': {
            'ro': 'Clientul selectat',
            'en': 'Selected customer'
        },
        'selected_service': {
            'ro': 'Serviciul selectat',
            'en': 'Selected service'
        },
        'selected_product': {
            'ro': 'Produsul selectat',
            'en': 'Selected product'
        },
        'selected_subscription': {
            'ro': 'Abonamentul selectat',
            'en': 'Selected subscription'
        },
        'choose_customer': {
            'ro': 'Alege clientul',
            'en': 'Choose customer'
        },
        'all_customers': {
            'ro': 'Toți clienții',
            'en': 'All customers'
        },
        'choose_worker': {
            'ro': 'Alege angajatul',
            'en': 'Choose employee'
        },
        'cancel_selection': {
            'ro': 'Deselectează',
            'en': 'Cancel selection'
        },
        'cancel_schedule': {
            'ro': 'Anulează rezervarea',
            'en': 'Cancel schedule'
        },
        'cancel_recurrent_schedule': {
            'ro': 'Anulează rezervarea recurentă',
            'en': 'Cancel recurrent schedule'
        },
        'cancel_schedule_resource_deleted': {
            'ro': 'Rezervarea a fost anulată',
            'en': 'Schedule canceled'
        },
        'canceling_schedule': {
            'ro': 'Ești sigur că vrei să anulezi această rezervare?',
            'en': 'Are you sure you want to cancel this schedule?'
        },
        'canceling_recurrent_schedule': {
            'ro': 'Ești sigur că vrei să anulezi această rezervare recurentă?',
            'en': 'Are you sure you want to cancel this recurrent schedule?'
        },
        'canceling_recurrent_schedule_interval': {
            'ro':'Alege un interval de timp pentru a anula rezervarea recurentă.',
            'en':'Select an interval to cancel the recurrent schedule.'
        },
        // Add Schedule Request.
        // 'add_schedule_invalid_computed_sale_id': {
        //     'ro': 'Pentru a face o rezervare, mai întâi trebuie să efectuați o plată pentru acest serviciu',
        //     'en': 'In order to make a schedule, you must first make a payment for this service'
        // },
        'add_schedule_invalid_computed_sale_id': {
            'ro': 'Nu aveți credite suficiente pentru a face o rezervare la serviciul "{{service_name}}" în data de "{{date}}"',
            'en': 'You do not have enough credit to make a reservation for "{{service_name}}" on "{{date}}"'
        },
        'add_schedule_cannot_create_resource_in_the_past': {
            'ro': 'Nu se pot face rezervări pentru planificări din trecut',
            'en': 'Schedules in the past are not possible'
        },
        'add_schedule_resource_added': {
            'ro': 'Rezervare realizată',
            'en': 'Schedule added'
        },
        'add_schedule_resource_overlap': {
            'ro': 'Rezervare suprapusă! Ai deja ocupat intervalul acesta de timp',
            'en': 'Schedule overlap! You have already made a schedule for this time interval'
        },
        'add_schedule_invalid_key_value_customer_id': {
            'ro': 'Nu uita să selectezi clientul',
            'en': 'Do not forget to select the customer'
        },
        'select_a_customer': {
            'ro': 'Selectează un client',
            'en': 'Select a customer'
        },
        'enter_customer_name': {
            'ro': 'Numele clientului',
            'en': 'Enter customer name'
        },

        // Appointments
        'appointments_no_services_advice_message': {
            'ro': 'Te rugăm să adaugi cel puțin un serviciu care este de tip programare',
            'en': 'Please add at least 1 service which is of type appointment'
        },
        'appointments_no_locations_advice_message': {
            'ro': 'Te rugăm să adaugi cel puțin o locație',
            'en': 'Please add at least 1 location'
        },
        'appointment': {
            'ro': 'Programare',
            'en': 'Appointment'
        },
        'appointments': {
            'ro': 'Programări',
            'en': 'Appointments'
        },
        'add_appointment': {
            'ro': 'Adaugă programare',
            'en': 'Add appointment'
        },
        'customer_name': {
            'ro': 'Nume client',
            'en': 'Customer name'
        },
        'edit_appointment': {
            'ro': 'Editează programarea',
            'en': 'Edit appointment'
        },
        'delete_appointment': {
            'ro': 'Șterge programarea',
            'en': 'Delete appointment'
        },
        'deleting_appointment': {
            'ro': 'Ești sigur că vrei să ștergi această programare?',
            'en': 'Are you sure you want to delete this appointment?'
        },
        // Add Appointment Form.
        'add_appointment_resource_added': {
            'ro': 'Programarea ta a fost adăugată',
            'en': 'Your appointment has been added'
        },
        'add_appointment_invalid_key_value_start_time': {
            'ro': 'Te rugăm să completezi ora începerii, în mod corect',
            'en': 'Please enter correctly the required start time'
        },
        'add_appointment_invalid_key_value_end_time': {
            'ro': 'Te rugăm să completezi ora de sfârșit, în mod corect',
            'en': 'Please enter correctly the required end time'
        },
        'add_appointment_resource_updated': {
            'ro': 'Programarea a fost actualizată',
            'en': 'Appointment updated'
        },
        'add_appointment_appointment_overlap': {
            'ro': 'Programare suprapusă',
            'en': 'Appointment overlap'
        },
        'delete_appointment_resource_deleted': {
            'ro': 'Programarea a fost ștearsă',
            'en': 'Appointment deleted'
        },
        'add_appointment_cannot_create_resource_in_the_past': {
            'ro': 'Nu se pot face programări în trecut',
            'en': 'Appointments in the past are not possible'
        },
        'add_appointment_json_key_is_required_customer_name': {
            'ro': 'Te rugăm să completezi numele clientului',
            'en': 'Please enter the required customer name'
        },
        'customer_not_selected': {
            'ro': 'Client neselectat',
            'en': 'Customer not selected'
        },

        // Role requests.
        'add_role_resource_added': {
            'ro': 'Rolul a fost adăugat',
            'en': 'Role added'
        },
        'edit_role_resource_updated': {
            'ro': 'Rolul a fost actualizat',
            'en': 'Role updated'
        },
        'edit_role_for_user_resource_updated': {
            'ro': 'Rolurile a fost actualizate',
            'en': 'Roles updated'
        },
        'delete_role_resource_deleted': {
            'ro': 'Rolul a fost șters',
            'en': 'Role deleted'
        },
        'delete_role_role_is_used': {
            'ro': 'Rolul este folosit și nu poate fi șters',
            'en': 'Role is used and cannot be deleted'
        },
        'select_role': {
            'ro': 'Selectează rolul',
            'en': 'Select role'
        },
        'select_a_role_to_see_and_setup_options_for': {
            'ro': 'Selectează un rol din listă pentru a putea vizualiza și seta opțiunile',
            'en': 'Select a role from the list in order to view and setup options'
        },
        'select_a_worker_to_see_and_setup_roles_for': {
            'ro': 'Selectează un angajat din listă pentru a putea vizualiza și seta rolurile',
            'en': 'Select a worker from the list in order to view and setup roles'
        },
        'app_messages': {
            'ro': 'Mesaje',
            'en': 'App messages'
        },
        'edit_role_feature_resource_updated': {
            'ro': 'Opțiunile rolului au fost actualizate',
            'en': 'Role options updated'
        },
        'appointment_service': {
            'ro': 'Serviciu de tip programare',
            'en': 'Appointment service'
        },
        'appointment_service_type_cannot_be_purchased': {
            'ro': 'Serviciul de tip programare nu poate fi cumpărat',
            'en': 'Appointment service type cannot be purchased'
        },

        // Infinite Loading.
        'infinite_no_more_promotions': {
            'ro': 'Nu mai sunt alte promoții de afișat :)',
            'en': 'No more promotions to list :)'
        },

        // Customer Orders.
        'customer_orders': {
            'ro': 'Comenzile mele',
            'en': 'My orders'
        },
        'individual_orders': {
            'ro': 'Individuale',
            'en': 'Individual'
        },
        'group_orders': {
            'ro': 'Grup',
            'en': 'Group'
        },

        'providers': {
            'ro': 'Prestatori',
            'en': 'Providers'
        },
        'add_provider': {
            'ro': 'Adaugă prestatori',
            'en': 'Add providers'
        },

        // User Settings.
        'user_settings': {
            'ro': 'Setările utilizatorului',
            'en': 'User Settings'
        },
        'receive_emails': {
            'ro': 'Primiți e-mailuri',
            'en': 'Receive emails'
        },
        'notifications_language': {
            'ro': 'Limba pentru notificări',
            'en': 'Notifications language'
        },
        'edit_user_settings': {
            'ro': 'Editează setările utilizatorului',
            'en': 'Edit user settings'
        },
        'delete_account': {
            'ro': 'Șterge cont',
            'en': 'Delete account'
        },
        'lang_en': {
            'ro': 'EN',
            'en': 'EN'
        },
        'lang_ro': {
            'ro': 'RO',
            'en': 'RO'
        },
        'not_seted': {
            'ro': 'Valoarea nu a fost setată',
            'en': 'Value not set'
        },
        'edit_user_settings_resource_saved': {
            'ro': 'Setările au fost actualizate',
            'en': 'Settings updated'
        },
        'edit_user_settings_resource_saved_receive_emails': {
            'ro': 'Setările au fost actualizate pentru Primiți e-mailuri',
            'en': 'Settings updated for Receive emails'
        },
        'edit_user_settings_resource_saved_notifications_language': {
            'ro': 'Setările au fost actualizate pentru Limba pentru notificări',
            'en': 'Settings updated for Notifications language'
        },

        'current_password': {
            'ro': 'Parola curentă',
            'en': 'Current password'
        },
        'new_password': {
            'ro': 'Parola nouă',
            'en': 'New password'
        },
        'login_invalid_credentials': {
            'ro': 'Vă rugăm să introduceți un e-mail și o parolă valabile',
            'en': 'Please enter a valid email and password'
        },
        'login_v1_upgrade_check_email_for_info': {
            'ro': 'Am trecut la o nouă versiune. Vă rugăm să verificați emailul pentru a utiliza noua versiune.',
            'en': 'We switched to a new version. Please check your email in order to use the new version.'
        },
        'login_user_is_not_active': {
            'ro': 'Contul necesită activare, vă rugăm să verificați emailul.',
            'en': 'The account requires activation, please check your email.'
        },
        'terms_and_conditions_agreement': {
            'ro': 'Am citit și sunt de acord cu %the_terms_and_conditions%',
            'en': 'I have read and agree to the %the_terms_and_conditions%'
        },
        'the_terms_and_conditions': {
            'ro': 'Termenii și condițiile',
            'en': 'Terms and Conditions'
        },
        'activate_worker_account_resource_activated': {
            'ro': 'Contul a fost activat',
            'en': 'Account activated'
        },
        'activate_customer_account_resource_activated': {
            'ro': 'Contul a fost activat',
            'en': 'Account activated'
        },

        // Upcoming Events.
        'no_upcoming_events': {
            'ro': 'Nu sunt evenimente',
            'en': 'No upcoming events'
        },
        'today': {
            'ro': 'Astăzi',
            'en': 'Today'
        },
        'tomorrow': {
            'ro': 'Mâine',
            'en': 'Tomorrow'
        },
        'day_after_tomorrow': {
            'ro': 'Poimâine',
            'en': 'Day after tomorrow'
        },

        // App Settings - Time Limits.
        'app_settings': {
            'ro': 'Setările applicației',
            'en': 'App settings'
        },
        'time_limits': {
            'ro': 'Limitări de timp',
            'en': 'Time limits'
        },
        'time_limit': {
            'ro': 'Limitare de timp',
            'en': 'Time limit'
        },
        'add_timelimit': {
            'ro': 'Adaugă limitare',
            'en': 'Add time limit'
        },
        'new_timelimit': {
            'ro': 'Creează limitare nouă',
            'en': 'Create new time limit'
        },
        'find_timelimit': {
            'ro': 'Caută limitare',
            'en': 'Search time limit'
        },
        'enter_timelimit_name': {
            'ro': 'Introduceți numele',
            'en': 'Enter name'
        },
        'enter_number_of_hours': {
            'ro': 'Introduceți numărul de ore',
            'en': 'Enter the number of hours'
        },
        'limit_type': {
            'ro': 'Tipul de limitare',
            'en': 'Limit type'
        },
        'day_before': {
            'ro': 'Ziua anterioară',
            'en': 'The day before'
        },
        'timelimit_value': {
            'ro': 'Valoarea limitării',
            'en': 'Limitation value'
        },
        'timelimit_activated': {
            'ro': 'Limitare activată',
            'en': 'Limitation activated'
        },
        'timelimit_deactivated': {
            'ro': 'Limitare dezactivată',
            'en': 'Limitation deactivated'
        },
        'add_timelimit_json_key_is_required_name': {
            'ro': 'Te rugăm să completezi numele limitării de timp',
            'en': 'Please fill in the name of the time limit'
        },
        'add_timelimit_json_key_is_required_limit_type': {
            'ro': 'Te rugăm să alegi tipul de limitare',
            'en': 'Please choose the type of the time limit'
        },
        'add_timelimit_invalid_key_value_day_before_time': {
            'ro': 'Te rugăm să completezi corect valoarea pentru Ziua anterioară',
            'en': 'Please fill in correctly the value for The day before'
        },
        'add_timelimit_invalid_key_value_min_hours_before': {
            'ro': 'Te rugăm să completezi corect valoarea pentru Ore inainte',
            'en': 'Please fill in correctly the value for the Hours before'
        },
        'add_timelimit_resource_added': {
            'ro': 'Limitare adăugată',
            'en': 'Limitation added'
        },
        'delete_timelimit_resource_deleted': {
            'ro': 'Limitare ștearsă',
            'en': 'Limitation deleted'
        },
        'delete_timelimit_resource_is_used': {
            'ro': 'Limitarea este folosită și nu poate fi ștearsă',
            'en': 'Time limit is used and cannot be deleted'
        },
        'delete_timelimit': {
            'ro': 'Șterge limitarea de timp',
            'en': 'Delete time limit'
        },
        'deleting_timelimit': {
            'ro': 'Ești sigur că vrei să ștergi limitarea de timp?',
            'en': 'Are you sure you want to delete this time limit?'
        },
        'hours_before': {
            'ro': 'Ore înainte',
            'en': 'Hours before'
        },
        'select_day_before_time': {
            'ro': 'Alegeți ora pentru ziua anterioară',
            'en': 'Pick the time for the day before'
        },
        'select_timelimit': {
            'ro': 'Alegeți limitarea de timp',
            'en': 'Choose the time limit'
        },
        'add_planning_required_timelimit': {
            'ro': 'Alegeți limitarea de timp',
            'en': 'Choose the time limit'
        },

        // Data Libraries.
        'data_libraries': {
            'ro': 'Librării de date',
            'en': 'Data libraries'
        },
        'general_data': {
            'ro': 'Date generale',
            'en': 'General data'
        },
        'training_classifications': {
            'ro': 'Clasificări antrenament',
            'en': 'Training classifications'
        },
        'training_phases': {
            'ro': 'Faze de antrenament',
            'en': 'Training phases'
        },
        'training_categories': {
            'ro': 'Categorii de antrenament',
            'en': 'Training categories'
        },
        'new_training_levels': {
            'ro': 'Nivel nou de antrenament',
            'en': 'New training level'
        },
        'create_training_levels': {
            'ro': 'Creează nivel nou de antrenament',
            'en': 'Create new training level'
        },
        'training_levels': {
            'ro': 'Niveluri de antrenament',
            'en': 'Training levels'
        },
        'exercises_and_trainings': {
            'ro': 'Exerciții și antrenamente',
            'en': 'Exercises and trainings'
        },
        // Training categories.
        'training_category': {
            'ro': 'Categoria de antrenament',
            'en': 'Training category'
        },
        'no_training_category': {
            'ro': 'Fără categorie de antrenament',
            'en': 'No training category'
        },
        'add_training_categories': {
            'ro': 'Adaugă categorie de antrenament',
            'en': 'Add training category'
        },
        'new_training_categories': {
            'ro': 'Categorie nouă de antrenament',
            'en': 'New training category'
        },
        'create_training_categories': {
            'ro': 'Creează categorie nouă de antrenament',
            'en': 'Create new training category'
        },
        'add_training_category': {
            'ro': 'Categorie nouă de antrenament',
            'en': 'New training category'
        },
        'new_training_category': {
            'ro': 'Adaugă categorie de antrenament',
            'en': 'Add training category'
        },
        'edit_training_category': {
            'ro': 'Editează categoria de antrenament',
            'en': 'Edit training category'
        },
        'delete_training_category': {
            'ro': 'Șterge categoria de antrenament',
            'en': 'Delete training category'
        },
        'delete_training_category_resource_is_used': {
            'ro': 'Categoria de antrenament este folosită și nu poate fi ștearsă',
            'en': 'Training category is used and cannot be deleted'
        },
        'add_training_category_resource_added': {
            'ro': 'Categoria de antrenament a fost adăugată',
            'en': 'Training category added'
        },
        'add_training_category_json_key_is_required_name': {
            'ro': 'Te rugăm să completezi numele categoriei de antrenament',
            'en': 'Please fill in the name of the training category'
        },
        'edit_training_category_json_key_is_required_name': {
            'ro': 'Te rugăm să completezi numele categoriei de antrenament',
            'en': 'Please fill in the name of the training category'
        },
        'edit_training_category_resource_updated': {
            'ro': 'Categoria de antrenament a fost actualizată',
            'en': 'Training category updated'
        },
        'delete_training_category_resource_deleted': {
            'ro': 'Categoria de antrenament a fost ștearsă',
            'en': 'Training category deleted'
        },
        'deleting_training_category': {
            'ro': 'Ești sigur că vrei să ștergi categoria de antrenament?',
            'en': 'Are you sure you want to delete this training category?'
        },
        'find_training_category': {
            'ro': 'Caută categorie de antrenament',
            'en': 'Search training categories'
        },
        // Training phases.
        'training_phase': {
            'ro': 'Faza de antrenament',
            'en': 'Training phase'
        },
        'training_phases': {
            'ro': 'Faze de antrenament',
            'en': 'Training phases'
        },
        'no_training_phase': {
            'ro': 'Fără fază de antrenament',
            'en': 'No training phase'
        },
        'new_training_phases': {
            'ro': 'Fază de antrenament nouă',
            'en': 'New training phase'
        },
        'create_training_phases': {
            'ro': 'Creează fază de antrenament nouă',
            'en': 'Create new training phase'
        },
        'add_training_phases': {
            'ro': 'Adaugă fază de antrenament',
            'en': 'Add training phase'
        },
        'add_training_phase': {
            'ro': 'Adaugă fază de antrenament',
            'en': 'Add training phase'
        },
        'edit_training_phase': {
            'ro': 'Editează faza de antrenament',
            'en': 'Edit training phase'
        },
        'delete_training_phase': {
            'ro': 'Șterge faza de antrenament',
            'en': 'Delete training phase'
        },
        'delete_training_phase_resource_is_used': {
            'ro': 'Faza de antrenament este folosită și nu poate fi ștearsă',
            'en': 'Training phase is used and cannot be deleted'
        },
        'add_training_phase_resource_added': {
            'ro': 'Faza de antrenament a fost adăugată',
            'en': 'Training phase added'
        },
        'add_training_phase_json_key_is_required_name': {
            'ro': 'Te rugăm să completezi numele fazei de antrenament',
            'en': 'Please fill in the name of the training phase'
        },
        'edit_training_phase_json_key_is_required_name': {
            'ro': 'Te rugăm să completezi numele fazei de antrenament',
            'en': 'Please fill in the name of the training phase'
        },
        'edit_training_phase_resource_updated': {
            'ro': 'Faza de antrenament a fost actualizată',
            'en': 'Training phase updated'
        },
        'delete_training_phase_resource_deleted': {
            'ro': 'Faza de antrenament a fost ștearsă',
            'en': 'Training phase deleted'
        },
        'deleting_training_phase': {
            'ro': 'Ești sigur că vrei să ștergi faza de antrenament?',
            'en': 'Are you sure you want to delete this training phase?'
        },
        'find_training_phase': {
            'ro': 'Caută fază de antrenament',
            'en': 'Search training phases'
        },
        // Training types.
        'training_type': {
            'ro': 'Tipul de antrenament',
            'en': 'Training type'
        },
        'new_training_types': {
            'ro': 'Tip nou de antrenament',
            'en': 'New training type'
        },
        'create_training_types': {
            'ro': 'Creează tip nou de antrenament',
            'en': 'Create new training type'
        },
        'training_types': {
            'ro': 'Tipuri de antrenament',
            'en': 'Training types'
        },
        'no_training_type': {
            'ro': 'Fără tip de antrenament',
            'en': 'No training type'
        },
        'add_training_types': {
            'ro': 'Adaugă tip de antrenament',
            'en': 'Add training type'
        },
        'add_training_type': {
            'ro': 'Adaugă tip de antrenament',
            'en': 'Add training type'
        },
        'edit_training_type': {
            'ro': 'Editează tipul de antrenament',
            'en': 'Edit training type'
        },
        'delete_training_type': {
            'ro': 'Șterge tipul de antrenament',
            'en': 'Delete training type'
        },
        'delete_training_type_resource_is_used': {
            'ro': 'Tipul de antrenament este folosit și nu poate fi șters',
            'en': 'Training type is used and cannot be deleted'
        },
        'add_training_type_resource_added': {
            'ro': 'Tipul de antrenament a fost adăugat',
            'en': 'Training type added'
        },
        'add_training_type_json_key_is_required_name': {
            'ro': 'Te rugăm să completezi numele tipului de antrenament',
            'en': 'Please fill in the name of the training type'
        },
        'edit_training_type_json_key_is_required_name': {
            'ro': 'Te rugăm să completezi numele tipului de antrenament',
            'en': 'Please fill in the name of the training type'
        },
        'edit_training_type_resource_updated': {
            'ro': 'Tipul de antrenament a fost actualizat',
            'en': 'Training type updated'
        },
        'delete_training_type_resource_deleted': {
            'ro': 'Tipul de antrenament a fost șters',
            'en': 'Training type deleted'
        },
        'deleting_training_type': {
            'ro': 'Ești sigur că vrei să ștergi tipul de antrenament?',
            'en': 'Are you sure you want to delete this training type?'
        },
        'find_training_type': {
            'ro': 'Caută tip de antrenament',
            'en': 'Search training types'
        },
        // Training levels.
        'training_level': {
            'ro': 'Nivelul de antrenament',
            'en': 'Training level'
        },
        'training_levels': {
            'ro': 'Niveluri de antrenament',
            'en': 'Training levels'
        },
        'no_training_level': {
            'ro': 'Fără nivel de antrenament',
            'en': 'No training level'
        },
        'add_training_levels': {
            'ro': 'Adaugă nivel de antrenament',
            'en': 'Add training level'
        },
        'add_training_level': {
            'ro': 'Adaugă nivel de antrenament',
            'en': 'Add training level'
        },
        'edit_training_level': {
            'ro': 'Editează nivelul de antrenament',
            'en': 'Edit training level'
        },
        'delete_training_level': {
            'ro': 'Șterge nivelul de antrenament',
            'en': 'Delete training level'
        },
        'delete_training_level_resource_is_used': {
            'ro': 'Nivelul de antrenament este folosit și nu poate fi șters',
            'en': 'Training level is used and cannot be deleted'
        },
        'add_training_level_resource_added': {
            'ro': 'Nivelul de antrenament a fost adăugat',
            'en': 'Training level added'
        },
        'add_training_level_json_key_is_required_name': {
            'ro': 'Te rugăm să completezi numele nivelului de antrenament',
            'en': 'Please fill in the name of the training level'
        },
        'edit_training_level_json_key_is_required_name': {
            'ro': 'Te rugăm să completezi numele nivelului de antrenament',
            'en': 'Please fill in the name of the training level'
        },
        'edit_training_level_resource_updated': {
            'ro': 'Nivelul de antrenament a fost actualizat',
            'en': 'Training level updated'
        },
        'delete_training_level_resource_deleted': {
            'ro': 'Nivelul de antrenament a fost șters',
            'en': 'Training level deleted'
        },
        'deleting_training_level': {
            'ro': 'Ești sigur că vrei să ștergi nivelul de antrenament?',
            'en': 'Are you sure you want to delete this training level?'
        },
        'find_training_level': {
            'ro': 'Caută nivel de antrenament',
            'en': 'Search training levels'
        },
        // Muscles.
        'muscles': {
            'ro': 'Grupe musculare',
            'en': 'Muscles'
        },
        'add_muscle': {
            'ro': 'Adaugă grupă musculară',
            'en': 'Add muscle'
        },
        'new_muscle': {
            'ro': 'Grupă musculară nouă',
            'en': 'New muscle'
        },
        'create_muscle': {
            'ro': 'Creează grupă musculară nouă',
            'en': 'Create new muscle'
        },
        'create_muscles': {
            'ro': 'Creează grupă musculară nouă',
            'en': 'Create new muscle'
        },
        'include_muscle': {
            'ro': 'Include grupă musculară',
            'en': 'Include muscle'
        },
        'edit_muscle': {
            'ro': 'Editează grupa musculară',
            'en': 'Edit muscle'
        },
        'delete_muscle': {
            'ro': 'Șterge grupa musculară',
            'en': 'Delete muscle'
        },
        'add_muscle_resource_added': {
            'ro': 'Grupa musculară a fost adăugată',
            'en': 'Muscle added'
        },
        'add_muscle_json_key_is_required_name': {
            'ro': 'Te rugăm să completezi numele grupei musculare',
            'en': 'Please fill in the name of the muscle'
        },
        'edit_muscle_json_key_is_required_name': {
            'ro': 'Te rugăm să completezi numele grupei musculare',
            'en': 'Please fill in the name of the muscle'
        },
        'edit_muscle_resource_updated': {
            'ro': 'Grupa musculară a fost actualizată',
            'en': 'Muscle updated'
        },
        'delete_muscle_resource_is_used': {
            'ro': 'Grupa musculară este folosită și nu poate fi ștearsă',
            'en': 'Muscle is used and cannot be deleted'
        },
        'delete_muscle_resource_deleted': {
            'ro': 'Grupa musculară a fost ștearsă',
            'en': 'Muscle deleted'
        },
        'deleting_muscle': {
            'ro': 'Ești sigur că vrei să ștergi grupa musculară?',
            'en': 'Are you sure you want to delete this muscle?'
        },
        'find_muscle': {
            'ro': 'Caută grupă musculară',
            'en': 'Search muscles'
        },
        // Equipments.
        'no_equipment_found_on_search': {
            'ro': 'Nu au fost găsite echipamente care să corespundă criteriilor de căutare',
            'en': 'No equipments matched your search criteria'
        },
        'equipments': {
            'ro': 'Echipamente',
            'en': 'Equipments'
        },
        'add_equipment': {
            'ro': 'Adaugă echipament',
            'en': 'Add equipment'
        },
        'new_equipment': {
            'ro': 'Echipament nou',
            'en': 'New equipment'
        },
        'create_equipment': {
            'ro': 'Creează echipament nou',
            'en': 'Create new equipment'
        },
        'create_equipments': {
            'ro': 'Creează echipament nou',
            'en': 'Create new equipment'
        },
        'include_equipment': {
            'ro': 'Include echipament',
            'en': 'Include equipment'
        },
        'edit_equipment': {
            'ro': 'Editează echipamentul',
            'en': 'Edit equipment'
        },
        'delete_equipment': {
            'ro': 'Șterge echipamentul',
            'en': 'Delete equipment'
        },
        'find_personal_equipment': {
            'ro': 'Caută echipament personal',
            'en': 'Search personal equipment'
        },
        'delete_equipment_resource_is_used': {
            'ro': 'Echipamentul este folosit și nu poate fi șters',
            'en': 'Equipment is used and cannot be deleted'
        },
        'add_equipment_resource_added': {
            'ro': 'Echipamentul a fost adăugat',
            'en': 'Equipment added'
        },
        'add_equipment_json_key_is_required_name': {
            'ro': 'Te rugăm să completezi numele echipamentului',
            'en': 'Please fill in the name of the equipment'
        },
        'edit_equipment_json_key_is_required_name': {
            'ro': 'Te rugăm să completezi numele echipamentului',
            'en': 'Please fill in the name of the equipment'
        },
        'edit_equipment_resource_updated': {
            'ro': 'Echipamentul a fost actualizată',
            'en': 'Equipment updated'
        },
        'delete_equipment_resource_deleted': {
            'ro': 'Echipamentul a fost ștearsă',
            'en': 'Equipment deleted'
        },
        'deleting_equipment': {
            'ro': 'Ești sigur că vrei să ștergi echipamentul?',
            'en': 'Are you sure you want to delete this equipment?'
        },
        'find_equipment': {
            'ro': 'Caută echipament',
            'en': 'Search equipments'
        },
        // Medical Problems.
        'names': {
            'ro': 'Denumiri',
            'en': 'Names'
        },
        'medical_problems': {
            'ro': 'Probleme medicale',
            'en': 'Medical problems'
        },
        'recommended': {
            'ro': 'Recomandate',
            'en': 'Recommended'
        },
        'not_recommended': {
            'ro': 'Nerecomandate',
            'en': 'Not recommended'
        },
        'forbidden': {
            'ro': 'Interzise',
            'en': 'Forbidden'
        },
        'recommended_medical_problems': {
            'ro': 'Probleme medicale recomandate',
            'en': 'Recommended medical problems'
        },
        'not_recommended_medical_problems': {
            'ro': 'Probleme medicale nerecomandate',
            'en': 'Not recommended medical problems'
        },
        'forbidden_medical_problems': {
            'ro': 'Probleme medicale interzise',
            'en': 'Forbidden medical problems'
        },
        'select_reference': {
            'ro': 'Referințe medicale',
            'en': 'Medical references'
        },
        'medical_problem': {
            'ro': 'Problemă medicală',
            'en': 'Medical problem'
        },
        'add_medical_problem': {
            'ro': 'Adaugă problemă medicală',
            'en': 'Add medical problem'
        },
        'new_medical_problem': {
            'ro': 'Problemă medicală nouă',
            'en': 'New medical problem'
        },
        'create_medical_problem': {
            'ro': 'Creează problemă medicală nouă',
            'en': 'Create new medical problem'
        },
        'create_medical_problems': {
            'ro': 'Creează problemă medicală nouă',
            'en': 'Create new medical problem'
        },
        'include_medical_problem': {
            'ro': 'Include problemă medicală',
            'en': 'Include medical problem'
        },
        'edit_medical_problem': {
            'ro': 'Editează problema medicală',
            'en': 'Edit medical problem'
        },
        'delete_medical_problem': {
            'ro': 'Șterge problema medicală',
            'en': 'Delete medical problem'
        },
        'delete_medical_problem_resource_is_used': {
            'ro': 'Problema medicală este folosită și nu poate fi ștearsă',
            'en': 'Medical problem is used and cannot be deleted'
        },
        'add_medical_problem_resource_added': {
            'ro': 'Problema medicală a fost adăugată',
            'en': 'Medical problem added'
        },
        'add_medical_problem_json_key_is_required_name': {
            'ro': 'Te rugăm să completezi numele problemei medicale',
            'en': 'Please fill in the name of the medical problem'
        },
        'edit_medical_problem_json_key_is_required_name': {
            'ro': 'Te rugăm să completezi numele problemei medicale',
            'en': 'Please fill in the name of the medical problem'
        },
        'edit_medical_problem_resource_updated': {
            'ro': 'Problema medicală a fost actualizată',
            'en': 'Medical problem updated'
        },
        'delete_medical_problem_resource_deleted': {
            'ro': 'Problema medicală a fost ștearsă',
            'en': 'Medical problem deleted'
        },
        'deleting_medical_problem': {
            'ro': 'Ești sigur că vrei să ștergi problema medicală?',
            'en': 'Are you sure you want to delete this medical problem?'
        },
        'find_medical_problem': {
            'ro': 'Caută problemă medicală',
            'en': 'Search medical problems'
        },
        // Measurements.
        'measurements': {
            'ro': 'Măsurători',
            'en': 'Measurements'
        },
        'add_measurement': {
            'ro': 'Adaugă măsurătoare',
            'en': 'Add measurement'
        },
        'new_measurement': {
            'ro': 'Măsurătoare nouă',
            'en': 'New measurement'
        },
        'create_measurement': {
            'ro': 'Creează măsurătoare nouă',
            'en': 'Create new measurement'
        },
        'include_measurement': {
            'ro': 'Include măsurătoare',
            'en': 'Include measurement'
        },
        'edit_measurement': {
            'ro': 'Editează măsurătoarea',
            'en': 'Edit measurement'
        },
        'delete_measurement': {
            'ro': 'Șterge măsurătoarea',
            'en': 'Delete measurement'
        },
        'delete_measurement_resource_is_used': {
            'ro': 'Măsurătoarea este folosită și nu poate fi ștearsă',
            'en': 'Measurement is used and cannot be deleted'
        },
        'add_measurement_resource_added': {
            'ro': 'Măsurătoarea a fost adăugată',
            'en': 'Measurement added'
        },
        'add_measurement_json_key_is_required_name': {
            'ro': 'Te rugăm să completezi numele măsurătorii',
            'en': 'Please fill in the name of the measurement'
        },
        'edit_measurement_json_key_is_required_name': {
            'ro': 'Te rugăm să completezi numele măsurătorii',
            'en': 'Please fill in the name of the measurement'
        },
        'edit_measurement_resource_updated': {
            'ro': 'Măsurătoarea a fost actualizată',
            'en': 'Measurement updated'
        },
        'delete_measurement_resource_deleted': {
            'ro': 'Măsurătoarea a fost ștearsă',
            'en': 'Measurement deleted'
        },
        'deleting_measurement': {
            'ro': 'Ești sigur că vrei să ștergi măsurătoarea?',
            'en': 'Are you sure you want to delete this measurement?'
        },
        'find_measurement': {
            'ro': 'Caută măsurătoare',
            'en': 'Search measurements'
        },
        'measurements_unit': {
            'ro': 'Unitate de măsură',
            'en': 'Unit'
        },
        'enter_measurements_unit': {
            'ro': 'Unitatea de măsură',
            'en': 'Enter unit'
        },
        // Units.
        'units': {
            'ro': 'Unități',
            'en': 'Units'
        },
        'add_units': {
            'ro': 'Adaugă unități',
            'en': 'Add units'
        },
        'add_units_resource_added': {
            'ro': 'Unitatea a fost adăugată',
            'en': 'Unit added'
        },
        'add_unit': {
            'ro': 'Adaugă unitate',
            'en': 'Add unit'
        },
        'new_unit': {
            'ro': 'Unitate nouă',
            'en': 'New unit'
        },
        'create_unit': {
            'ro': 'Creează unitate nouă',
            'en': 'Create new unit'
        },
        'create_units': {
            'ro': 'Creează unitate nouă',
            'en': 'Create new unit'
        },
        'include_unit': {
            'ro': 'Include unitate',
            'en': 'Include unit'
        },
        'edit_unit': {
            'ro': 'Editează unitatea',
            'en': 'Edit unit'
        },
        'delete_unit': {
            'ro': 'Șterge unitatea',
            'en': 'Delete unit'
        },
        'delete_unit_resource_is_used': {
            'ro': 'Unitatea este folosită și nu poate fi ștearsă',
            'en': 'Unit is used and cannot be deleted'
        },
        'add_unit_resource_added': {
            'ro': 'Unitatea a fost adăugată',
            'en': 'Unit added'
        },
        'add_unit_json_key_is_required_name': {
            'ro': 'Te rugăm să completezi numele unității',
            'en': 'Please fill in the name of the unit'
        },
        'add_units_json_key_is_required_name': {
            'ro': 'Te rugăm să completezi numele unității',
            'en': 'Please fill in the name of the unit'
        },
        'edit_unit_json_key_is_required_name': {
            'ro': 'Te rugăm să completezi numele unității',
            'en': 'Please fill in the name of the unit'
        },
        'edit_unit_resource_updated': {
            'ro': 'Unitatea a fost actualizată',
            'en': 'Unit updated'
        },
        'delete_unit_resource_deleted': {
            'ro': 'Unitatea a fost ștearsă',
            'en': 'Unit deleted'
        },
        'deleting_unit': {
            'ro': 'Ești sigur că vrei să ștergi unitatea?',
            'en': 'Are you sure you want to delete this unit?'
        },
        'find_unit': {
            'ro': 'Caută unitate',
            'en': 'Search units'
        },
        // Objectives.
        'objectives': {
            'ro': 'Obiective',
            'en': 'Objectives'
        },
        'add_objective': {
            'ro': 'Adaugă obiectiv',
            'en': 'Add objective'
        },
        'new_objective': {
            'ro': 'Obiectiv nou',
            'en': 'New objective'
        },
        'create_objective': {
            'ro': 'Creează obiectiv nou',
            'en': 'Create new objective'
        },
        'edit_objective': {
            'ro': 'Editează obiectivul',
            'en': 'Edit objective'
        },
        'delete_objective': {
            'ro': 'Șterge obiectivul',
            'en': 'Delete objective'
        },
        'add_objective_resource_added': {
            'ro': 'Obiectivul a fost adăugat',
            'en': 'Objective added'
        },
        'add_objective_json_key_is_required_name': {
            'ro': 'Te rugăm să completezi numele obiectivului',
            'en': 'Please fill in the name of the objective'
        },
        'edit_objective_json_key_is_required_name': {
            'ro': 'Te rugăm să completezi numele obiectivului',
            'en': 'Please fill in the name of the objective'
        },
        'edit_objective_resource_updated': {
            'ro': 'Obiectivul a fost actualizat',
            'en': 'Objective updated'
        },
        'delete_objective_resource_deleted': {
            'ro': 'Obiectivul a fost șters',
            'en': 'Objective deleted'
        },
        'delete_objective_resource_is_used': {
            'ro': 'Obiectivul este folosit și nu poate fi șters',
            'en': 'Objective is used and cannot be deleted'
        },
        'deleting_objective': {
            'ro': 'Ești sigur că vrei să ștergi obiectivul?',
            'en': 'Are you sure you want to delete this objective?'
        },
        'find_objective': {
            'ro': 'Caută obiectiv',
            'en': 'Search objective'
        },
        'description': {
            'ro': 'Descriere',
            'en': 'Description'
        },
        'enter_objective_description': {
            'ro': 'Introduceți descrierea obiectivului',
            'en': 'Enter objective description'
        },
        // Exercises.
        'unit': {
            'ro': 'Unitate',
            'en': 'Unit'
        },
        'mark_exercises': {
            'ro': 'Marcare exerciții',
            'en': 'Mark exercises'
        },
        'marked_by': {
            'ro': 'Marcat de',
            'en': 'Marked by'
        },
        'marked_at': {
            'ro': 'Marcat la',
            'en': 'Marked at'
        },
        'log_training': {
            'ro': 'Marcare exerciții',
            'en': 'Mark exercises'
        },
        'exercises': {
            'ro': 'Exerciții',
            'en': 'Exercises'
        },
        'add_exercise': {
            'ro': 'Adaugă exercițiu',
            'en': 'Add exercise'
        },
        'new_exercise': {
            'ro': 'Exercițiu nou',
            'en': 'New exercise'
        },
        'create_exercise': {
            'ro': 'Creează exercițiu nou',
            'en': 'Create new exercise'
        },
        'add_exercises': {
            'ro': 'Adaugă exercițiu',
            'en': 'Add exercise'
        },
        'edit_exercise': {
            'ro': 'Editează exercițiu',
            'en': 'Edit exercise'
        },
        'delete_exercise': {
            'ro': 'Șterge exercițiu',
            'en': 'Delete exercise'
        },
        'add_exercise_resource_added': {
            'ro': 'Exercițiul a fost adăugat',
            'en': 'Exercise added'
        },
        'add_exercise_json_key_is_required_name': {
            'ro': 'Te rugăm să completezi numele exercițiului',
            'en': 'Please fill in the name of the exercise'
        },
        'add_exercise_json_key_is_required_unit_id': {
            'ro': 'Te rugăm să alegi unitatea',
            'en': 'Please choose the unit'
        },
        'edit_exercise_resource_updated': {
            'ro': 'Exercițiul a fost actualizat',
            'en': 'Exercise updated'
        },
        'edit_exercise_json_key_is_required_name': {
            'ro': 'Te rugăm să completezi numele exercițiului',
            'en': 'Please fill in the name of the exercise'
        },
        'delete_exercise_resource_deleted': {
            'ro': 'Exercițiul a fost șters',
            'en': 'Exercise deleted'
        },
        'delete_exercise_resource_is_used': {
            'ro': 'Exercițiul este folosit și nu poate fi șters',
            'en': 'Exercise is used and cannot be deleted'
        },
        'deleting_exercise': {
            'ro': 'Ești sigur că vrei să ștergi exercițiul?',
            'en': 'Are you sure you want to delete this exercise?'
        },
        'find_exercise': {
            'ro': 'Caută exercițiu',
            'en': 'Search exercises'
        },
        'find_exercises': {
            'ro': 'Caută exercițiu',
            'en': 'Search exercise'
        },
        'exercise_name': {
            'ro': 'Nume exercițiu',
            'en': 'Exercise name'
        },
        'actions': {
            'ro': 'Acțiuni',
            'en': 'Actions'
        },
        // Exercises, included items
        'delete_included_training_type': {
            'ro': 'Șterge tipul de antrenament inclus',
            'en': 'Delete included training type'
        },
        'deleting_included_training_type': {
            'ro': 'Ești sigur că vrei să scoți tipul de antrenament din exercițiu?',
            'en': 'Are you sure you want to remove this training type from the exercise?'
        },
        'delete_included_muscle': {
            'ro': 'Șterge grupa de mușchi inclusă',
            'en': 'Delete included muscle'
        },
        'deleting_included_muscle': {
            'ro': 'Ești sigur că vrei să scoți grupa musculară din exercițiu?',
            'en': 'Are you sure you want to remove this muscle from the exercise?'
        },
        'delete_included_equipment': {
            'ro': 'Șterge echipamentul inclus',
            'en': 'Delete included equipment'
        },
        'deleting_included_equipment': {
            'ro': 'Ești sigur că vrei să scoți echipamentul din exercițiu?',
            'en': 'Are you sure you want to remove this equipment from the exercise?'
        },
        'delete_included_medical_problem': {
            'ro': 'Șterge problema medicală inclusă',
            'en': 'Delete included medical problem'
        },
        'deleting_included_medical_problem': {
            'ro': 'Ești sigur că vrei să scoți problema medicală din exercițiu?',
            'en': 'Are you sure you want to remove this medical problem from the exercise?'
        },
        'training_type_resource_added': {
            'ro': 'Tipul de antrenament a fost inclus în exercițiu',
            'en': 'The training type has been added to the exercise'
        },
        'muscle_resource_added': {
            'ro': 'Grupa musculară a fost inclusă în exercițiu',
            'en': 'The muscle has been added to the exercise'
        },
        'equipment_resource_added': {
            'ro': 'Echipamentul a fost inclus în exercițiu',
            'en': 'The equipment has been added to the exercise'
        },
        'medical_problem_resource_added': {
            'ro': 'Problema medicală a fost inclusă în exercițiu',
            'en': 'The medical problem has been added to the exercise'
        },
        'deleted_included_training_type_resource_deleted': {
            'ro': 'Tipul de antrenament a fost scos din exercițiu',
            'en': 'Included training type has been deleted from exercise'
        },
        // Personal Files.
        'personal_files': {
            'ro': 'Fișe personale',
            'en': 'Personal files'
        },
        'personal_file': {
            'ro': 'Fișa personală',
            'en': 'Personal file'
        },
        'personal_data': {
            'ro': 'Date personale',
            'en': 'Personal data'
        },
        'edit_personal_data': {
            'ro': 'Editează datele personale',
            'en': 'Edit personal data'
        },
        'select_gender': {
            'ro': 'Selectează genul',
            'en': 'Select gender'
        },
        'choose_gender': {
            'ro': 'Selectați genul',
            'en': 'Select gender'
        },
        'notes': {
            'ro': 'Notițe',
            'en': 'Notes'
        },
        'see_exercises': {
            'ro': 'Vezi exercițiile',
            'en': 'See exercises'
        },
        'hide_exercises': {
            'ro': 'Ascunde exercițiile',
            'en': 'Hide exercises'
        },
        'training_exercises': {
            'ro': 'Exercițiile antrenamentului',
            'en': 'Training exercises'
        },
        'agenda': {
            'ro': 'Agenda',
            'en': 'Agenda'
        },
        'equipment': {
            'ro': 'Echipament',
            'en': 'Equipment'
        },
        'personal_equipment': {
            'ro': 'Echipament personal',
            'en': 'Personal equipment'
        },
        'personal_equipments': {
            'ro': 'Echipamente personale',
            'en': 'Personal equipments'
        },
        'add_personal_equipment': {
            'ro': 'Adaugă echipament personal',
            'en': 'Add personal equipment'
        },
        'include_personal_equipment': {
            'ro': 'Include echipament personal',
            'en': 'Include personal equipment'
        },
        'gender_female': {
            'ro': 'Feminin',
            'en': 'Female'
        },
        'gender_male': {
            'ro': 'Masculin',
            'en': 'Male'
        },
        'age': {
            'ro': 'Vârstă',
            'en': 'Age'
        },
        'birth_date': {
            'ro': 'Data de naștere',
            'en': 'Birth date'
        },
        'phone_number': {
            'ro': 'Telefon',
            'en': 'Phone'
        },
        'personal_equipment_resource_added': {
            'ro': 'Echipamentul personal a fost adăugat',
            'en': 'The personal equipment has been added'
        },
        'personal_equipment_resource_deleted': {
            'ro': 'Echipamentul personal a fost șters',
            'en': 'The personal equipment has been deleted'
        },
        'delete_included_personal_equipment': {
            'ro': 'Șterge echipamentul personal inclus',
            'en': 'Delete included personal equipment'
        },
        'deleting_included_personal_equipment': {
            'ro': 'Ești sigur că vrei să scoți echipamentul din lista personală?',
            'en': 'Are you sure you want to remove this equipment from the personal list?'
        },
        'here_you_can_manage_the_list_with_personal_equipment_from_home': {
            'ro': 'De aici se poate gestiona echipamentul personal care poate fi folosit acasă',
            'en': 'From here you can manage the personal equipment which can be used at home'
        },
        'available_equipments': {
            'ro': 'Echipamente disponibile',
            'en': 'Available equipments'
        },
        'no_available_equipments_yet_use_the_button_below_to_start_adding': {
            'ro': 'Nu au fost adugăte echipamente, folosește butonul de mai jos pentru a începe',
            'en': 'Equipments not yet added, use the button below to start adding'
        },
        'no_available_equipments_yet': {
            'ro': 'Nu au fost adugăte echipamente',
            'en': 'Equipments not yet added'
        },
        // Exercises Collections.
        'exercises_collections': {
            'ro': 'Colecții de exerciții',
            'en': 'Exercises collections'
        },
        'exercises_collection': {
            'ro': 'Colecția de exerciții',
            'en': 'Exercises collection'
        },
        'new_exercises_collection': {
            'ro': 'Colecție nouă de exerciții',
            'en': 'New exercises collection'
        },
        'create_exercises_collection': {
            'ro': 'Creează colecție nouă de exerciții',
            'en': 'Create new exercises collection'
        },
        'new_exercises_collections': {
            'ro': 'Colecție nouă de exerciții',
            'en': 'New exercises collection'
        },
        'create_exercises_collections': {
            'ro': 'Creează colecție nouă de exerciții',
            'en': 'Create new exercises collection'
        },
        'add_exercises_collection': {
            'ro': 'Adaugă colecție de exerciții',
            'en': 'Add exercises collection'
        },
        'add_exercises_collection_json_key_is_required_name': {
            'ro': 'Te rugăm să completezi numele colecției de exerciții',
            'en': 'Please fill in the name of the exercises collection'
        },
        'add_exercises_collections': {
            'ro': 'Adaugă colecție de exerciții',
            'en': 'Add exercises collection'
        },
        'edit_exercises_collections': {
            'ro': 'Editează colecția de exerciții',
            'en': 'Edit exercises collection'
        },
        'edit_exercises_collection': {
            'ro': 'Editează colecția de exerciții',
            'en': 'Edit exercises collection'
        },
        'edit_exercises_collection_json_key_is_required_name': {
            'ro': 'Te rugăm să completezi numele colecției de exerciții',
            'en': 'Please fill in the name of the exercises collection'
        },
        'delete_exercises_collection': {
            'ro': 'Șterge colecția de exerciții',
            'en': 'Delete exercises collection'
        },
        'add_exercises_collection_resource_added': {
            'ro': 'Colecția de exerciții a fost adăugată',
            'en': 'Exercises collection added'
        },
        'exercises_collections_resource_added': {
            'ro': 'Colecția de exerciții a fost adăugată',
            'en': 'Exercises collection added'
        },
        'exercises_collections_resource_is_used': {
            'ro': 'Colecția de exerciții este folosită',
            'en': 'Exercises collection is used'
        },
        'edit_exercises_collection_resource_updated': {
            'ro': 'Colecția de exerciții a fost actualizată',
            'en': 'Exercises collection updated'
        },
        'delete_exercises_collection_resource_deleted': {
            'ro': 'Colecția de exerciții a fost ștearsă',
            'en': 'Exercises collection deleted'
        },
        'delete_exercises_collection_resource_is_used': {
            'ro': 'Colecția de exerciții este folosită și nu poate fi ștearsă',
            'en': 'Exercises collection is used and cannot be deleted'
        },
        'deleting_exercises_collection': {
            'ro': 'Ești sigur că vrei să ștergi colecția de exerciții?',
            'en': 'Are you sure you want to delete this exercises collection?'
        },
        'find_exercises_collection': {
            'ro': 'Caută colecția de exerciții',
            'en': 'Search exercises collection'
        },
        'find_exercises_collections': {
            'ro': 'Caută colecția de exerciții',
            'en': 'Search exercises collection'
        },
        'exercises_collection_name': {
            'ro': 'Nume colectie de exerciții',
            'en': 'Exercises collection name'
        },
        'include_exercise': {
            'ro': 'Include exerciții',
            'en': 'Include exercises'
        },
        'include_exercises': {
            'ro': 'Include exerciții',
            'en': 'Include exercises'
        },
        'drag_and_drop_to_change_order': {
            'ro': 'Schimbați ordinea prin drag&drop',
            'en': 'Change order with drag&drop'
        },
        'exercise_resource_added': {
            'ro': 'Exercițiul a fost inclus',
            'en': 'Exercise added to the collection'
        },
        'exercises_resource_added': {
            'ro': 'Exercițiul a fost inclus',
            'en': 'Exercise added to the collection'
        },
        'delete_included_exercise': {
            'ro': 'Șterge exercițiul inclus',
            'en': 'Delete included exercise'
        },
        'delete_included_exercises': {
            'ro': 'Șterge exercițiul inclus',
            'en': 'Delete included exercise'
        },
        'deleting_included_exercise': {
            'ro': 'Ești sigur că vrei să scoți exercițiul din colecție?',
            'en': 'Are you sure you want to remove this exercise from collection?'
        },
        'deleting_included_exercises': {
            'ro': 'Ești sigur că vrei să scoți exercițiul din colecție?',
            'en': 'Are you sure you want to remove this exercise from collection?'
        },
        'exercise_resource_deleted': {
            'ro': 'Exercițiul a fost șters',
            'en': 'Exercise deleted'
        },
        'exercises_resource_deleted': {
            'ro': 'Exercițiul a fost șters',
            'en': 'Exercise deleted'
        },
        // Trainings.
        'trainings': {
            'ro': 'Antrenamente',
            'en': 'Trainings'
        },
        'rounds': {
            'ro': 'Runde',
            'en': 'Rounds'
        },
        'time': {
            'ro': 'Timp',
            'en': 'Time'
        },
        'add_training': {
            'ro': 'Adaugă antrenament',
            'en': 'Add training'
        },
        'new_training': {
            'ro': 'Antrenament nou',
            'en': 'New training'
        },
        'edit_trainings': {
            'ro': 'Editează antrenamentul',
            'en': 'Edit training'
        },
        'edit_training': {
            'ro': 'Editează antrenamentul',
            'en': 'Edit training'
        },
        'delete_training': {
            'ro': 'Șterge antrenament',
            'en': 'Delete training'
        },
        'add_training_resource_added': {
            'ro': 'Antrenamentul a fost adăugat',
            'en': 'Training added'
        },
        'add_training_json_key_is_required_name': {
            'ro': 'Te rugăm să completezi numele antrenamentului',
            'en': 'Please fill in the name of the training'
        },
        'edit_training_resource_updated': {
            'ro': 'Antrenamentul a fost actualizat',
            'en': 'Training updated'
        },
        'edit_training_json_key_is_required_name': {
            'ro': 'Te rugăm să completezi numele antrenamentului',
            'en': 'Please fill in the name of the training'
        },
        'delete_training_resource_deleted': {
            'ro': 'Antrenamentul a fost șters',
            'en': 'Training deleted'
        },
        'delete_training_resource_is_used': {
            'ro': 'Antrenamentul este folosit și nu poate fi șters',
            'en': 'Training is used and cannot be deleted'
        },
        'deleting_training': {
            'ro': 'Ești sigur că vrei să ștergi antrenamentul?',
            'en': 'Are you sure you want to delete this training?'
        },
        'find_training': {
            'ro': 'Caută antrenament',
            'en': 'Search training'
        },
        'find_trainings': {
            'ro': 'Caută antrenamente',
            'en': 'Search trainings'
        },
        'training_name': {
            'ro': 'Nume antrenament',
            'en': 'Training name'
        },
        'include_exercises_collections': {
            'ro': 'Include colecții de exerciții',
            'en': 'Include exercises collections'
        },
        'exercises_collection_resource_added': {
            'ro': 'Colecția de Exerciții a fost inclusă',
            'en': 'Exercises collection added to the training'
        },
        'delete_included_exercises_collection': {
            'ro': 'Șterge colecția de exerciții inclusă',
            'en': 'Delete included exercises collection'
        },
        'delete_included_exercises_collections': {
            'ro': 'Șterge colecția de exerciții inclusă',
            'en': 'Delete included exercises collection'
        },
        'deleting_included_exercises_collection': {
            'ro': 'Ești sigur că vrei să scoți colecția de exerciții din antrenament?',
            'en': 'Are you sure you want to remove this exercise from training?'
        },
        'deleting_included_exercises_collections': {
            'ro': 'Ești sigur că vrei să scoți colecția de exerciții din antrenament?',
            'en': 'Are you sure you want to remove this exercise from training?'
        },
        'exercises_collection_resource_deleted': {
            'ro': 'Colecția de exerciții a fost ștearsă',
            'en': 'Exercises collection deleted'
        },
        'exercises_collections_resource_deleted': {
            'ro': 'Colecția de exerciții a fost ștearsă',
            'en': 'Exercises collection deleted'
        },
        // Personal trainings.
        'assigned_trainings': {
            'ro': 'Antrenamente alocate',
            'en': 'Assigned trainings'
        },
        'personal_training': {
            'ro': 'Antrenament personal',
            'en': 'Personal training'
        },
        'personal_trainings': {
            'ro': 'Antrenamente personale',
            'en': 'Personal trainings'
        },
        'assign_personal_training': {
            'ro': 'Alocare antrenamente personale',
            'en': 'Assign personal training'
        },
        'choose_training': {
            'ro': 'Alege antrenamentul',
            'en': 'Choose training'
        },
        'training_date': {
            'ro': 'Data antrenamentului',
            'en': 'Training date'
        },
        'assign_training_resource_added': {
            'ro': 'Antrenament alocat',
            'en': 'Training assigned'
        },
        'assign_training_json_key_is_required_training_id': {
            'ro': 'Nu uita să selectezi antrenamentul',
            'en': 'Do not forget to select the training'
        },
        'assign_training_no_active_user_for_customer': {
            'ro': 'Acest client nu are cont de utilizator activ',
            'en': 'This customer has no active user account'
        },
        'training': {
            'ro': 'Antrenament',
            'en': 'Training'
        },
        'organized_trainings': {
            'ro': 'Antrenamente organizate',
            'en': 'Organized trainings'
        },
        'here_you_can_manage_the_list_with_personal_training': {
            'ro': 'Jurnalul antrenamentelor organizate',
            'en': 'Training journal for organized trainings'
        },
        'here_you_can_manage_the_agenda_with_personal_training': {
            'ro': 'De aici se poate gestiona agenda cu antrenamentele personale',
            'en': 'From here you can manage the agenda with personal trainings'
        },
        'personal_agenda': {
            'ro': 'Agenda (plan) de antrenamente',
            'en': 'Personal agenda (plan)'
        },
        'personal_agenda': {
            'ro': 'Antrenamente personale',
            'en': 'Personal trainings'
        },
        // Personal Objectives.
        'no_objectives_found_on_search': {
            'ro': 'Nu au fost găsite obiective care să corespundă criteriilor de căutare',
            'en': 'No objectives matched your search criteria'
        },
        'find_personal_objective': {
            'ro': 'Caută obiectiv personal',
            'en': 'Search personal objective'
        },
        'personal_objectives': {
            'ro': 'Obiective personale',
            'en': 'Personal Objectives'
        },
        'here_you_can_manage_the_list_with_personal_objectives': {
            'ro': 'De aici se pot gestiona obiectivele personale',
            'en': 'From here you can manage personal objectives'
        },
        'assigned_objectives': {
            'ro': 'Obiective alocate',
            'en': 'Assigned objectives'
        },
        'choose_objectives': {
            'ro': 'Alege obiectivele',
            'en': 'Choose objectives'
        },
        'assign_personal_objective': {
            'ro': 'Alocare obiective personale',
            'en': 'Assign personal objectives'
        },
        'objective_description': {
            'ro': 'Descrierea obiectivului',
            'en': 'Objective\'s description'
        },
        'assign_objectives_resource_added': {
            'ro': 'Obiectiv personal alocat',
            'en': 'Personal objective added'
        },
        'assign_objectives_json_key_is_required_objective_id': {
            'ro': 'Nu uita să selectezi obiectivul',
            'en': 'Do not forget to select the objective'
        },
        'assign_objectives_json_key_is_required_description': {
            'ro': 'Descrierea este obligatorie',
            'en': 'The description is mandatory'
        },
        'no_assigned_objectives_yet_use_the_button_below_to_start_adding': {
            'ro': 'Nu au fost alocate obiective, folosește butonul de mai jos pentru a începe',
            'en': 'Objectives not yet assigned, use the button below to start adding'
        },
        'no_assigned_objectives_yet': {
            'ro': 'Nu au fost alocate obiective',
            'en': 'Objectives not yet assigned'
        },
        'personal_objective_settings': {
            'ro': 'Setări obiectiv personal',
            'en': 'Personal objectiv settings'
        },
        'objective': {
            'ro': 'Obiectiv',
            'en': 'Objective'
        },
        'delete_personal_objective': {
            'ro': 'Șterge obiectivul personal',
            'en': 'Delete personal objective'
        },
        'mark_personal_objective': {
            'ro': 'Marchează obiectivul personal',
            'en': 'Mark personal objective'
        },
        'finished_at': {
            'ro': 'Finalizat la data',
            'en': 'Finished at'
        },
        'not_yet': {
            'ro': 'Nu încă',
            'en': 'Not yet'
        },
        'nope': {
            'ro': 'Fără deadline',
            'en': 'Without deadline'
        },
        'deadline': {
            'ro': 'Deadline',
            'en': 'Deadline'
        },
        'delete_personal_objective': {
            'ro': 'Șterge obiectiv personal',
            'en': 'Delete personal objective'
        },
        'deleting_personal_objective': {
            'ro': 'Ești sigur că vrei să ștergi obiectivul personal?',
            'en': 'Are you sure you want to delete this personal objective?'
        },
        'personal_objective_cannot_delete_finished_objective': {
            'ro': 'Obiectivele finalizate nu pot fi șterse',
            'en': 'Finished objectives cannot be deleted'
        },
        'personal_objective_updated': {
            'ro': 'Obiectiv personalizat actualizat',
            'en': 'Personal objective updated'
        },
        'personal_objective_resource_deleted': {
            'ro': 'Obiectivul personalizat a fost șters',
            'en': 'Personal objective deleted'
        },
        // Personal Medical Problems.
        'no_medical_problems_found_on_search': {
            'ro': 'Nu au fost găsite probleme medicale care să corespundă criteriilor de căutare',
            'en': 'No medical problems matched your search criteria'
        },
        'find_personal_medical_problem': {
            'ro': 'Caută problemă medicală',
            'en': 'Search medical problem'
        },
        'personal_medical_problems': {
            'ro': 'Probleme medicale personale',
            'en': 'Personal medical problems'
        },
        'here_you_can_manage_the_list_with_personal_medical_problems': {
            'ro': 'De aici se pot gestiona problemele medicale personale',
            'en': 'From here you can manage personal medical problems'
        },
        'medical_problems_history': {
            'ro': 'Istoricul problemelor medicale',
            'en': 'History of medical problems'
        },
        'choose_medical_problem': {
            'ro': 'Alege problema medicală',
            'en': 'Choose medical problem'
        },
        'add_personal_medical_problem': {
            'ro': 'Adaugă probleme medicale personale',
            'en': 'Add medical problems'
        },
        'include_personal_medical_problem': {
            'ro': 'Include probleme medicale personale',
            'en': 'Include medical problems'
        },
        'personal_medical_problem_description': {
            'ro': 'Descrierea problemei medicale personale',
            'en': 'Description of personal medical problem'
        },
        'add_medical_problems_resource_added': {
            'ro': 'Problemă medicală personală adăugată',
            'en': 'Personal medical problem added'
        },
        'add_medical_problems_json_key_is_required_medical_problem_id': {
            'ro': 'Nu uita să selectezi problema medicală',
            'en': 'Do not forget to select the medical problem'
        },
        'no_assigned_medical_problems_yet_use_the_button_below_to_start_adding': {
            'ro': 'Nu au fost adăugate probleme medicale, folosește butonul de mai jos pentru a începe',
            'en': 'Medical problems not yet added, use the button below to start adding'
        },
        'personal_medical_problem_settings': {
            'ro': 'Setări problemă medicală personală',
            'en': 'Personal medical problem settings'
        },
        'delete_personal_medical_problem': {
            'ro': 'Șterge problema medicală personala',
            'en': 'Delete personal medical problem'
        },
        'mark_personal_medical_problem': {
            'ro': 'Marchează problema medicală personala',
            'en': 'Mark personal medical problem'
        },
        'delete_personal_medical_problem': {
            'ro': 'Șterge problema medicală personală',
            'en': 'Delete personal medical problem'
        },
        'deleting_personal_medical_problem': {
            'ro': 'Ești sigur că vrei să ștergi problema medicală personala?',
            'en': 'Are you sure you want to delete this personal medical problem?'
        },
        'personal_medical_problem_updated': {
            'ro': 'Problema medicală personală actualizată',
            'en': 'Personal medical problem updated'
        },
        'personal_medical_problem_resource_deleted': {
            'ro': 'Problema medicală personală a fost ștearsă',
            'en': 'Personal medical problem deleted'
        },
        'add_medical_problems_duplicate_resource': {
            'ro': 'Problema medicală personală există și este activă',
            'en': 'Personal medical problem has already been added and it is still active'
        },
        'no_description': {
            'ro': 'Fără descriere',
            'en': 'No description'
        },
        // Personal Measurements.
        'here_you_can_manage_personal_measurements': {
            'ro': 'De aici se pot gestiona măsurătorile personale',
            'en': 'From here you can manage personal measurements'
        },
        'find_personal_measurement': {
            'ro': 'Caută măsurătoare',
            'en': 'Search measurement'
        },
        'measurements_history': {
            'ro': 'Istoricul măsurătorilor',
            'en': 'Measurements history'
        },
        'add_personal_measurement': {
            'ro': 'Adaugă măsurători personale',
            'en': 'Add personal measurements'
        },
        'include_personal_measurement': {
            'ro': 'Include măsurători personale',
            'en': 'Include personal measurements'
        },
        'personal_measurement_value': {
            'ro': 'Valoarea măsurătorii',
            'en': 'Measurement\'s value'
        },
        'measurement_value': {
            'ro': 'Valoarea măsurătorii',
            'en': 'Measurement\'s value'
        },
        'measurement_no_value': {
            'ro': 'Fără valoare',
            'en': 'No value'
        },
        'delete_personal_measurement': {
            'ro': 'Șterge măsurătoarea personală',
            'en': 'Delete personal measurement'
        },
        'deleting_personal_measurement': {
            'ro': 'Ești sigur că vrei să ștergi măsurătoarea personală?',
            'en': 'Are you sure you want to delete this personal measurement?'
        },
        'personal_measurement_updated': {
            'ro': 'Măsurătoare personală actualizată',
            'en': 'Personal measurement updated'
        },
        'personal_measurement_resource_deleted': {
            'ro': 'Măsurătoarea personală a fost ștearsă',
            'en': 'Personal measurement deleted'
        },
        'personal_measurement_settings': {
            'ro': 'Setări măsurătoare personală',
            'en': 'Personal measurement settings'
        },
        'value': {
            'ro': 'Valoare',
            'en': 'Value'
        },
        'choose_measurement': {
            'ro': 'Alege măsurătoarea',
            'en': 'Choose measurement'
        },
        'add_measurements_json_key_is_required_measurement_id': {
            'ro': 'Nu uita să selectezi măsurătoarea',
            'en': 'Do not forget to select the measurement'
        },
        'add_measurements_json_key_is_required_measurement_value': {
            'ro': 'Valoarea măsurătorii nu este opțională',
            'en': 'The value of the measurement is not optional'
        },
        'add_measurements_resource_added': {
            'ro': 'Măsurătoare personală adăugată',
            'en': 'Personal measurement added'
        },
        'no_assigned_measurements_yet_use_the_button_below_to_start_adding': {
            'ro': 'Nu au fost alocate măsurători, folosește butonul de mai jos pentru a începe',
            'en': 'Measurements not yet assigned, use the button below to start adding'
        },
        'no_measurements_found_on_search': {
            'ro': 'Nu au fost găsite măsurători care să corespundă criteriilor de căutare',
            'en': 'No measurements matched your search criteria'
        },
        // Personal notes.
        'no_notes_yet_use_the_button_below_to_start_adding': {
            'ro': 'Nu sunt notițe, folosește butonul de mai jos pentru a adăuga',
            'en': 'No notes yet, use the button below to start adding'
        },
        'notes_help_you_to_better_organize': {
            'ro': 'Notițele te ajută să te organizezi mai bine',
            'en': 'Notes help you to better organize'
        },
        'find_personal_note': {
            'ro': 'Caută notiță personală',
            'en': 'Search personal note'
        },
        'added_notes': {
            'ro': 'Notițe adăugate',
            'en': 'Added notes'
        },
        'personal_notes': {
            'ro': 'Notițe personale',
            'en': 'Personal notes'
        },
        'add_personal_note': {
            'ro': 'Adaugă Notițe personale',
            'en': 'Add personal notes'
        },
        'add_notes_resource_added': {
            'ro': 'Notița personală a fost adăugată',
            'en': 'Personal notes added'
        },
        'delete_personal_note': {
            'ro': 'Șterge notița personală',
            'en': 'Delete personal note'
        },
        'deleting_personal_note': {
            'ro': 'Ești sigur că vrei să ștergi notița personală?',
            'en': 'Are you sure you want to delete this personal note?'
        },
        'personal_note_resource_deleted': {
            'ro': 'Notița personală a fost ștearsă',
            'en': 'Personal note deleted'
        },
        'add_notes_json_key_is_required_notes': {
            'ro': 'Notița nu este opțională',
            'en': 'The note is not optional'
        },
        // Month names.
        'January': {
            'ro': 'Ianuarie',
            'en': 'January'
        },
        'February': {
            'ro': 'Februarie',
            'en': 'February'
        },
        'March': {
            'ro': 'Martie',
            'en': 'March'
        },
        'April': {
            'ro': 'Aprilie',
            'en': 'April'
        },
        'May': {
            'ro': 'Mai',
            'en': 'May'
        },
        'June': {
            'ro': 'Iunie',
            'en': 'June'
        },
        'July': {
            'ro': 'Iulie',
            'en': 'July'
        },
        'August': {
            'ro': 'August',
            'en': 'August'
        },
        'September': {
            'ro': 'Septembrie',
            'en': 'September'
        },
        'October': {
            'ro': 'Octombrie',
            'en': 'October'
        },
        'November': {
            'ro': 'Noiembrie',
            'en': 'November'
        },
        'December': {
            'ro': 'Decembrie',
            'en': 'December'
        },
        // Custom images.
        'images_resource_updated': {
            'ro': 'Imaginea a fost adăugată',
            'en': 'Image added'
        },
        'delete_image_file_deleted': {
            'ro': 'Imaginea a fost ștearsă',
            'en': 'Image deleted'
        },
        'delete_image': {
            'ro': 'Șterge imagine',
            'en': 'Delete image'
        },
        'deleting_image': {
            'ro': 'Ești sigur că vrei să ștergi imaginea?',
            'en': 'Are you sure you want to delete this image?'
        },
        'cannot_delete_file_with_status_true': {
            'ro': 'Imaginea a fost ștearsă',
            'en': 'Image deleted'
        },
        // Status update.
        'toggle_status_resource_updated': {
            'ro': 'Status actualizat',
            'en': 'Status updated'
        },
        // Offer > Services.
        'add_service_resource_added': {
            'ro': 'Serviciu creat',
            'en': 'Service created'
        },
        'add_service_form_key_is_required_name': {
            'ro': 'Te rugăm să completezi numele serviciului',
            'en': 'Please fill in the name of the service'
        },
        'add_service_form_key_is_required_log_training': {
            'ro': 'Te rugăm să bifezi Marcare exerciții',
            'en': 'Please tick Mark exercises'
        },
        'edit_service_json_key_is_required_name': {
            'ro': 'Te rugăm să completezi numele serviciului',
            'en': 'Please fill in the name of the service'
        },
        'add_service_form_key_is_required_base_price': {
            'ro': 'Te rugăm să completezi prețul de bază al serviciului',
            'en': 'Please fill in the base price of the service'
        },
        'add_service_form_key_is_required_subscription_price': {
            'ro': 'Te rugăm să completezi prețul de abonament al serviciului',
            'en': 'Please fill in the subscription price of the service'
        },
        'edit_service_json_key_is_required_base_price': {
            'ro': 'Te rugăm să completezi prețul de bază al serviciului',
            'en': 'Please fill in the base price of the service'
        },
        'delete_service_resource_deleted': {
            'ro': 'Serviciu șters',
            'en': 'Service deleted'
        },
        'delete_service_resource_is_used': {
            'ro': 'Serviciul este folosit și nu poate fi șters',
            'en': 'Service is used cannot be deleted'
        },
        'delete_service_service_is_used_in_subscriptions': {
            'ro': 'Serviciul este folosit în abonament și nu poate fi șters',
            'en': 'Service is used in subscription cannot be deleted'
        },
        'edit_service_resource_updated': {
            'ro': 'Serviciul a fost actualizat',
            'en': 'Service updated'
        },
        // Offer > Products.
        'add_product_resource_added': {
            'ro': 'Produs creat',
            'en': 'Product created'
        },
        'add_product_form_key_is_required_name': {
            'ro': 'Te rugăm să introduci numele produsului',
            'en': 'Please enter the name of the product'
        },
        'add_product_form_key_is_required_base_price': {
            'ro': 'Te rugăm să completezi prețul de bază al produsului',
            'en': 'Please fill in the base price of the product'
        },
        'add_product_form_key_is_required_subscription_price': {
            'ro': 'Te rugăm să completezi prețul de abonament al produsului',
            'en': 'Please fill in the subscription price of the product'
        },
        'delete_product_resource_deleted': {
            'ro': 'Produs șters',
            'en': 'Product deleted'
        },
        'delete_product_resource_is_used': {
            'ro': 'Produsul este folosit și nu poate fi șters',
            'en': 'Product is used and cannot be deleted'
        },
        'edit_product_resource_updated': {
            'ro': 'Produsul a fost actualizat',
            'en': 'Product updated'
        },
        'edit_product_json_key_is_required_name': {
            'ro': 'Te rugăm să introduci numele produsului',
            'en': 'Please enter the name of the product'
        },
        'edit_product_json_key_is_required_base_price': {
            'ro': 'Te rugăm să completezi prețul de bază al produsului',
            'en': 'Please fill in the base price of the product'
        },
        'edit_product_json_key_is_required_subscription_price': {
            'ro': 'Te rugăm să completezi prețul de abonament al produsului',
            'en': 'Please fill in the subscription price of the product'
        },
        // Business > Locations.
        'add_location': {
            'ro': 'Adaugă locație',
            'en': 'Add location'
        },
        'new_location': {
            'ro': 'Locație nouă',
            'en': 'New location'
        },
        'add_location_form_key_is_required_name': {
            'ro': 'Te rugăm să completezi numele locației',
            'en': 'Please enter the name of the location'
        },
        'add_location_form_key_is_required_address': {
            'ro': 'Te rugăm să completezi adresa locației',
            'en': 'Please enter the address of the location'
        },
        'add_location_form_key_is_required_start_time': {
            'ro': 'Te rugăm să completezi ora deschiderii',
            'en': 'Please enter the start time'
        },
        'add_location_form_key_is_required_end_time': {
            'ro': 'Te rugăm să completezi ora închiderii',
            'en': 'Please enter the end time'
        },
        'add_location_resource_added': {
            'ro': 'Locație creată',
            'en': 'Location created'
        },
        'delete_location_resource_deleted': {
            'ro': 'Locație ștearsă',
            'en': 'Location deleted'
        },
        'delete_location_resource_is_used': {
            'ro': 'Locația este folosită și nu poate fi ștearsă',
            'en': 'Location is used and cannot be deleted'
        },
        'edit_location_resource_updated': {
            'ro': 'Locația a fost actualizată',
            'en': 'Location updated'
        },
        'edit_location_json_key_is_required_name': {
            'ro': 'Te rugăm să introduci numele locației',
            'en': 'Please enter the name of the address'
        },
        'edit_location_json_key_is_required_address': {
            'ro': 'Te rugăm să introduci adresa locației',
            'en': 'Please enter the address of the address'
        },
        'edit_location_form_key_is_required_start_time': {
            'ro': 'Te rugăm să completezi ora deschiderii',
            'en': 'Please enter the start time'
        },
        'edit_location_form_key_is_required_end_time': {
            'ro': 'Te rugăm să completezi ora închiderii',
            'en': 'Please enter the end time'
        },
        'edit_location_invalid_key_value_start_time': {
            'ro': 'Te rugăm să completezi ora deschiderii',
            'en': 'Please enter the start time'
        },
        'edit_location_invalid_key_value_end_time': {
            'ro': 'Te rugăm să completezi ora închiderii',
            'en': 'Please enter the end time'
        },
        // Offer > Subscriptions.
        'add_standard_subscription_resource_added': {
            'ro': 'Abonament predefinit creat',
            'en': 'Predefined subscription created'
        },
        'delete_standard_subscription_resource_deleted': {
            'ro': 'Abonament predefinit șters',
            'en': 'Predefined subscription deleted'
        },
        'delete_standard_subscription_resource_is_used': {
            'ro': 'Abonamentul predefinit este folosit și nu poate fi șters',
            'en': 'Predefined subscription is used and cannot be deleted'
        },
        'edit_standard_subscription_resource_updated': {
            'ro': 'Abonamentul predefinit a fost actualizat',
            'en': 'Predefined subscription updated'
        },
        'add_custom_subscription_resource_added': {
            'ro': 'Abonament personalizat creat',
            'en': 'Custom subscription created'
        },
        'delete_custom_subscription_resource_deleted': {
            'ro': 'Abonament personalizat șters',
            'en': 'Custom subscription deleted'
        },
        'delete_custom_subscription_resource_is_used': {
            'ro': 'Abonamentul personalizat este folosit și nu poate fi șters',
            'en': 'Custom subscription is used and cannot be deleted'
        },
        'edit_custom_subscription_resource_updated': {
            'ro': 'Abonamentul personalizat a fost actualizat',
            'en': 'Custom subscription updated'
        },
        'add_standard_subscription_form_key_is_required_name': {
            'ro': 'Te rugăm să completezi numele abonamentului predefinit',
            'en': 'Please fill in the name of the predefined subscription'
        },
        'add_standard_subscription_form_key_is_required_duration': {
            'ro': 'Te rugăm să completezi perioada abonamentului predefinit',
            'en': 'Please fill in the duration of the predefined subscription'
        },
        'add_standard_subscription_form_key_is_required_access_price': {
            'ro': 'Te rugăm să completezi prețul de acces al abonamentului predefinit',
            'en': 'Please fill in the access price of the predefined subscription'
        },
        'add_standard_subscription_json_key_is_required_access_price': {
            'ro': 'Te rugăm să completezi prețul de acces al abonamentului predefinit',
            'en': 'Please fill in the access price of the predefined subscription'
        },
        'add_standard_subscription_invalid_key_value_access_price': {
            'ro': 'Te rugăm să completezi prețul de acces al abonamentului predefinit',
            'en': 'Please fill in the access price of the predefined subscription'
        },
        'add_standard_subscription_form_key_is_required_discount_type': {
            'ro': 'Te rugăm să alegi tipul de discount al abonamentului predefinit',
            'en': 'Please choose the discount type of the predefined subscription'
        },
        'add_standard_subscription_form_key_is_required_discount_value': {
            'ro': 'Te rugăm să completezi valoarea de discount a abonamentului predefinit',
            'en': 'Please fill in the discount value of the predefined subscription'
        },
        'edit_standard_subscription_json_key_is_required_name': {
            'ro': 'Te rugăm să completezi numele abonamentului predefinit',
            'en': 'Please fill in the name of the predefined subscription'
        },
        'edit_standard_subscription_json_key_is_required_duration': {
            'ro': 'Te rugăm să completezi perioada abonamentului predefinit',
            'en': 'Please fill in the duration of the predefined subscription'
        },
        'edit_standard_subscription_json_key_is_required_access_price': {
            'ro': 'Te rugăm să completezi prețul de acces al abonamentului predefinit',
            'en': 'Please fill in the access price of the predefined subscription'
        },
        'edit_standard_subscription_json_key_is_required_discount_type': {
            'ro': 'Te rugăm să alegi tipul de discount al abonamentului predefinit',
            'en': 'Please choose the discount type of the predefined subscription'
        },
        'edit_standard_subscription_json_key_is_required_discount_value': {
            'ro': 'Te rugăm să completezi valoarea de discount a abonamentului predefinit',
            'en': 'Please fill in the discount value of the predefined subscription'
        },
        //-- custom.
        'add_custom_subscription_json_key_is_required_name': {
            'ro': 'Te rugăm să completezi numele abonamentului personalizat',
            'en': 'Please fill in the name of the custom subscription'
        },
        'add_custom_subscription_json_key_is_required_duration': {
            'ro': 'Te rugăm să completezi perioada abonamentului personalizat',
            'en': 'Please fill in the duration of the custom subscription'
        },
        'add_custom_subscription_json_key_is_required_included_credits': {
            'ro': 'Te rugăm să completezi creditele incluse în abonamentul personalizat',
            'en': 'Please fill in the included credits of the custom subscription'
        },
        'add_custom_subscription_json_key_is_required_price': {
            'ro': 'Te rugăm să completezi prețul abonamentului personalizat',
            'en': 'Please fill in the price of the custom subscription'
        },
        'add_custom_subscription_json_key_is_required_discount_type': {
            'ro': 'Te rugăm să alegi tipul de discount al abonamentului personalizat',
            'en': 'Please choose the discount type of the custom subscription'
        },
        'add_custom_subscription_json_key_is_required_discount_value': {
            'ro': 'Te rugăm să completezi valoarea de discount a abonamentului personalizat',
            'en': 'Please fill in the discount value of the custom subscription'
        },
        'add_custom_subscription_invalid_key_value_services': {
            'ro': 'Te rugăm să incluzi servicii',
            'en': 'Please include services'
        },
        'add_custom_subscription_form_key_is_required_name': {
            'ro': 'Te rugăm să completezi numele abonamentului personalizat',
            'en': 'Please fill in the name of the custom subscription'
        },
        'add_custom_subscription_form_key_is_required_duration': {
            'ro': 'Te rugăm să completezi perioada abonamentului personalizat',
            'en': 'Please fill in the duration of the custom subscription'
        },
        'add_custom_subscription_form_key_is_required_included_credits': {
            'ro': 'Te rugăm să completezi creditele incluse în abonamentul personalizat',
            'en': 'Please fill in the included credits of the custom subscription'
        },
        'add_custom_subscription_form_key_is_required_price': {
            'ro': 'Te rugăm să completezi prețul abonamentului personalizat',
            'en': 'Please fill in the price of the custom subscription'
        },
        'add_custom_subscription_form_key_is_required_discount_type': {
            'ro': 'Te rugăm să alegi tipul de discount al abonamentului personalizat',
            'en': 'Please choose the discount type of the custom subscription'
        },
        'add_custom_subscription_form_key_is_required_discount_value': {
            'ro': 'Te rugăm să completezi valoarea de discount a abonamentului personalizat',
            'en': 'Please fill in the discount value of the custom subscription'
        },
        'edit_custom_subscription_json_key_is_required_name': {
            'ro': 'Te rugăm să completezi numele abonamentului personalizat',
            'en': 'Please fill in the name of the custom subscription'
        },
        'edit_custom_subscription_json_key_is_required_duration': {
            'ro': 'Te rugăm să completezi perioada abonamentului personalizat',
            'en': 'Please fill in the duration of the custom subscription'
        },
        'edit_custom_subscription_json_key_is_required_included_credits': {
            'ro': 'Te rugăm să completezi creditele incluse în abonamentul personalizat',
            'en': 'Please fill in the included credits of the custom subscription'
        },
        'edit_custom_subscription_json_key_is_required_price': {
            'ro': 'Te rugăm să completezi prețul abonamentului personalizat',
            'en': 'Please fill in the price of the custom subscription'
        },
        'edit_custom_subscription_json_key_is_required_discount_type': {
            'ro': 'Te rugăm să alegi tipul de discount al abonamentului personalizat',
            'en': 'Please choose the discount type of the custom subscription'
        },
        'edit_custom_subscription_json_key_is_required_discount_value': {
            'ro': 'Te rugăm să completezi valoarea de discount a abonamentului personalizat',
            'en': 'Please fill in the discount value of the custom subscription'
        },
        // Offer > Promotions.
        'add_promotion_resource_added': {
            'ro': 'Promoție creată',
            'en': 'Promotion created'
        },
        'delete_promotion_resource_deleted': {
            'ro': 'Promoție ștearsă',
            'en': 'Promotion deleted'
        },
        'delete_welcome_subscription_resource_deleted': {
            'ro': 'Abonament bonus de înregistrare șters',
            'en': 'Welcome subscription deleted'
        },
        'delete_promotion_resource_is_used': {
            'ro': 'Promoția este folosită și nu poate fi ștearsă',
            'en': 'Promotion is used and cannot be deleted'
        },
        'edit_promotion_resource_updated': {
            'ro': 'Promoția a fost actualizată',
            'en': 'Promotion updated'
        },
        'edit_promotion_json_key_is_required_name': {
            'ro': 'Te rugăm să completezi numele promoției',
            'en': 'Please fill in the name of the promotion'
        },

        //
        'add_worker_resource_added': {
            'ro': 'Angajat creat',
            'en': 'Employee created'
        },
        'make_user_resource_added': {
            'ro': 'Utilizator creat',
            'en': 'User created'
        },
        'created_at': {
            'ro': 'Creat la',
            'en': 'Created at'
        },
        'created_by': {
            'ro': 'Creat de',
            'en': 'Created by'
        },
        'consulted_by': {
            'ro': 'Consultant',
            'en': 'Consultant'
        },
        'sale_created_at': {
            'ro': 'Creată la',
            'en': 'Created at'
        },
        'delete_worker_resource_deleted': {
            'ro': 'Angajat șters',
            'en': 'Employee deleted'
        },
        'delete_worker_resource_is_used': {
            'ro': 'Angajatul este folosit și nu poate fi șters',
            'en': 'Employee is used and cannot be deleted'
        },
        'edit_worker_resource_updated': {
            'ro': 'Angajatul a fost actualizat',
            'en': 'Employee updated'
        },
        // User management, Groups.
        'approve': {
            'ro': 'Aprobă',
            'en': 'Approve'
        },
        'approve_group_resource_updated': {
            'ro': 'Grup aprobat',
            'en': 'Approved group'
        },
        'add_group_resource_added': {
            'ro': 'Grup creat',
            'en': 'Group created'
        },
        'edit_group_resource_updated': {
            'ro': 'Grupul a fost actualizat',
            'en': 'Group updated'
        },
        'add_group_json_key_is_required_name': {
            'ro': 'Te rugăm să completezi numele grupului',
            'en': 'Please fill in the name of the group'
        },
        'edit_group_json_key_is_required_name': {
            'ro': 'Te rugăm să completezi numele grupului',
            'en': 'Please fill in the name of the group'
        },
        'add_group_json_key_is_required_owner_id': {
            'ro': 'Te rugăm să selectezi proprietarul grupului',
            'en': 'Please select the owner of the group'
        },
        'delete_group_resource_deleted': {
            'ro': 'Grup șters',
            'en': 'Group deleted'
        },
        'delete_group_resource_is_used': {
            'ro': 'Grupul este folosit și nu poate fi șters',
            'en': 'Group is used and cannot be deleted'
        },
        'group_members': {
            'ro': 'Membrii grupului',
            'en': 'Group members'
        },
        // Schedule status.
        'schedule_status': {
            'ro': 'Starea rezervării',
            'en': 'Schedule status'
        },
        'scheduled_days': {
            'ro': 'Zile programate',
            'en': 'Scheduled days'
        },
        'schedules_omitted': {
            'ro': 'Status rezervări',
            'en': 'Schedules status'
        },
        'change': {
            'ro': 'Schimbă',
            'en': 'Change'
        },
        'change_schedule_status_to': {
            'ro': 'Schimbă starea rezervării',
            'en': 'Change schedule status'
        },
        'changed_schedule_status_resource_updated': {
            'ro': 'Starea rezervării a fost schimbată',
            'en': 'Schedule status changed'
        },
        'click_on_attended_to_associate_trainings_or_exercises': {
            'ro': 'Pentru a asocia antrenamente sau exerciții, folosiți butonul Participare',
            'en': 'Use the Attended button in order to associate trainings or exercises'
        },
        'schedule_associate_trainings_or_exercises': {
            'ro': 'Asocieri de antrenamente sau exerciții',
            'en': 'Trainings or exercises associations'
        },
        'do_you_want_to_associate_trainings_or_exercises': {
            'ro': 'Dorești asociere de antrenamente sau exerciții?',
            'en': 'Do you want to associate trainings or exercises?'
        },
        'assigned_exercises': {
            'ro': 'Exerciții asociate',
            'en': 'Assigned exercises'
        },
        'assigned_trainings': {
            'ro': 'Antrenamente asociate',
            'en': 'Assigned trainings'
        },
        'pending': {
            'ro': 'În aşteptare',
            'en': 'Pending'
        },
        'attended': {
            'ro': 'Participare',
            'en': 'Attended'
        },
        'marked': {
            'ro': 'Marcat',
            'en': 'Marked'
        },
        'absence_status': {
            'ro': 'Status absență',
            'en': 'Absence status'
        },
        'absence_warned': {
            'ro': 'Absență avertizată',
            'en': 'Absence warned'
        },
        'absence_penalized': {
            'ro': 'Absență penalizată',
            'en': 'Absence penalized'
        },
        'schedule_assignation_trainings_duplicate_resource': {
            'ro': 'Asociere existentă',
            'en': 'Assignation existing'
        },
        'schedule_assignation_notes_resource_updated': {
            'ro': 'Notițe actualizate',
            'en': 'Notes updated'
        },
        'schedule_assignation_exercises_duplicate_resource': {
            'ro': 'Asociere existentă',
            'en': 'Assignation existing'
        },
        'schedule_assignation_trainings_resource_added': {
            'ro': 'Antrenament adâugat',
            'en': 'Training deleted'
        },
        'schedule_assignation_exercises_resource_added': {
            'ro': 'Exercițiu adâugat',
            'en': 'Exercise deleted'
        },
        'schedule_assignation_training_resource_deleted': {
            'ro': 'Antrenament șters',
            'en': 'Training deleted'
        },
        'schedule_assignation_exercise_resource_deleted': {
            'ro': 'Exercițiu șters',
            'en': 'Exercise deleted'
        },
        'toasted_resource_deleted': {
            'ro':'Rezervări recurente anulate',
            'en':'Recurrent schedules removed'
        },
        'schedule_recurrent_resource_deleted': {
            'ro':'Rezervarea fost anulată',
            'en':'Schedule removed'
        },
        'for_date': {
            'ro':'pentru data de',
            'en':'for date of'
        },
        'delete_assigned_training': {
            'ro': 'Șterge antrenament asociat',
            'en': 'Delete assigned training'
        },
        'delete_assigned_exercise': {
            'ro': 'Șterge exercițiu asociat',
            'en': 'Delete assigned exercise'
        },
        'deleting_assigned_training': {
            'ro': 'Ești sigur că vrei să ștergi asocierea de antrenament?',
            'en': 'Are you sure you want to delete this training assignment?'
        },
        'deleting_assigned_exercise': {
            'ro': 'Ești sigur că vrei să ștergi asocierea de exercițiu?',
            'en': 'Are you sure you want to delete this exercise assignment?'
        },

        // My Profile.
        'my_profile': {
            'ro': 'Profilul meu',
            'en': 'My profile'
        },
        'profile_picture': {
            'ro': 'Poza de profil',
            'en': 'Profile picture'
        },
        'edit_current_user_resource_updated': {
            'ro': 'Profilul a fost actualizat',
            'en': 'Profile updated'
        },
        'edit_current_user_json_key_is_required_first_name': {
            'ro': 'Te rugăm să completezi prenumele',
            'en': 'Please fill in the first name'
        },
        'edit_current_user_json_key_is_required_last_name': {
            'ro': 'Te rugăm să completezi numele de familie',
            'en': 'Please fill in the last name'
        },
        'edit_current_user_json_key_is_required_gender': {
            'ro': 'Te rugăm să selectezi genul',
            'en': 'Please select the gender'
        },
        'edit_current_user_json_key_is_required_birth_date': {
            'ro': 'Te rugăm să completezi data de naștere',
            'en': 'Please fill in the birth date'
        },
        'edit_current_user_json_key_is_required_phone': {
            'ro': 'Te rugăm să completezi numărul de telefon',
            'en': 'Please fill in the phone'
        },
        'edit_personal_data_resource_updated': {
            'ro': 'Datele personale au fost actualizate',
            'en': 'Personal data updated'
        },
        // Profile image.
        'delete_profile_image_resource_deleted': {
            'ro': 'Imaginea a fost ștearsă',
            'en': 'Image deleted'
        },
        'delete_profile_image': {
            'ro': 'Șterge imaginea de profil',
            'en': 'Delete profile image'
        },
        'deleting_profile_image': {
            'ro': 'Ești sigur că vrei să ștergi imaginea de profil?',
            'en': 'Are you sure you want to delete the profile image?'
        },

        // Delete user account.
        'delete_user_intention': {
            'ro': 'Ești pe cale să ștergi un cont de utilizator.',
            'en': 'You are about to delete an user account.'
        },
        'yes_continue': {
            'ro': 'Da, continuă',
            'en': 'Yes, continue'
        },
        'proceed_delete': {
            'ro': 'Șterge contul',
            'en': 'Delete account'
        },
        'delete_user_confirmation': {
            'ro': 'Șterge contul de utilizator!',
            'en': 'Delete user account!'
        },
        'deleting_user_confirmation': {
            'ro': 'Ești sigur că vrei să ștergi contul de utilizator?',
            'en': 'Are you sure you want to delete this user account?'
        },
        'deleting_user_confirmation_no_undo': {
            'ro': 'Odată șters, toate datele legate de acest cont vor fi pierdute și nu putem face undo.',
            'en': 'Once deleted, all the data related to this account will be lost, undo is not possible here.'
        },

        // Notifications.
        'notifications': {
            'ro': 'Notificări',
            'en': 'Notifications'
        },
        'no_notifications': {
            'ro': 'Nu sunt notificări',
            'en': 'No notifications'
        },

        // Messages.
        'close': {
            'ro': 'Închide',
            'en': 'Close'
        },
        'message': {
            'ro': 'Mesaj',
            'en': 'Message'
        },
        'received_messages': {
            'ro': 'Primite',
            'en': 'Received'
        },
        'sent_messages': {
            'ro': 'Trimise',
            'en': 'Sent'
        },
        'message_from': {
            'ro': 'De la',
            'en': 'From'
        },
        'message_to': {
            'ro': 'Către',
            'en': 'To'
        },
        'no_messages': {
            'ro': 'Nu sunt mesaje',
            'en': 'No messages'
        },
        'hide_list': {
            'ro': 'Ascunde lista',
            'en': 'Hide list'
        },
        'send_message': {
            'ro': 'Trimite mesaj',
            'en': 'Send message'
        },
        'send_message_to': {
            'ro': 'Trimite mesaj către',
            'en': 'Send message to'
        },
        'message_subject': {
            'ro': 'Subiect',
            'en': 'Subject'
        },
        'message_body': {
            'ro': 'Mesaj',
            'en': 'Message'
        },
        'sent_messages_count': {
            'ro': 'Mesaje trimise',
            'en': 'Sent messages'
        },
        'delete_messages_resources_soft_deleted': {
            'ro': 'Mesaje șterse',
            'en': 'Messages deleted'
        },
        'delete_message_resources_soft_deleted': {
            'ro': 'Mesaj șters',
            'en': 'Message deleted'
        },
        'you_are_about_to_delete_messages': {
            'ro': 'Ești pe cale să ștergi',
            'en': 'You are about to delete'
        },
        'messages_low': {
            'ro': 'mesaje',
            'en': 'messages'
        },
        'delete_messages': {
            'ro': 'Ștergere mesaje',
            'en': 'Delete messages'
        },
        'deleting_messages': {
            'ro': 'Ești sigur că vrei să ștergi mesajele?',
            'en': 'Are you sure you want to delete the messages?'
        },
        'delete_message': {
            'ro': 'Ștergere mesaj',
            'en': 'Delete message'
        },
        'deleting_message': {
            'ro': 'Ești sigur că vrei să ștergi mesajul?',
            'en': 'Are you sure you want to delete the message?'
        },
        'send_message_sent': {
            'ro': 'Mesaj trimis',
            'en': 'Message sent'
        },
        'send_message_subject': {
            'ro': 'Te rugăm să completezi subiectul',
            'en': 'Please fill in the subject'
        },
        'send_message_message': {
            'ro': 'Te rugăm să completezi mesajul',
            'en': 'Please fill in the message'
        },
        'unsent_messages': {
            'ro': 'Mesaje netrimise',
            'en': 'Messages not sent'
        },

        // Import CSV.
        'import': {
            'ro': 'Importă',
            'en': 'Import'
        },
        'import_csv': {
            'ro': 'Importă CSV',
            'en': 'Import CSV'
        },
        'header_csv_will_not_be_imported': {
            'ro': 'Antetul (prima linie) din fișierul CSV nu va fi importat',
            'en': 'Header (first line) of the csv will not be imported'
        },
        'csv_format': {
            'ro': 'Format CSV',
            'en': 'CSV format'
        },
        'upload_file': {
            'ro': 'Încarcă fișier',
            'en': 'Upload file'
        },
        'csv_format_fields': {
            'ro': 'Câmpuri',
            'en': 'Fields'
        },
        'preview': {
            'ro': 'Previzualizare',
            'en': 'Preview'
        },
        'example': {
            'ro': 'Exemplu',
            'en': 'Example'
        },
        'csv_format_fields_columns_count': {
            'ro': 'Coloane',
            'en': 'Columns'
        },
        'choose_csv_file': {
            'ro': 'Alege fișierul CSV',
            'en': 'Choose the CSV file'
        },
        'import_csv_imported': {
            'ro': 'Fișierul a fost procesat',
            'en': 'The file has been processed'
        },
        'import_csv_file_you_are_about_to_import_this_file': {
            'ro': 'Ești pe cale să imporți conținutul acestui fișier .csv',
            'en': 'You are about to import the content of this .csv file'
        },
        'import_csv_file_please_make_sure_all_good': {
            'ro': 'Te rugăm să te asiguri că totul este în regulă înainte de a continua.',
            'en': 'Please make sure that everything is alright, before you proceed.'
        },
        'imported_items': {
            'ro': 'Importate',
            'en': 'Imported'
        },
        'skipped_items': {
            'ro': 'Omise',
            'en': 'Skipped'
        },
        'import_status': {
            'ro': 'Status import',
            'en': 'Import status'
        },

        // Identity.
        'edit_identity': {
            'ro': 'Editează identitate',
            'en': 'Edit identity'
        },
        'edit_identity_resource_updated': {
            'ro': 'Identitatea a fost actualizată',
            'en': 'Identity updated'
        },
        'edit_identity_form_key_is_required_title': {
            'ro': 'Te rugăm să completezi titlul',
            'en': 'Please fill in the title'
        },
        'edit_identity_form_key_is_required_description': {
            'ro': 'Te rugăm să completezi descrierea',
            'en': 'Please fill in the description'
        },
        'edit_identity_form_key_is_required_email': {
            'ro': 'Te rugăm să completezi adresa de email',
            'en': 'Please fill in the email'
        },
        'edit_identity_form_key_is_required_phone': {
            'ro': 'Te rugăm să completezi numărul de telefon',
            'en': 'Please fill in the phone'
        },

        // Worker (Employee).
        'add_worker': {
            'ro': 'Adaugă angajat',
            'en': 'Add worker'
        },
        'new_worker': {
            'ro': 'Angajat nou',
            'en': 'New worker'
        },
        'create_worker': {
            'ro': 'Creează angajat nou',
            'en': 'Create new worker'
        },
        'add_worker_json_key_is_required_email': {
            'ro': 'Te rugăm să completezi adresa de email',
            'en': 'Please fill in the email'
        },
        'add_worker_json_key_is_required_first_name': {
            'ro': 'Te rugăm să completezi prenumele',
            'en': 'Please fill in the first name'
        },
        'add_worker_json_key_is_required_last_name': {
            'ro': 'Te rugăm să completezi numele de familie',
            'en': 'Please fill in the last name'
        },
        'add_worker_json_key_is_required_phone_number': {
            'ro': 'Te rugăm să completezi numărul de telefon',
            'en': 'Please fill in the phone number'
        },
        'add_worker_json_key_is_required_gender': {
            'ro': 'Te rugăm să completezi genul',
            'en': 'Please fill in the gender'
        },
        'add_worker_json_key_is_required_birth_date': {
            'ro': 'Te rugăm să completezi data de naștere',
            'en': 'Please fill in the birth date'
        },
        'add_worker_invalid_key_value_birth_date': {
            'ro': 'Te rugăm să completezi data de naștere',
            'en': 'Please fill in the birth date'
        },
        'add_worker_email_is_used': {
            'ro': 'Te rugăm să introduci altă adresă de email',
            'en': 'Please enter a different email address'
        },
        'edit_worker_json_key_is_required_first_name': {
            'ro': 'Te rugăm să completezi prenumele',
            'en': 'Please fill in the first name'
        },
        'edit_worker_json_key_is_required_last_name': {
            'ro': 'Te rugăm să completezi numele de familie',
            'en': 'Please fill in the last name'
        },
        'edit_worker_json_key_is_required_phone_number': {
            'ro': 'Te rugăm să completezi numărul de telefon',
            'en': 'Please fill in the phone number'
        },
        'edit_worker_json_key_is_required_gender': {
            'ro': 'Te rugăm să completezi genul',
            'en': 'Please fill in the gender'
        },
        'edit_worker_json_key_is_required_birth_date': {
            'ro': 'Te rugăm să completezi data de naștere',
            'en': 'Please fill in the birth date'
        },
        'edit_worker_invalid_key_value_birth_date': {
            'ro': 'Te rugăm să completezi data de naștere',
            'en': 'Please fill in the birth date'
        },
        'edit_worker_email_is_used': {
            'ro': 'Te rugăm să introduci altă adresă de email',
            'en': 'Please enter a different email address'
        },

        // Add Standard Subscription, new Structure.
        'include': {
            'ro': 'Include',
            'en': 'Include'
        },
        'please_add_at_least_one_item': {
            'ro': 'Te rugăm să adaugi cel puțin un item',
            'en': 'Please add at least 1 item'
        },
        'list_update': {
            'ro': 'Lista a fost actualizată',
            'en': 'List updated'
        },
        'choose_price_type': {
            'ro': 'Alege tipul de preț',
            'en': 'Choose price type'
        },
        'precalculated': {
            'ro': 'Precalculat',
            'en': 'Precalculated'
        },
        'customizable': {
            'ro': 'Customizabil',
            'en': 'Customizable'
        },
        'default_price': {
            'ro': 'Prețul implicit',
            'en': 'Default price'
        },
        'enter_default_price': {
            'ro': 'Introduceți prețul implicit',
            'en': 'Enter default price'
        },
        'subscription_default_price_info': {
            'ro': 'Acesta este prețul final de vânzare, indiferent de serviciile și produsele incluse.',
            'en': 'This is the final selling price, regardless of the services and products included.'
        },

        // Add Standard Subscription Validation.
        'add_standard_subscription_json_key_is_required_name': {
            'ro': 'Te rugăm să completezi numele abonamentului',
            'en': 'Please fill in the name of the subscription'
        },
        'add_standard_subscription_json_key_is_required_duration': {
            'ro': 'Te rugăm să completezi durata abonamentului',
            'en': 'Please fill in the duration of the subscription'
        },
        'add_standard_subscription_json_key_is_required_services': {
            'ro': 'Te rugăm să adaugi cel puțin un serviciu',
            'en': 'Please add at least 1 service'
        },
        'add_standard_subscription_json_key_is_required_products': {
            'ro': 'Te rugăm să adaugi cel puțin un produs',
            'en': 'Please add at least 1 product'
        },

        // Add Service.
        'service_type': {
            'ro': 'Tip serviciu',
            'en': 'Service type'
        },
        'unavailable': {
            'ro': 'Indisponibil',
            'en': 'Unavailable'
        },
        'viewable': {
            'ro': 'Vizibil',
            'en': 'Viewable'
        },
        'selectable': {
            'ro': 'Selectabil',
            'en': 'Selectable'
        },

        // Clone Subscriptions (standard/custom).
        'predefined_subscription': {
            'ro': 'Predefinit',
            'en': 'Standard'
        },
        'standard_subscription': {
            'ro': 'Predefinit',
            'en': 'Standard'
        },
        'clone_standard_subscription': {
            'ro': 'Clonare abonament predefinit',
            'en': 'Clone standard subscription'
        },
        'clone_custom_subscription': {
            'ro': 'Clonare abonament personalizat',
            'en': 'Clone custom subscription'
        },
        'clone_standard_subscription': {
            'ro': 'Clonare abonament predefinit',
            'en': 'Clone standard subscription'
        },
        'cloning_subscription': {
            'ro': 'Ești sigur că vrei să clonezi abonamentul?',
            'en': 'Are you sure you want to delete this subscription?'
        },
        'renew_subscription': {
            'ro': 'Reînnoiește abonamentul',
            'en': 'Renew subscription'
        },
        'clone': {
            'ro': 'Clonează',
            'en': 'Clone'
        },
        'clone_subscription': {
            'ro': 'Clonează abonamentul',
            'en': 'Clone subscription'
        },
        'subscription_name': {
            'ro': 'Nume abonament',
            'en': 'Subscription name'
        },
        'enter_subscription_name': {
            'ro': 'Introduceți numele abonamentului',
            'en': 'Enter subscription name'
        },
        'enter_subscription_clone_name': {
            'ro': 'Introduceți nume nou pentru abonamentul clonat',
            'en': 'Enter new subscription name for the clone'
        },
        'clone_standard_subscription_resource_added': {
            'ro': 'Clona a fost creată',
            'en': 'Clone created'
        },
        'clone_custom_subscription_resource_added': {
            'ro': 'Clona a fost creată',
            'en': 'Clone created'
        },
        'clone_standard_subscription_json_key_is_required_name': {
            'ro': 'Te rugăm să completezi numele abonamentului',
            'en': 'Please fill in the name of the subscription'
        },
        'clone_custom_subscription_json_key_is_required_name': {
            'ro': 'Te rugăm să completezi numele abonamentului',
            'en': 'Please fill in the name of the subscription'
        },

        'max_amount_allowed': {
            'ro': 'Maxim',
            'en': 'Max'
        },
        'positive_numeric_value_only_error': {
            'ro': 'Doar numere positive',
            'en': 'Only positive numbers'
        },

        // Add Streaming Form.
        'add_streaming': {
            'ro': 'Adaugă sesiune de streaming',
            'en': 'Add streaming session'
        },
        'delete_streaming': {
            'ro': 'Șterge streaming',
            'en': 'Delete streaming'
        },
        'deleting_streaming': {
            'ro': 'Ești sigur că vrei să ștergi sesiunea de streaming?',
            'en': 'Are you sure you want to delete this streaming session?'
        },
        'streaming_name': {
            'ro': 'Nume sesiune de streaming',
            'en': 'Streaming session name'
        },
        'enter_streaming_name': {
            'ro': 'Numele sesiunii de streaming',
            'en': 'The name of the streaming session'
        },
        'video_link': {
            'ro': 'Link video',
            'en': 'Video link'
        },
        'enter_video_link': {
            'ro': 'Link video',
            'en': 'Video link'
        },
        'video_provider': {
            'ro': 'Platforma video',
            'en': 'Video provider'
        },
        'select_video_provider': {
            'ro': 'Selectează platforma video',
            'en': 'Select video provider'
        },
        'included_providers': {
            'ro': 'Prestatori incluși',
            'en': 'Included providers'
        },
        'add_providers': {
            'ro': 'Asociază prestatori',
            'en': 'Assign providers'
        },
        'edit_streaming': {
            'ro': 'Editează streaming',
            'en': 'Edit streaming'
        },
        'sbscription_required_for_video_streaming': {
            'ro': 'Pentru a putea accesa streaming de video este nevoie de abonament',
            'en': 'Subscription is required for video streaming'
        },
        'streaming_session': {
            'ro': 'Sesiune de streaming',
            'en': 'Streaming session'
        },
        'add_streaming_resource_added': {
            'ro': 'Sesiunea de streaming a fost adăugată',
            'en': 'Your streaming session has been added'
        },
        'add_streaming_invalid_key_value_start_time': {
            'ro': 'Te rugăm să completezi ora începerii, în mod corect',
            'en': 'Please enter correctly the required start time'
        },
        'add_streaming_invalid_key_value_end_time': {
            'ro': 'Te rugăm să completezi ora de sfârșit, în mod corect',
            'en': 'Please enter correctly the required end time'
        },
        'edit_streaming_resource_updated': {
            'ro': 'Sesiunea de streaming a fost actualizată',
            'en': 'Streaming updated'
        },
        'add_streaming_streaming_overlap': {
            'ro': 'Sesiune de streaming suprapusă',
            'en': 'Streaming session overlap'
        },
        'delete_streaming_resource_deleted': {
            'ro': 'Sesiunea de streaming a fost ștearsă',
            'en': 'Streaming deleted'
        },
        'add_streaming_cannot_create_resource_in_the_past': {
            'ro': 'Nu se pot face sesiuni de streaming în trecut',
            'en': 'Streaming sessions in the past are not possible'
        },
        'add_streaming_json_key_is_required_name': {
            'ro': 'Te rugăm să completezi numele sesiunii de streaming',
            'en': 'Please enter the required streaming session name'
        },

        'ascending': {
            'ro': 'Ascendent',
            'en': 'Ascending'
        },
        'descending': {
            'ro': 'Descendent',
            'en': 'Descending'
        },

        // Canceled schedules.
        'canceled_at': {
            'ro': 'Anulat la',
            'en': 'Canceled at'
        },
        'canceled_by': {
            'ro': 'Anulat de',
            'en': 'Canceled by'
        },
        'schedule_created_at': {
            'ro': 'Rezervare din',
            'en': 'Scheduled on'
        },
        'schedule_created_by': {
            'ro': 'Rezervat de',
            'en': 'Scheduled by'
        },
        'schedule_date': {
            'ro': 'Data rezervării',
            'en': 'Schedule date'
        },

        // Title attributes and additional jovering info.
        'show_description':  {
            'ro': 'Arată descrierea',
            'en': 'Show description'
        },
        'hide_description':  {
            'ro': 'Ascunde descrierea',
            'en': 'Hide description'
        },

        // Buy, Customer panels.
        'coming_soon':  {
            'ro': 'În curând',
            'en': 'Coming soon'
        },
        'hide_description':  {
            'ro': 'Ascunde descrierea',
            'en': 'Hide description'
        },
        'buy_service':  {
            'ro': 'Cumpără serviciu',
            'en': 'Buy service'
        },
        'buy_product':  {
            'ro': 'Cumpără produs',
            'en': 'Buy product'
        },
        'buy_subscription':  {
            'ro': 'Cumpără abonament',
            'en': 'Buy subscription'
        },

        // Personal Training Details.
        'details_training':  {
            'ro': 'Detalii antrenament',
            'en': 'Training details'
        },
        'preview_training':  {
            'ro': 'Previzualizare antrenament',
            'en': 'Preview training'
        },
        'unavailable_equipment':  {
            'ro': 'Echipament indisponibil',
            'en': 'Unavailable equipment'
        },

        // Terms and Conditions
        'title_terms_and_conditions': {
            'ro': 'Termeni și condiții',
            'en': 'Terms and Conditions'
        },
        'rules_of_procedure': {
            'ro': 'Regulament',
            'en': 'Rules'
        },
        'gdpr': {
            'ro': 'GDPR',
            'en': 'GDPR'
        },

        // frontdesk, online payment methods
        'frontdesk': {
            'ro': 'Frontdesk',
            'en': 'Receptie'
        },
        'online': {
            'ro': 'Online',
            'en': 'Online'
        },
        'cash': {
            'ro': 'Numerar',
            'en': 'Cash'
        },
        'card': {
            'ro': 'Card',
            'en': 'Card'
        },

        // Payment methods.
        'cash_payment_method': {
            'ro': 'Numerar',
            'en': 'Cash'
        },
        'card_payment_method': {
            'ro': 'Card',
            'en': 'Card'
        },
        'the_payment_method': {
            'ro': 'Metoda de plată',
            'en': 'Payment method'
        },
        'method': {
            'ro': 'Metoda',
            'en': 'Method'
        },
        'date_and_hour': {
            'ro': 'Data și ora',
            'en': 'Date and hour'
        },

        // Overlapped events.
        'panel_title_overlap': {
            'ro': 'Suprapunere',
            'en': 'Overlaping'
        },
        'overlapped_events_detailed_info': {
            'ro': 'Nu se poate adăuga %msg%, intrucât există %event%',
            'en': 'You cannot add %msg%, as there already exists %event%'
        },
        'overlapped_msg_planning_overlap': {
            'ro': 'planificare',
            'en': 'planning'
        },
        'overlapped_msg_appointment_overlap': {
            'ro': 'programare',
            'en': 'appointment'
        },
        'overlapped_msg_customer_overlap': {
            'ro': 'rezervare',
            'en': 'schedule'
        },
        'overlapped_event_planning': {
            'ro': 'planificare',
            'en': 'planning'
        },
        'overlapped_event_appointment': {
            'ro': 'programare',
            'en': 'appointment'
        },
        'overlapped_event_schedule': {
            'ro': 'rezervare',
            'en': 'schedule'
        },

        // Sale details, modify subsription valid_until.
        'no_obtained_date': {
            'ro': 'Nu se poate recomanda o dată',
            'en': 'Date cannot be recommended'
        },
        'obtained_date_selected': {
            'ro': 'Data a fost selectată',
            'en': 'Date has been selected'
        },
        'change_valid_until_json_key_is_required_description': {
            'ro': 'Te rugăm să completezi descrierea',
            'en': 'Please fill in the description'
        },
        'change_valid_until_json_key_is_required_new_valid_until': {
            'ro': 'Te rugăm să alegi noua dată pentru Valid până la',
            'en': 'Please fill in the new valid until'
        },
        'change_valid_until_resource_added': {
            'ro': 'Data pentru Valid până la a fost schimbată',
            'en': 'The date for Valid until has been changed'
        },

        // Sales, Credits situation.
        'credits_situation': {
            'ro': 'Situația creditelor',
            'en': 'Credits situation'
        },
        // Sales, Credits history.
        'heading_credits_history': {
            'ro': 'Istoricul creditelor',
            'en': 'Credits history'
        },
        'credits_history': {
            'ro': 'Istoricul creditelor',
            'en': 'Credits history'
        },
        'credits_situations_expire_date': {
            'ro': 'Expiră',
            'en': 'Expires'
        },
        'credits_situations_available_credits': {
            'ro': 'Credite disponibile',
            'en': 'Available credits'
        },
        'credits_situations_reserved_credits': {
            'ro': 'Credite rezervate',
            'en': 'Reserved credits'
        },
        'credits_situations_consumed_credits': {
            'ro': 'Credite consumate',
            'en': 'Consumed credits'
        },
        'credits_situations_total_credits': {
            'ro': 'Total credite',
            'en': 'Total credits'
        },
        'advice_select_customer_to_continue': {
            'ro': 'Alegeți un client pentru a obține informațiile referitoare la creditele sale',
            'en': 'Choose a customer to get credit information'
        },
        'expires_never': {
            'ro': 'niciodată',
            'en': 'never'
        },
        'credits_situation_no_credits': {
            'ro': 'Nu există credite',
            'en': 'No credits'
        },
        'no_payment_history': {
            'ro': 'Nu există istoric',
            'en': 'No payment history'
        },
        'credits_history_operation_credit': {
            'ro': 'credit',
            'en': 'credit'
        },
        'credits_history_operation_credits': {
            'ro': 'credite',
            'en': 'credits'
        },
        'credits_history_operation_credits_alt': {
            'ro': 'de credite',
            'en': 'credits'
        },
        'credits_history_operation_consumed_attended': {
            'ro': 'a consumat',
            'en': 'consumed'
        },
        'credits_history_operation_released': {
            'ro': 'a consumat',
            'en': 'consumed'
        },
        'credits_history_operation_consumed_absence_penalized': {
            'ro': 'absență penalizată',
            'en': 'absence penalized'
        },
        'credits_history_operation_added': {
            'ro': 'Au fost adăugate',
            'en': 'were added'
        },
        'credits_history_operation_added_singular': {
            'ro': 'A fost adăugat',
            'en': 'was added'
        },
        'credits_history_operation_released': {
            'ro': 'a deblocat',
            'en': 'unlocked'
        },
        'credits_history_operation_reserved': {
            'ro': 'a rezervat',
            'en': 'reserved'
        },
        'credits_history_consumed_by_attended': {
            'ro': 'participare',
            'en': 'attended'
        },
        'credits_history_consumed_by_absence_penalized': {
            'ro': 'absență penalizată',
            'en': 'absence penalized'
        },
        'credit_history_for': {
            'ro': 'pentru',
            'en': 'for'
        },
        'credits_history_no_history_yet': {
            'ro': 'Nu există istoric',
            'en': 'There is no history'
        },
        // Sales, Changes history.
        'heading_changes_history': {
            'ro': 'Istoricul modificărilor',
            'en': 'Changes history'
        },
        'changes_history': {
            'ro': 'Istoricul modificărilor',
            'en': 'Changes history'
        },
        'changes_history_no_history_yet': {
            'ro': 'Nu există istoric',
            'en': 'There is no history'
        },
        'changes_history_creator_name': {
            'ro': 'Modificat de',
            'en': 'Changed by'
        },
        'new_valid_until': {
            'ro': 'Noul Valid până la',
            'en': 'New Valid until'
        },
        'old_valid_until': {
            'ro': 'Vechiul Valid până la',
            'en': 'Old Valid until'
        },
        'absences': {
            'ro': 'Absenţe',
            'en': 'Absences'
        },
        'cancellations': {
            'ro': 'Anulări',
            'en': 'Cancellations'
        },
        // Absences.
        'absence_status': {
            'ro': 'Status',
            'en': 'Status'
        },
        'absence_status_absence_warned': {
            'ro': 'Absență avertizată',
            'en': 'Absence warned'
        },
        'schedule_canceled_at': {
            'ro': 'Anulare efectuată în',
            'en': 'Cancellation made in'
        },
        // Customer groups.
        'customer_groups': {
            'ro': 'Grupurile clientului',
            'en': 'Customer\'s Groups'
        },
        'view_customer_groups': {
            'ro': 'Vizualizare grupuri client',
            'en': 'View customer\'s groups'
        },
        'customer_group_owner': {
            'ro': 'Deţinătorul grupului',
            'en': 'Group owner'
        },
        'customer_group_member': {
            'ro': 'Membru al grupului',
            'en': 'Group member'
        },
        'customer_group_is_active': {
            'ro': 'Grup activ',
            'en': 'Active group'
        },
        'customer_group_is_not_active': {
            'ro': 'Grup inactiv',
            'en': 'Inactive group'
        },
        'customer_group_is_approved': {
            'ro': 'Grup aprobat',
            'en': 'Approved group'
        },
        'customer_group_is_not_approved': {
            'ro': 'Grup neaprobat',
            'en': 'Not approved group'
        },
        'customer_group_is_deleted': {
            'ro': 'Grup șters',
            'en': 'Deleted group'
        },
        // Services filter, Service Type.
        'service_type_s_schedule': {
            'ro': 'Rezervare',
            'en': 'Schedule'
        },
        'service_type_s_appointment': {
            'ro': 'Programare',
            'en': 'Appointment'
        },
        'service_type_s_streaming': {
            'ro': 'Streaming',
            'en': 'Streaming'
        },
        // Resend Activation Email.
        'resend_activation_email': {
            'ro': 'Retrimite email de activare cont',
            'en': 'Resend email for account activation'
        },
        'resend_activation_email_description': {
            'ro': 'Retrimite pe email link-ul de activare cont',
            'en': 'Resend by email the link for account activation'
        },
        'resend_activation_email_check_your_email': {
            'ro': 'Te rugăm să îți verifici email-ul',
            'en': 'Please check your email'
        },
        'resend_activation_email_user_not_found': {
            'ro': 'Acest email nu corespunde unui utilizator',
            'en': 'Email not corresponding to any user'
        },
        'title_resend_activation_link': {
            'ro': 'Retrimite link',
            'en': 'Resend link'
        },
        'backend_version': {
            'ro': 'Versiune Backend',
            'en': 'Backend Version'
        },

        // Customer: Buy Subscription/Add Sale.
        'customer_add_sale_select_buyer_type': {
            'ro': 'Cumpărătorul',
            'en': 'Buyer'
        },
        'customer_add_sale_me': {
            'ro': 'Eu',
            'en': 'Me'
        },
        'customer_add_sale_my_group': {
            'ro': 'Grupul meu',
            'en': 'My group'
        },
        'buy_subscription_panel_info': {
            'ro': 'Pentru a achiziționa abonamentul, te rugăm să completezi formularul astfel încât să putem calcula prețul final corespunzător alegerilor făcute. După calcularea prețului final, vei putea finaliza achiziția și vei fi redirectat către pagina de Comenzi pentru a trece la pasul următor. În pagina de Comenzi, vei putea adăuga Plata Online pentru abonamentul dorit',
            'en': 'In order to purchase the subscription, please fill in the form so that we can calculate the final price corresponding to your choices. After calculating the final price, you will be able to complete the purchase and you will be redirected to the Orders page to move on to the next step. In the Orders page, you will be able to add Online Payment for the desired subscription'
        },
        'buy_button_text': {
            'ro': 'Cumpără',
            'en': 'Buy'
        },
        'payment_amount': {
            'ro': 'Sumă',
            'en': 'Amount'
        },
        'card_payment_method_euplatesc': {
            'ro': 'EuPlătesc',
            'en': 'EuPlătesc'
        },
        'add_payment_pay_button_text': {
            'ro': 'Plătește',
            'en': 'Pay'
        },

        'recheck_status': {
            'ro': 'Reverifică status',
            'en': 'Recheck status'
        },
        'capture_payment': {
            'ro': 'Acceptă plata',
            'en': 'Accept payment'
        },
        'capture_payment_question': {
            'ro': 'Ești sigur că vrei să accepți plata?',
            'en': 'Are you sure you want to accept the payment?'
        },
        'reversal_payment': {
            'ro': 'Refuză plata',
            'en': 'Refuse payment'
        },
        'reversal_payment_question': {
            'ro': 'Ești sigur că vrei să refuzi plata?',
            'en': 'Are you sure you want to reject the payment?'
        },
    },

}
