import Vue from 'vue'

/**
 * Global variables
 * Conventionally, use $ symbol to define global variables and to avoid conflicts.
 */

// Items per page.
Vue.prototype.$items_per_page = 25
// Toaster duration.
Vue.prototype.$toasted_duration = 5000

// Shuffle.
Vue.prototype.$shuffle = function (arr) {
    var ctr = arr.length, temp, index;

    // While there are elements in the array
    while (ctr > 0) {
        // Pick a random index
        index = Math.floor(Math.random() * ctr);
        // Decrease ctr by 1
        ctr--;
        // And swap the last element with it
        temp = arr[ctr];
        arr[ctr] = arr[index];
        arr[index] = temp;
    }
    return arr;
}
